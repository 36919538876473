import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css"
import { Provider } from 'react-redux';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </PersistGate>
    </Provider>
  </React.StrictMode>
);
