import { NavLink, useNavigate ,useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button } from "@mui/material";
import { Grid, InputLabel, MenuItem, Select, TextField ,Snackbar  } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { store } from "../../redux/store";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  opensides: {
    backgroundColor: "#fda94f",
    color: "white",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },

  nextback: {
    justifyContent: "space-between",
    display: "flex",
  },

  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },

  subhead: {
    fontSize: "15px",
    fontWeight: "600",
  },
}));
const CommercialStep = ({hostProperty}) => {
  const { id } = useParams();
 const Navigate = useNavigate()

  const [property, setProperty] = useState({
    currentFloor: hostProperty.currentFloor || "",
    totalFloors: hostProperty.totalFloors ||    "",
    furnishedStatus:hostProperty.furnishedStatus ||    "",
    numberOfWashroom : hostProperty.numberOfWashroom ||    "",
    personalRooms: hostProperty.personalRooms ||    "",
    construction:hostProperty.construction ||    "",
    areaType: hostProperty.areaType ||    "sq.ft",
    areaDetails: hostProperty.areaDetails ||    "",
    propertyDimensions: { length: hostProperty && hostProperty.propertyDimensions && hostProperty.propertyDimensions.length || "", width:hostProperty && hostProperty.propertyDimensions && hostProperty.propertyDimensions.width || "" },
  
    
  });
  const setUserData = (data) => {
    store.dispatch({
      type: "set_host_property",
      payload: data,
    });
  };



  const setPropertyDetails = (details) => {
    setProperty({ ...property, ...details });
  };
  const validateAndUpdatePropertyDimensions = (newLength, newWidth) => {
    const newArea = Number(newLength) * Number(newWidth);
    if (newArea <= property.areaDetails) {
      setProperty({ ...property, propertyDimensions: { length: newLength, width: newWidth } });
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSubmit = () => {
   
    const isAreaDetailsValid = !!property.areaDetails;
    const arePropertyDimensionsValid =
      !!property.propertyDimensions.length &&
      !!property.propertyDimensions.width;
    const isCurrentFloorValid = !!property.currentFloor;
    const isTotalFloorsValid = !!property.totalFloors;
    // const isBathroomsValid = !!property.bathrooms;
    const isPersonalRoomsValid = !!property.personalRooms;
    const isFurnishedStatusValid = !!property.furnishedStatus;
    const isConstructionValid = !!property.construction;
    const isWashroomValid = !!property.numberOfWashroom;

    
    if (
      !(
        isAreaDetailsValid &&
        arePropertyDimensionsValid &&
        isCurrentFloorValid &&
        isTotalFloorsValid &&
        // isBathroomsValid &&
        isPersonalRoomsValid &&
        isFurnishedStatusValid &&
        isConstructionValid &&
        isWashroomValid
      )
    ) {
      showSnackbar('Validation failed. Please fill in all required fields.', 'error');

     
      return;
    }

   
    setUserData({
      ...property
    });
    Navigate(`/property-listing/${id}/step3`);
  };

  const classes = useStyles();


 
  
  return (
    <>
   
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>
                

                <br />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Tell About Your Property
                      </div>
                      <div>Area Details:</div>

                      <br />
                      <div className={classes.inputContainer}>
                      <TextField
                            label="Area"
                            type="number"
                            variant="outlined"
                            fullWidth
                            sx={{width:"50%"}}
                            value={property.areaDetails}
                            onChange={(e) => setPropertyDetails({ areaDetails: e.target.value })}
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                          />
                      
                        <InputLabel>Dimensions</InputLabel>
                        <select
                className="px-3"
                value={property.areaType}
                onChange={(e) => setPropertyDetails({ areaType: e.target.value })}
                style={{ padding: "10px", border: "1px solid #ddd", borderRadius: "5px", boxSizing: "border-box" }}
              >
                <option value="sq.ft">sq.ft</option>
                <option value="sq.yard">sq.yard</option>
                <option value="sq.m">sq.m</option>
                <option value="acres">acres</option>
                <option value="marla">marla</option>
                <option value="cents">cents</option>
              </select>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Property Dimensions:
                      </div>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Length"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={property.propertyDimensions.length}
                            onChange={(e) => validateAndUpdatePropertyDimensions(e.target.value, property.propertyDimensions.width)}
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Width"
                            type="number"
                            variant="outlined"
                            value={property.propertyDimensions.width}
                            fullWidth
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                            onChange={(e) => validateAndUpdatePropertyDimensions(property.propertyDimensions.length, e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Floor Details:</div>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Current Floor"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={property.currentFloor}
                            onChange={(e) => setPropertyDetails({ currentFloor: e.target.value })}
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Total Floors"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={property.totalFloors}
                            onChange={(e) => setPropertyDetails({ totalFloors: e.target.value })}
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>

         

               

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Number of Washrooms:
                      </div>

                      <TextField
                      value={property.numberOfWashroom}
                      onChange={(e) => setPropertyDetails({numberOfWashroom : e.target.value })}
                        label="Enter Number of Washrooms"
                        type="number"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Number of Personal Rooms:
                      </div>

                      <TextField
                       value={property.personalRooms}
                       onChange={(e) => setPropertyDetails({personalRooms : e.target.value })}
                        label="Enter Number of Personal Rooms"
                        type="number"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />
                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Furnished Status:</div>
                      <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                        <button
                          onClick={() =>{
                            setProperty({...property , furnishedStatus:"Yes"})
                          }}
                          value={property.furnishedStatus}
                          style={{
                            border: "2px solid grey",
                            borderRadius:"5px", 
                            padding: "2px 20px",
                            backgroundColor:
                            property.furnishedStatus === "Yes" ? "#fda94f" : "inherit",
                            color: property.furnishedStatus === "Yes" ? "white" : "inherit",
                            
                          }}
                        >
                          Yes
                        </button>
                        <button
                          onClick={() =>{
                            setProperty({...property , furnishedStatus:"No"})
                          }}
                          value={property.furnishedStatus}
                          style={{
                            border: "2px solid grey",
                            borderRadius:"5px", 
                            padding: "2px 20px",
                            backgroundColor:
                            property.furnishedStatus === "No" ? "#fda94f" : "inherit",
                            color:property.furnishedStatus === "No" ? "white" : "inherit",
                          }}
                        >
                          No
                        </button>
                      </ButtonGroup>
                     
                    </div>
                  </div>
                </div>
                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Construction :</div>

                      <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                        <button
                          onClick={() =>{
                            setProperty({...property , construction:"Yes"})
                          }}
                          value={property.construction}
                          style={{
                            border: "2px solid grey",
                            borderRadius:"5px", 
                            padding: "2px 20px",
                            backgroundColor:
                            property.construction==="Yes" ? "#fda94f" : "inherit",
                            color:   property.construction==="Yes" ? "white" : "inherit",
                          }}
                        >
                          Yes
                        </button>
                        <button
                        onClick={() =>{
                          setProperty({...property , construction:"No"})
                        }}
                        value={property.construction}
                          style={{
                            border: "2px solid grey",
                            borderRadius:"5px", 
                            padding: "2px 20px",
                            backgroundColor:
                            property.construction==="No" ? "#fda94f" : "inherit",
                            color:   property.construction=== "No" ? "white" : "inherit",
                          }}
                        >
                          No
                        </button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container} style={{marginBottom:"15px"}}>
                  <div className="container">
                    <Grid container spacing={2} >
                      <Grid item xs={12}>
                        <div>{/* Your existing content */}</div>
                      </Grid>
                      <Grid item xs={12} className={classes.nextback}>
                        <Link to={`/property-listing/${id}/step1`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Back
                          </Button>
                        </Link>

                      
                          <Button
                           onClick={()=>{
                            handleSubmit()
                           }}
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Next
                          </Button>
                    
                      </Grid>
                    </Grid>

                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                      >
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ScrollToTopButton /> */}
        </div>
    
    </>
  );
};


const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty,

});


export default connect(mapStateToProps)(CommercialStep);
