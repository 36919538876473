import React, { useState ,useEffect} from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { useParams } from "react-router-dom";



// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const ProjectDetails = ({ onClose }) => {

  const navigate = useNavigate()
  const { id } = useParams();

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [leadData, setLeadData] = useState({
    projectName: "", projectOwner: "", budget: "",
    //  phoneNumber: "", fax: "",
    city: "", state: "", street: "", country: "", zipCode: "",
    industry: ""
    // , owner: ""
    , website: "", employNumber: "", leadSource: "",
    projectStatus: "", projectCategory: "",
    startDate :"", endDate:"", title: "", description: "",
  })

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {

    try {
      const response = await Api.getProjectById(id);
      console.log(response.data.data)
      setLeadData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }




  

  const backToPage = () => {
    navigate("/projects")
  }










  return (
    <>

    <Topbar/>
 
      <Box m="20px"  className="margin_in_mobile_ipad"

        sx={{

          // border: 'none',



          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          // borderRadius: "10px"

          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}

      >
        

         


        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Project Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Project Name"
  value={leadData.projectName}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 15) {
//       setLeadData({ ...leadData, projectName: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
  className="class_input"
  id="outlined-controlled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, lastName: inputText });
    }
  }}
  variant="outlined"
/> */}


<TextField
  className="class_input"
  id="outlined-controlled"
  label="Project Owner"
  value={leadData.projectOwner}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 15) {
//       setLeadData({ ...leadData, projectOwner: inputText });
//     }
//   }}
  variant="outlined"
/>



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}



<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Budget"
  value={leadData.budget}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 35) {
//       setLeadData({ ...leadData, budget: inputText });
//     }
//   }}
  variant="outlined"
/>


{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Email"
  value={leadData.email}
  onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}



{/* 
<TextField
type="date"
  className="class_input"
  id="outlined-controlled"
  label="Start Date"
  value={leadData.startDate}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, startDate: inputText });
    }
  }}
  variant="outlined"
/> */}




{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Phone"
  value={leadData.phoneNumber}
  onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}

{/* <div>Project Status</div> */}

{/* <TextField
type="date"
  className="class_input"
  id="outlined-controlled"
  label="End Date"
  value={leadData.endDate}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, endDate: inputText });
    }
  }}
  variant="outlined"
/> */}
{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>

<Box       sx={{

// border: 'none',
boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
borderRadius: "10px"
, padding:"20px"
, width:"100%"

// "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//   color: `${colors.grey[100]} !important`,
// },
}} >
       <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Address Information

</Typography>
<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">
<TextField
  className="class_input"
  id="outlined-controlled"
  label="Street"
  value={leadData.street}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 40) {
//       setLeadData({ ...leadData, street: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Street"
  value={leadData.street}
  onChange={(e) => setLeadData({ ...leadData, street: e.target.value })}
  sx={{ paddingTop: "" }}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="City"
  value={leadData.city}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 20) {
//       setLeadData({ ...leadData, city: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="City"
  value={leadData.city}
  onChange={(e) => setLeadData({ ...leadData, city: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="State"
  value={leadData.state}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 20) {
//       setLeadData({ ...leadData, state: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="State"
  value={leadData.state}
  onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Country"
  value={leadData.country}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 20) {
//       setLeadData({ ...leadData, country: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Country"
  value={leadData.country}
  onChange={(e) => setLeadData({ ...leadData, country: e.target.value })}
/> */}



<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Postal Code"
  value={leadData.zipCode}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 10) {
//       setLeadData({ ...leadData, zipCode: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Postal Code"
  value={leadData.zipCode}
  onChange={(e) => setLeadData({ ...leadData, zipCode: e.target.value })}
/> */}

</Box>










       </Box>

</div>


      


        



      

          <br /> 
          <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

          <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}   >
        <Typography
        

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Company Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">


<TextField

  className="class_input"
  id="outlined-controlled"
  label="Industry"
  value={leadData.industry}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 20) {
//       setLeadData({ ...leadData, industry: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Industry"
  value={leadData.industry}
  onChange={(e) => setLeadData({ ...leadData, industry: e.target.value })}
/> */}

{/* 
<TextField

  className="class_input"
  id="outlined-controlled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, owner: inputText });
    }
  }}
  variant="outlined"
/> */}

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => setLeadData({ ...leadData, owner: e.target.value })}
/> */}

<TextField

  className="class_input"
  id="outlined-controlled"
  label="WebSite"
  value={leadData.website}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 20) {
//       setLeadData({ ...leadData, website: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="WebSite"
  value={leadData.website}
  onChange={(e) => setLeadData({ ...leadData, website: e.target.value })}
/> */}


<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Number of Employees"
  value={leadData.employNumber}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 3) {
//       setLeadData({ ...leadData, employNumber: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Number of Employees"
  value={leadData.employNumber}
  onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
/> */}


<TextField

  className="class_input"
  id="outlined-controlled"
  label="Lead Source"
  value={leadData.leadSource}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 15) {
//       setLeadData({ ...leadData, leadSource: inputText });
//     }
//   }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Lead Source"
  value={leadData.leadSource}
  onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
/> */}

</Box>
        </Box>

        

        <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
        <Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Additional Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

<Grid >
  <div>Project Status</div>
  <FormControl fullWidth >

    <Select
    sx={{height:"40px"}}
      labelId="second-select-label"
      id="second-select"
      fullWidth
      value={leadData.projectStatus}
    //   onChange={(e) => setLeadData({ ...leadData, projectStatus: e.target.value })}
    >
      <MenuItem value="Not Started">Not Started</MenuItem>
      <MenuItem value="In Progress"> In Progress</MenuItem>
      <MenuItem value="On Hold">On Hold</MenuItem>
      <MenuItem value="Completed">Completed</MenuItem>

    </Select>
  </FormControl>
</Grid>

<Grid >
  <div>Project Category</div>
  <FormControl fullWidth>

    <Select
         sx={{height:"40px"}}
      labelId="third-select-label"
      id="third-select"
      fullWidth
      defaultValue=""
      value={leadData.projectCategory}
    //   onChange={(e) => setLeadData({ ...leadData, projectCategory: e.target.value })}
    >
      <MenuItem value="Marketing">Marketing</MenuItem>
      <MenuItem value="Sales"> Sales</MenuItem>
      <MenuItem value="Product Development">Product Development</MenuItem>
    </Select>
  </FormControl>
</Grid>

<Grid >
  <div >Start Date</div>
  <FormControl fullWidth>

    {/* <Select
         sx={{height:"40px"}}
      labelId="first-select-label"
      id="first-select"
      fullWidth
      defaultValue=""
      value={leadData.organization}
      onChange={(e) => setLeadData({ ...leadData, organization: e.target.value })}
    >

      <MenuItem value="org_1">Org. 1</MenuItem>
      <MenuItem value="org_2">Org. 2</MenuItem>
      <MenuItem value="org_3">Org. 3 </MenuItem>
    </Select> */}

<TextField
type="date"
className="class_input"
id="outlined-controlled"
// label="Lead Source"
value={leadData.startDate}
// onChange={(e) => {
//   const inputText = e.target.value;
//   if (inputText.length <= 15) {
//     setLeadData({ ...leadData, startDate: inputText });
//   }
// }}
variant="outlined"
/>
  </FormControl>
</Grid>

<Grid >
  <div >End Date</div>
  <FormControl fullWidth>

    {/* <Select
         sx={{height:"40px"}}
      labelId="first-select-label"
      id="first-select"
      fullWidth
      defaultValue=""
      value={leadData.permission}
      onChange={(e) => setLeadData({ ...leadData, permission: e.target.value })}
    >

      <MenuItem value="option_1">Option 1</MenuItem>
      <MenuItem value="option_2">Option 2</MenuItem>
      <MenuItem value="option_3">Option 3 </MenuItem>
    </Select> */}

<TextField
type="date"
className="class_input"
id="outlined-controlled"
// label="Lead Source"
value={leadData.endDate}
// onChange={(e) => {
//   const inputText = e.target.value;
//   if (inputText.length <= 15) {
//     setLeadData({ ...leadData, endDate: inputText });
//   }
// }}
variant="outlined"
/>
  </FormControl>
</Grid>




</Box>
        </Box>


            </div>
         

      

          <br />


         

      

              
          <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

                
           <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}} >
           <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Title

</Typography>

<br />
<br/>

{/* <TextField
className="class_input"
id="second-text-field"
label="Title"
fullWidth

value={leadData.title}
onChange={(e) =>
  setLeadData({ ...leadData, title: e.target.value })
}
/> */}
<TextField
                        label="Title"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.title}
                        multiline

                        // onChange={(e) => {
                        //   const inputText = e.target.value;
                        //   const words = inputText.trim().split(/\s+/);
                        //   if (words.length <= 100) { 
                        //     setLeadData({ ...leadData, title: inputText });
                        //   }
                        // }}
                        // onChange={(e) =>
                        //   setLeadData({ ...leadData, title: e.target.value })
                        // }

                        style={{width:"100%"}}
                   
                      />
           </Box>


             
                
             <Box    sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}>   <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Description

</Typography>
<br />
<br/>
<Box
component="form" 

noValidate
autoComplete="off"
>



<TextField
                        label="Description"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.description}
                        multiline

                        // onChange={(e) => {
                        //   const inputText = e.target.value;
                        //   const words = inputText.trim().split(/\s+/);
                        //   if (words.length <= 500) { 
                        //     setLeadData({ ...leadData, description: inputText });
                        //   }
                        // }}
                        // onChange={(e) =>
                        //   setLeadData({ ...leadData, description: e.target.value })
                        // }

                        style={{width:"100%"}}
                   
                      />

</Box></Box>
            </div>



           
       


           
     


       



        
      


      

    









   




       

      

       


       


    

<br/>


            <div style={{ display: "flex", justifyContent: "end", alignItems: "center"}}>
       
         
            <div style={{ display: "flex", gap: "10px" }}>

              {/* <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button> */}


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
        

        </div>


   






          
       
     
  






        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};

export default ProjectDetails;
