import { NavLink ,useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import { highlights } from "../../components/data/index";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import axios from "axios";
import { useState, useRef } from "react";
import { Button, Grid, TextField, MenuItem, Box, Snackbar } from "@mui/material";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  priceInput: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  nextback: {
    justifyContent: "space-between",
    display: "flex",
  },

  mainContainer: {
    display: "flex",
    justifyContent: "space-between",


    // Media Query for Mobile
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },

    // Media Query for iPad
    [theme.breakpoints.between('sm', 900)]: {
      flexDirection: "column", // Change it as per your requirement for iPad
    },
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },

  subhead: {
    fontSize: "15px",
    fontWeight: "600",
  },
}));
const Step3 = ({ hostProperty }) => {

  const { id } = useParams();
  let linkTo = `/property-listing/${id}/step2`;

  if (hostProperty.Sub_property_selectedItem === 'plotland') {
    linkTo = `/property-listing/${id}/plot-step`;
  } else if (hostProperty.living_Part === 'commercial') {
    linkTo = `/property-listing/${id}/commercial-step`;
  }

  const Navigate = useNavigate()
  const fileTypes = ["JPG", "PNG", "GIF"];
  const fileInputRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState(hostProperty.highlights || []);
  const [price, setPrice] = useState(hostProperty.price || "");
  const [images, setImages] = useState(hostProperty.images || []);
  const [loading, setLoading] = useState(false)
  const setUserData = (data) => {
    store.dispatch({
      type: "set_host_property",
      payload: data,
    });
  };

  const classes = useStyles();
  const toggleOption = (name) => {
    if (selectedOptions.includes(name)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== name));
    } else if (selectedOptions.length < 2) {
      setSelectedOptions([...selectedOptions, name]);
    } else {
      // Replace the second selection with the new selection
      const updatedSelection = [...selectedOptions];
      updatedSelection.splice(1, 1, name); // Replace the second item
      setSelectedOptions(updatedSelection);
    }
  };
  const handleChange = async (newFiles) => {
    console.log(newFiles);
    if (!Array.isArray(newFiles) || newFiles.length === 0) {
      alert("Please select images to upload.");
      return;
    }
    
    try {
      const formData = new FormData();
      newFiles.forEach((image) => {
        formData.append("image", image);
      });
      setLoading(true)
      const response = await axios.post(`${BACKEND_URL}/upload_two`, formData);

      console.log(response);
      if (response.status === 200) {
        setLoading(false)
        const data = response.data;
        console.log(response);
        setImages([...images, ...data.imageUrls]);
      }
    } catch (uploadError) {
        setLoading(false)
      console.error("Error uploading images:", uploadError);
      alert("Something went wrong");
    }
  };

  const handleAddMore = () => {

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDeleteImage = (indexToDelete) => {
    const updatedImages = images.filter((_, index) => index !== indexToDelete);
    setImages(updatedImages);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSubmit = () => {

    const isPriceValid = !!price;
    const areImagesValid = images.length >= 5;
    const areHighlightsValid = selectedOptions.length > 0 && selectedOptions.length <= 2;


    if (!(isPriceValid && areImagesValid && areHighlightsValid)) {
      showSnackbar('Validation failed. Please fill in all required fields.', 'error');


      return;
    }


    setUserData({
      price: price,
      images: images,
      highlights: selectedOptions,
    });
    Navigate(`/property-listing/${id}/step4`);
  };

  return (
    <>
    
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>
                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Now, set your price</div>
                      <div style={{ marginBottom: "15px" }}>You can change it anytime.</div>

                      <TextField
                        label="Price"
                        variant="outlined"
                        type="number"
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        className={classes.priceInput}
                      />
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Add some photos of your house
                      </div>
                      <div>
                        You'll need 5 photos to get started. You can add more or
                        make changes later.
                      </div>
                      <br />

                      {images.length >= 1 && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ImageList
                            sx={{
                              width: 700,
                              height: 450,
                              transform: "translateZ(0)",
                            }}
                            rowHeight={200}
                            gap={1}
                          >
                            {images.map((item, index) => {
                              {
                                return (
                                  <ImageListItem key={item}>
                                    <img
                                      {...srcset(item, 250, 200)}
                                      alt="images"
                                      loading="lazy"
                                    />
                                    <ImageListItemBar
                                      position="top"
                                      actionIcon={
                                        <IconButton
                                          onClick={() => {
                                            handleDeleteImage(index);
                                          }}
                                          aria-label={`close ${item.title}`}
                                          sx={{ color: "white" }}
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                      actionPosition="right"
                                      sx={{
                                        background:
                                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                      }}
                                    />
                                  </ImageListItem>
                                );
                              }
                            })}
                          </ImageList>
                        </div>
                      )}
                      <input
                        type="file"
                        id="imageInput"
                        ref={fileInputRef} // Assign the ref to the file input
                        onChange={(e) =>
                          handleChange(Array.from(e.target.files))
                        }
                        name="files"
                        multiple
                        accept={fileTypes.map((type) => `.${type}`).join(",")}
                        style={{ display: "none" }} // Hide the actual file input
                      />
                      <div style={{ display: "flex", justifyContent: "center" }}>

                        <Button
                          onClick={() => {
                            handleAddMore();
                          }}
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#fda94f",

                            border: "2px solid #fda94f ",
                          }}
                        >
                          Upload
                        </Button>





                      </div>



                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Next let's describe your house
                      </div>

                      <div className={classes.subhead}>
                        Choose upto 2 highlights ,we will use these to get your
                        description started
                      </div>
                      <br />

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {highlights.map((data, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Item
                              className="Capitalise"
                              onClick={() => toggleOption(data.title)}
                              sx={{
                                padding: 2,
                                backgroundColor: selectedOptions.includes(
                                  data.title
                                )
                                  ? "#fda94f"
                                  : "inherit",
                                color: selectedOptions.includes(data.title)
                                  ? "white"
                                  : "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data.title}
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                      <br />
                    </div>
                  </div>
                </div>

                <div className={classes.container} style={{ marginBottom: "15px" }}>
                  <div className="container">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>{/* Your existing content */}</div>
                      </Grid>
                      <Grid item xs={12} className={classes.nextback}>
                        <Link to={linkTo}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                        {/* ///property-listing/step4 */}

                        <Button
                          onClick={() => {
                            handleSubmit()
                          }}
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#fda94f",

                            border: "2px solid #fda94f ",
                          }}
                        >
                          Next
                        </Button>

                      </Grid>
                    </Grid>

                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                      >
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      
    </>
  );
};


const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty,

});


export default connect(mapStateToProps)(Step3);
