import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import axios from 'axios';
import Api from '../../Api';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Topbar from "../global/Topbar";
import EditIcon from '@mui/icons-material/Edit';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const Companies = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [company, setCompany] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);


  const openPopup = () =>{
    navigate("/create-companies");
  
  }

  useEffect (()=>{
    fetchData()
  } ,[isDelete])


  const fetchData = async () => {
    try {
const response =await Api.getAll_company()
if(response.data.data && Array.isArray(response.data.data)){
  setCompany(mapPlotData(response.data.data));
  console.log(response.data.data)
}
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }



  useEffect(() => {
    !isLogging && navigate('/')
  }, [])

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };


  const handleDeleteConfirm = async () => {
    try {
    

      await Api.deleteCompany(selectedUserId);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
     
      console.error("Error deleting contact:", error);
    }
  }

  const columns = [
    { field: 'sno', headerName: 'S.No', width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Comapny', width: 170,
  
    renderCell: (params) => (
      <NavLink to={`/company-details/${params.row.id}`}>
        {params.value}
        </NavLink>
    ),

  },
    { field: 'phone', headerName: 'Phone', width: 170 },
    { field: 'billing_street', headerName: ' Street', width: 170 },
    { field: 'billing_city', headerName: ' City', width: 170 },
    { field: 'billing_state', headerName: ' State', width: 170 },
    { field: 'billing_country', headerName: ' Country', width: 170 },

    ,

    {
      field: 'edit_company',
      headerName: 'Edit Company'
      , width: 170 ,
      renderCell: (params) => (
        <NavLink to={`/edit-company/${params.row.id}`}>
          <EditIcon />
        </NavLink>
      ),
    }
,

    {
      field: 'delete_company',
      headerName: 'Delete Company'
      , width: 170 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    },

    // {
    //   field: ' ',
    //   headerName: ' ',
    //   flex: 1,
    //   width: 100,

    // },



    // {
    //   field: 'blank',
    //   headerName: ''
    //   , width: 40 ,
    //   renderCell: (params) => (
    //     <img src="/img/star.png" alt="" />
    //   ),
    // }

    // ,

    , {
      field: 'action',
      headerName: 'Action'
      , width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },


    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <NavLink to={`/edit-property/${params.row.id}`}> 
    //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id)}>
    //       Edit
    //     </Button>
    //     </NavLink>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id)}>
    //       Delete
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {

    const staticData = [
      { id: 1, sno: 1, company: 'kareena', phone: 'Facility Manager ', billing_street: '8580414459', billing_city: "karinachauhan2106@gmail.com", billing_state: 'gh', billing_country: 'jhygyjy' },

      // Add more tasks as needed
    ];
    setPlotData(staticData);

    // fetch(`${BACKEND_URL}/admin/rooms`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setPlotData(mapPlotData(data.data))
    //   })
    //   .catch((error) => console.error('Error fetching plot data:', error));
  }, []
    //  [isSnackbarOpen]
  ); // Add isSnackbarOpen as a dependency

  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || "No",
      name: d.industry || 'No',
      phone:d.mobileNumber || "No",
      billing_street:d.street,
      billing_city: d.city || 'No',
      billing_state: d.state || "No",
      billing_country:d.country || "No"
    }));
  };

  const handleEdit = (id) => {
    console.log(`Edit plot with ID ${id}`);
  };





  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
   <>
        <Topbar />

        <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Companies" subTitle="New Company"  openPopup={openPopup} />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={company} columns={columns} />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="property deleted successfully" />
    </Box>
   </>
  );
};

export default Companies;
