import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Topbar from "../global/Topbar";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
 






const Deals = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
  const [deal, setDeal] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {

    const response = await Api.getAll_Deal()
    if(response.data && Array.isArray(response.data.data)){
      setDeal(mapPlotData(response.data.data))
      console.log(response.data.data)
    }else{
      console.error('Data is not an array or is missing:', response.data.data);
    }
  }

  useEffect(() => {
    !isLogging && navigate('/')
  }, [])

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "deal_name", headerName: "Deal Name", width: 170 },
    { field: "company", headerName: "Company", width: 170 },
    { field: 'pipeline', headerName: 'Pipeline', width: 170 },
    { field: 'forecast_close_date', headerName: 'Forecast close Date', width: 170 },
    { field: 'user_responsible', headerName: 'User Responsible ', width: 170 },
    { field: 'deal_value', headerName: 'Deal Value ', width: 170 },
    // { field: 'lead_created', headerName: 'Lead Created ', flex: 1 },
    // { field: 'lead_owner', headerName: 'Lead Owner ', flex: 1 },

    {
      field: 'blank',
      headerName: " "
      , width: 20 ,
      renderCell: (params) => (
        <img src="/img/star.png" alt="" />
      ),
    }

    ,

    , {
      field: 'action',
      headerName: 'Action',
       width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },




    // { field: "location", headerName: "Location", flex: 1 },
    // { field: "type", headerName: "Type", flex: 1 },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id,params.row.type)}>
    //       Edit
    //     </Button>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id,params.row.type)}>
    //       Delete
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {



    const staticData = [
      { id: 1, sno: 1, deal_name: 'deal 1', company: 'abc ', user_responsible: 'user1', forecast_close_date: "12/69/4567", deal_value: "$326" },
      { id: 2, sno: 2, deal_name: 'deal 2', company: 'xyz', user_responsible: 'user2 ', forecast_close_date: "12/69/4567", deal_value: "$78" },

      // Add more tasks as needed
    ];
    setPlotData(staticData);

    // fetch(`${BACKEND_URL}/admin/featured-property`)
    //   .then((response) => response.json())        
    //   .then((data) => {
    //     // console.log(data);
    //     setPlotData(mapPlotData(data.data));
    //   })
    //   .catch((error) => console.error("Error fetching plot data:", error));
  }, []
    //  [isSnackbarOpen]
  ); // Add isSnackbarOpen as a dependency

  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index ,
      deal_name: d.dealName || "No",
      company:d.company || "No",
      pipeline: d.leadSource || "No",
      forecast_close_date:d.createdAt.slice(0,10) || "No",
      user_responsible:d.contactName || "No",
      deal_value:d.ammount || "No"
    }));
  };

  return (
<>
    <Topbar/>
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Deals" subTitle="New Deal"/>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          rows={deal}
          columns={columns}
        />
      </Box>

      {/* Delete Confirmation Dialog */}
      {/* <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Success Snackbar */}
      {/* <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" /> */}
    </Box>

    </>
  );
};

export default Deals;
