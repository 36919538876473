import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../components/Cookies";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Topbar from "../global/Topbar";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TextField, FormControl, InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditReminders = ({ isLogging }) => {
  const [reminderType, setReminderType] = useState("");
  const [reminder, setReminder] = useState({
    dueDate: "", frequency: ""
    , description: "", time: ""


  })
  const { id } = useParams();



  const [getReminder, setgetReminder] = useState([]);

const[editPopUp, setEditPopUp] = useState(true)

  const [open, setOpen] = useState(false);





  const openPopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);


  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const token = getCookie("leadmanagement");










  const [reminders , setReminders] = useState("")
  
  useEffect(() => {
    fetchData();
   
  }, []);



  const fetchData= async ()=>{

    try {
      const response= await Api.get_Remind_By_Id(id , token);
        console.log( "response of reminder by id" ,  response)
        setReminders(response.data.data)
        setReminderType(response.data.data.frequency);
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const backToPage = () => {
    navigate("/reminders")
  }

  const handleUpdate = async () => {
    try {

      const response = await Api.update_Reminder(id, { ...getReminder }, token );


      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/leads"),
        });
        
      }
    } catch (error) {

      console.error("Error updating property:", error);
    }
  };




  const handleEdit = async ()=>{
    setEditPopUp(true);
  }

   const handleCloseEdit = () =>{
    setEditPopUp(!editPopUp);
    navigate("/reminders")
   }

  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Api.delete_reminder(selectedUserId, token);
      setIsDelete(!isDelete);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "date", headerName: "Date", width: 200},
    { field: "time", headerName: "Time", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    {
      field: 'edit_reminder',
      headerName: 'Edit Reminder'
      , width: 100 ,
      
      renderCell: (params) => (
        // <Link to={`/update-reminder/${params.row.id}`} style={{color:"black"}}>
          
          <EditIcon  onClick = {handleEdit}/>
        // </Link>
        
      ),

    
    }
    ,  {
      field: 'delete_reminder',
      headerName: 'Delete Reminder'
      , width: 100 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)} style={{color:"black"}}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }
    // { field: "edit_reminder", headerName: "Edit Reminder", width: 300  },




    // {
    //   field: "delete_note",
    //   headerName: "Delete Reminder",
    //   width: 100,
    //   renderCell: (params) => (
    //     <button onClick={() => handleDeleteClick(params.row.id)}>
    //       <DeleteForeverOutlinedIcon />
    //     </button>
    //   ),
    // },
  ];


  const handleInputeChange = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    setReminders({ ...reminders, [e.target.name]: e.target.value })


  }

  const mapReminderData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      message: d.description || "No",
      date: d.dueDate || "Daily",

      time: d.time || "No",

      type: d.frequency || "No"

    }));
  };



  

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad">
        {/* <Header
          title="Reminders"
          subTitle="Add Reminder"
          openPopup={openPopup}
        /> */}
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            },
          }}
        >
    
        </Box>

     




        <Dialog
         open={editPopUp} 
        //  onClose={handleCloseEdit}
         >
          <DialogTitle >Edit Reminder</DialogTitle>
         
          <DialogContent >
              <DialogContentText id="alert-dialog-description">
                <div style={{ display: "flex", gap: "50px" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      checked={reminderType === "once" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      value="once"
                      style={{ height: "18px", width: "18px" }}
                      id="onceRadioButton"
                    
                    />

                    <label>
                      <strong>Once</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "daily" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      value="daily"
                      style={{ height: "18px", width: "18px" }}
                      id="dailyRadioButton"
                    
                    />

                    <label>
                      {" "}
                      <strong>Daily</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "weekly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="weeklyRadioButton"
                      value="weekly"
                    />

                    <label>
                      {" "}
                      <strong>Weekly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "monthly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="monthlyRadioButton"
                      value="monthly"
                    />

                    <label>
                      {" "}
                      <strong>Monthly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "yearly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="yearlyRadioButton"
                      value="yearly"
                    />

                    <label>
                      {" "}
                      <strong>Yearly</strong>
                    </label>
                  </div>
                </div>
                <br />

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto ",
                    width: "100%",
                  }}
                >
                  {reminderType === "once" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          // label="Select Date"
                          value={ reminders.dueDate}
                          name="dueDate"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          value={reminders.time}
                          name="time"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>




                    </>
                  ) : reminderType === "daily" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          value={reminders.time}
                          name="time"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      {/* <FormControl sx={{ m: 1 }}>
            <strong>User</strong>
            <Select
              //   value={age}
              //   onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "40px" }}
              icon={<PersonAddAltIcon />}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={10}>Russel Copeland</MenuItem>
              <MenuItem value={20}>Brian Jhonson</MenuItem>
            </Select>
          </FormControl> */}
                    </>
                  ) : reminderType === "weekly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Week</strong>
                        <Select
                          //   value={age}
                          //   onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{ height: "40px" }}
                          icon={<PersonAddAltIcon />}
                          name="dueDate"
                          value={ reminders.dueDate}
                          onChange={handleInputeChange}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="monday">Monday</MenuItem>
                          <MenuItem value="tuesday">Tuesday</MenuItem>
                          <MenuItem value="wednesday">Wednesday</MenuItem>

                          <MenuItem value="thursday">Thursday</MenuItem>
                          <MenuItem value="friday">Friday</MenuItem>
                          <MenuItem value="saturday">Saturday</MenuItem>
                          <MenuItem value="sunday">Sunday</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          value={reminders.time}
                          name="time"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "monthly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          value={ reminders.dueDate}
                          name="dueDate"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          name="time"
                          value={reminders.time}
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "yearly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          value={ reminders.dueDate}
                          name="dueDate"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          value={reminders.time}
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : (
                    ""
                  )}


                </div>

                <br />
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <strong>Message</strong>
              


                  <TextField
                    // label="Message  "

                    type="text"

                    id="second-text-field"
                    name="description"
                    multiline
value={reminders.description}
                    onChange={handleInputeChange}


                    style={{ width: "100%" }}

                  />




                </FormControl>



              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "white ",
                backgroundColor: "#9B56FF",



                "&:hover": {
                  color: "#9B56FF"
                }



              }} 
              onClick={handleUpdate}
              >Submit</Button>



              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "#9B56FF ",




                "&:hover": {
                  color: "#9B56FF"
                }



              }}



                onClick={handleCloseEdit}>Cancel</Button>
            </DialogActions>
        </Dialog>





      
      </Box>
    </>
  );
};

export default EditReminders;
