import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const EditHost = ({isLogging}) => {
  const { id } = useParams();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [userData, setUserData] = useState({});
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  
  useEffect(()=>{
    !isLogging && navigate('/')
  },[])


  const handleCloseSnackbar = () => {
    setErrorSnackbarOpen(false);
    setErrorMessage("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/admin/users/${id}`);
      setUserData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setErrorSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleFormSubmit = async (values) => {
    console.log("yash");
    console.log(values);
    setLoading(true);
    try {
      const response = await axios.put(`${BACKEND_URL}/admin/update/${id}` , values);
      if(response.data.message==="User updated successfully"){
        setSuccessSnackbarOpen(true);
        setLoading(false);
      }
     
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setErrorSnackbarOpen(true);
      setLoading(false);
    }



  };

  return (
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Edit USER" subtitle="Edit User Profile" />
      
      {Object.keys(userData).length > 0 && ( // Check if userData is not empty
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={{
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            email: userData.email || "",
            MobileNumber: userData.MobileNumber || "",
           
          }}
          validationSchema={checkoutSchema}
        >
          {({handleBlur , handleChange , values , handleSubmit , touched ,errors}) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={
                    !!touched.firstName &&
                    !!errors.firstName
                  }
                  helperText={
                    touched.firstName &&
                    errors.firstName
                  }
                  sx={{ gridColumn: "span 2" }}
                />

<TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="MobileNumber Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MobileNumber}
                name="MobileNumber"
                error={!!touched.MobileNumber && !!errors.MobileNumber}
                helperText={touched.MobileNumber && errors.MobileNumber}
                sx={{ gridColumn: "span 4" }}
              />
                {/* Rest of the form fields */}
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Update User"
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccessSnackbarOpen(false)}
          severity="success"
        >
          User Created Successfully
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  MobileNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    
  
});

export default EditHost;
