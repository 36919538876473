import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../components/Cookies";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Topbar from "../global/Topbar";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TextField, FormControl, InputAdornment } from "@mui/material";
import Switch from '@mui/material/Switch';
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



const label = { inputProps: { 'aria-label': 'Switch demo' } };






const Meetings = ({ isLogging, role }) => {
  const [meetingType, setMeetingType] = useState("once");
  const [meeting, setMeeting] = useState({
    name: "", email: "", mobileNumber: "", dueDate: "", frequency: ""
    , description: "", time: ""
  })
  const [meetingtask ,setMeetingTask] =useState([])

  const [open, setOpen] = useState(false);
  const [loading , setLoading] = useState(false)


  const [getMeeting, setGetMeeting] = useState([]);

  const handleCreateMeeting = async () => {
    setLoading(true)
    const payload = { ...meeting, frequency: meetingType }

    try {
      const response = await Api.create_Meeting(payload, token)
      console.log(payload)
      console.log("response of api to create meeting", response);

      if (response.data.message === "meeting created successfully") {
        setLoading(false)

        setOpen(false);
        toast.success('Successfully Created');
        fetchData()
        navigate("/meetings")
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }


  }


  const handleMeetingStatusChange = async (event, id) => {
    const newStatus = event.target.value;

    try {
      setLoading(true)
    const response =  await Api.update_Meeting(id, { status: newStatus }, token);
    console.log( "api response",response);

      const updatedTask = meetingtask.map((item) =>
        item.id === id ? { ...item, status: newStatus } : item
      );
      setMeetingTask(updatedTask);

      if(response.data.message == "Meeting updated successfully") {
        setLoading(false)
        toast.success("Meeting Status updated successfully")
      }
    } catch (error) {
      setLoading(false)
      console.error("Error updating lead status:", error);
    }
  };
  



  const openPopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [lead, setLead] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const token = getCookie("leadmanagement");

  const [users, setUsers] = useState([]);


  useEffect(() => {
    fetchData();
  }, [meetingtask , isDelete]);

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await Api.get_Meeting(token);
      setLoading(false)
      console.log("response of api get meeting", response);
      setGetMeeting(mapMeetingData(response.data.data))

    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  const getButtonColor = (leadStatus) => {
    switch (leadStatus) {
   
      case 'pending':
        return 'rgb(5, 102, 129)';
      case 'cancel':
        return 'rgb(58, 50, 133)';
      case 'confirm':
        return 'rgb(72, 110, 3)';
        default:
        return 'gray'; // Default color if status is not recognized
    }
  };



  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      await Api.delete_Meeting(selectedUserId, token);
      setLoading(false)
      setIsDelete(!isDelete);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "name", headerName: "Meeting With", width: 200 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "date", headerName: "Date", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,

      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) => handleMeetingStatusChange(e, params.row.id)}
          style={{
            backgroundColor: getButtonColor(params.value),
            padding: "2px ",
            width: "110px",
            color: "white",
            height:"35px"
            
          }}
        >
         <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="cancel">Cencel</MenuItem>
      <MenuItem value="confirm">Confirm</MenuItem>
     
        </Select>
      ),
    },
    { field: "time", headerName: "Time", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    // { field: "edit_reminder", headerName: "Edit Reminder", width: 300  },
    {
      field: "Delete Meeting",
      headerName: "Delete Meeting",
      width: 100,
      renderCell: (params) => (
        <button onClick={() => handleDeleteClick(params.row.id)}>
          <DeleteForeverOutlinedIcon />
        </button>
      ),
    },
  ];
  const handleInputeChange = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    setMeeting({ ...meeting, [e.target.name]: e.target.value })


  }


  const mapMeetingData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      name: d.name || "No",
      message: d.description || "No",
      date: d.dueDate || "Daily",
      status:d.status || "No",
      time: d.time || "No",
      type: d.frequency || "No"

    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad">
        <Header
          title="Meetings"
          subTitle="New Meeting"
          openPopup={openPopup}
        />
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            },
          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //  checkboxSelection
            rows={getMeeting}
            // rows={plotData}

            columns={columns}
          />
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Meeting?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>





        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"
           style={{ display: "flex", justifyContent: "space-between", 
           alignItems: "center" }}> <strong style={{ fontSize: "20px" }}>New Meeting</strong>  
             <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} /></DialogTitle>





          <DialogContent sx={{ height:"65vh"}}>
            <DialogContentText id="alert-dialog-description">
              <div className="meeting-scheduler" style={{ display: "flex", gap: "50px" , }}>
                <div style={{ display: "flex" }}>
                  <input
                    type="radio"
                    checked={meetingType === "once" ? true : false}
                    onChange={(e) => {
                      setMeetingType(e.target.value)
                      setMeeting({})
                    }}
                    style={{ height: "18px", width: "18px" }}
                    id="onceRadioButton"
                    value="once"
                  />

                  <label>
                    <strong>Once</strong>
                  </label>
                </div>

                <div style={{ display: "flex" }}>
                  <input
                    type="radio"
                    checked={meetingType === "daily" ? true : false}
                    onChange={(e) => {
                      setMeetingType(e.target.value)
                      setMeeting({})
                    }}
                    style={{ height: "18px", width: "18px" }}
                    id="dailyRadioButton"
                    value="daily"
                  />

                  <label>
                    {" "}
                    <strong>Daily</strong>
                  </label>
                </div>

                <div style={{ display: "flex" }}>
                  <input
                    type="radio"
                    checked={meetingType === "weekly" ? true : false}
                    onChange={(e) => {
                      setMeetingType(e.target.value)
                      setMeeting({})
                    }}
                    style={{ height: "18px", width: "18px" }}
                    id="weeklyRadioButton"
                    value="weekly"
                  />

                  <label>
                    {" "}
                    <strong>Weekly</strong>
                  </label>
                </div>

                <div style={{ display: "flex" }}>
                  <input
                    type="radio"
                    checked={meetingType === "monthly" ? true : false}
                    onChange={(e) => {
                      setMeetingType(e.target.value)
                      setMeeting({})
                    }}
                    style={{ height: "18px", width: "18px" }}
                    id="monthlyRadioButton"
                    value="monthly"
                  />

                  <label>
                    {" "}
                    <strong>Monthly</strong>
                  </label>
                </div>

                <div style={{ display: "flex" }}>
                  <input
                    type="radio"
                    checked={meetingType === "yearly" ? true : false}
                    onChange={(e) => {
                      setMeetingType(e.target.value)
                      setMeeting({})
                    }}
                    style={{ height: "18px", width: "18px" }}
                    id="yearlyRadioButton"
                    value="yearly"
                  />

                  <label>
                    {" "}
                    <strong>Yearly</strong>
                  </label>
                </div>
              </div>
              <br />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto ",
                  width: "100%",
                }}
              >
                {meetingType === "once" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <strong>Date</strong>

                      <TextField
                        type="date"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="dueDate"
                        onChange={handleInputeChange}

                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="time"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Name</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="name"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>email</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="email"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Mobile</strong>

                      <TextField
                        type="Number"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="mobileNumber"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                       {users.map((user) => (
                <MenuItem key={user._id} value= {user._id} >
                 {`${user.firstName} ${user.lastName}`} 
                </MenuItem>
              ))}
                      </Select>
                    </FormControl> */}
                  </>
                ) : meetingType === "daily" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="time"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>


                    <FormControl sx={{ m: 1 }}>
                      <strong>Name</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="name"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>email</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="email"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Mobile</strong>

                      <TextField
                        type="Number"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="mobileNumber"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>



                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}
                  </>
                ) : meetingType === "weekly" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <strong>Week</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                        name="dueDate"
                        onChange={handleInputeChange}

                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="monday">Monday</MenuItem>
                        <MenuItem value="tuesday">Tuesday</MenuItem>
                        <MenuItem value="wednesday">Wednesday</MenuItem>

                        <MenuItem value="thursday">Thursday</MenuItem>
                        <MenuItem value="friday">Friday</MenuItem>
                        <MenuItem value="saturday">Saturday</MenuItem>
                        <MenuItem value="sunday">Sunday</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        value={meeting.time}
                        name="time"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>


                    <FormControl sx={{ m: 1 }}>
                      <strong>Name</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        value={meeting.name}
                        name="name"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>email</strong>

                      <TextField
                        type="text"
                        value={meeting.email}
                        name="email"
                        onChange={handleInputeChange}
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}

                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Mobile</strong>

                      <TextField
                        type="Number"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        value={meeting.mobileNumber}
                        name="mobileNumber"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}
                  </>
                ) : meetingType === "monthly" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <strong>Date</strong>

                      <TextField
                        type="date"
                        name="dueDate"
                        onChange={handleInputeChange}

                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}

                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        name="time"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        value={meeting.time}
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Name</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="name"
                        value={meeting.name}
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>email</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="email"
                        value={meeting.email}
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Mobile</strong>

                      <TextField
                        type="Number"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="mobileNumber"
                        value={meeting.mobileNumber}
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}
                  </>
                ) : meetingType === "yearly" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <strong>Date</strong>

                      <TextField
                        type="date"
                        name="dueDate"
                        onChange={handleInputeChange}

                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}

                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="time"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Name</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="name"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>email</strong>

                      <TextField
                        type="text"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="email"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Mobile</strong>

                      <TextField
                        type="Number"
                        // label="Select Date"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        name="mobileNumber"
                        onChange={handleInputeChange}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}
                  </>
                ) : (
                  ""
                )}


              </div>

              <br />
              <FormControl sx={{ m: 1, width: "100%" }}>
                <strong>Message</strong>


                <br />

                <TextField
                  label="Message  "

                  type="text"

                  id="second-text-field"
                  name="description"

                  multiline

                  onChange={handleInputeChange}


                  style={{ width: "100%" }}

                />




                {/* <TextField
                  label="Enter message"
       
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={{ width: "100%" }}
                /> */}
              </FormControl>







              <br />


              {/* <FormControl sx={{ m: 1, width: "100%" }}>
                <strong>Automation</strong>
              





<div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex" , alignItems:"center" }}>
                <Switch {...label} />

                  <label>
                    <p>Email Automation</p>
                  </label>
                </div>

                <div style={{ display: "flex" , alignItems:"center" }}>
                <Switch {...label} />

                  <label>
                    {" "}
                    <p>Whatsapp Automation</p>
                  </label>
                </div>

            

              

             
              </div>
                   
              </FormControl> */}















            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={{
              border: "2px solid #9B56FF", height: "37.3px",
              color: "white ",
              backgroundColor: "#9B56FF",



              "&:hover": {
                color: "#9B56FF"
              }



            }}
            
            onClick={handleCreateMeeting} 
            >Submit</Button>



            <Button sx={{
              border: "2px solid #9B56FF", height: "37.3px",
              color: "#9B56FF ",




              "&:hover": {
                color: "#9B56FF"
              }



            }}



              onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {loading && <Loader />}
      </Box>
    </>
  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});
export default connect(mapStateToProps)(Meetings);

