import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';

import axios from "axios";
import Api from "../../Api";
import Topbar from "../global/Topbar";
import { NavLink, useNavigate } from "react-router-dom";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;





const Projects = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
  const [project, setProject] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);


  const openPopup = () =>{
    navigate("/create-project");
  
  }

  useEffect(() => {
    fetchData()
  }, [isDelete])

 


  const fetchData = async () => {

    try {
      const response = await Api.get_All_Project()

      if (response.data && Array.isArray(response.data.data)) {
        setProject(mapPlotData(response.data.data))
        console.log(response.data.data)
      } else {
        console.log("Data is not an array or is missing:'", response.data.dataresponse.data.data)
      }
    } catch (error) {
      console.log('Error fetching data:', error)
    }
  }

  useEffect(() => {
    !isLogging && navigate('/')
  }, [])

  

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Api.deleteProject(selectedUserId);
      
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
     
      console.error("Error deleting contact:", error);
    }
  };








  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },

    { field: "project_name", headerName: "Project Name", width: 170,
  
    renderCell: (params) => (
      <NavLink to={`/project-details/${params.row.id}`}>
        {params.value}
        </NavLink>
    ),

  },
    { field: "project_owner", headerName: "Project Owner", width: 170 },
    { field: "lead_source", headerName: "Lead Source", width: 170 },

    { field: "project_status", headerName: "Project Status", width: 170 },

    { field: 'project_category', headerName: 'Project Category', width: 170 },

    {
      field: 'edit_project',
      headerName: 'Edit Project'
      , width: 170 ,
      renderCell: (params) => (
        <NavLink to={`/edit-project/${params.row.id}`}>
          <EditIcon />
        </NavLink>
      ),
    }
,

    {
      field: 'delete_project',
      headerName: 'Delete Project'
      , width: 170 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    },
   


    , {
      field: 'action',
      headerName: 'Action'
      , width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },




  ];


  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      project_name: d.projectName || "No",
      project_owner: d.projectOwner || "No",
      lead_source: d.leadSource || "No",

      project_status: d.projectStatus || "No",
      project_category: d.projectCategory || "No",
      pipeline: d.progress,
      project_created: d.createdAt.slice(0,10),

    }));
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (

    <>
    <Topbar/>
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Projects" subTitle="New Projects" openPopup={openPopup}  />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
          
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          rows={project}
          columns={columns}
        />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" />
    </Box>

    </>
  );
};

export default Projects;
