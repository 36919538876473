import React, { useState ,useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';

// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditAgent = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate()


  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [leadData, setLeadData] = useState()
  useEffect(() => {
    fetchData();
  }, []);


  const fetchData= async ()=>{

    try {
      const response= await Api.getAgentById(id);
        console.log(response.data.data)
        setLeadData(response.data.data)
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  const backToPage = () => {
    navigate("/agents")
  }









  const handleUpdate = async () => {
    try {

      const response = await Api.editAgent(id, { ...leadData } );


      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/agents"),
        });
        
      }
    } catch (error) {

      console.error("Error updating property:", error);
    }
  };










  return (
    <>

<Topbar />

    {leadData && (
      <>
    <Box m="20px" className="margin_in_mobile_ipad"

sx={{

  // border: 'none',



  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
  // borderRadius: "10px"

  // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
  //   color: `${colors.grey[100]} !important`,
  // },
}}

>





<div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Contact Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
id="outlined-controlled"
label="First Name"
value={leadData.firstName}
onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<TextField
className="class_input"
id="outlined-controlled"
label="First Name"
value={leadData.firstName}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setLeadData({ ...leadData, firstName: inputText });
}
}}
variant="outlined"
/>

<TextField
className="class_input"
id="outlined-controlled"
label="Last Name"
value={leadData.lastName}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setLeadData({ ...leadData, lastName: inputText });
}
}}
variant="outlined"
/>



{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Last Name"
value={leadData.lastName}
onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}



<TextField
className="class_input"
id="outlined-controlled"
label="Email"
value={leadData.email}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 35) {
setLeadData({ ...leadData, email: inputText });
}
}}
variant="outlined"
/>


{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Email"
value={leadData.email}
onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}




<TextField
type="number"
className="class_input"
id="outlined-controlled"
label="Phone"
value={leadData.mobileNumber}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setLeadData({ ...leadData, mobileNumber: inputText });
}
}}
variant="outlined"
/>

{/* <TextField
type="password"
className="class_input"
id="outlined-controlled"
label="Password"
value={leadData.password}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setLeadData({ ...leadData, password: inputText });
}
}}
variant="outlined"
/> */}


{/* <TextField
      type={showPassword ? "text" : "password"}
      // placeholder="Enter Password"
      className="class_input"
id="outlined-controlled"
label="Password"
value={leadData.password}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setLeadData({ ...leadData, password: inputText });
}
}}
variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    /> */}

{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Phone"
value={leadData.mobileNumber}
onChange={(e) => setLeadData({ ...leadData, mobileNumber: e.target.value })}
/> */}


{/* <TextField
type="number"
className="class_input"
id="outlined-controlled"
label="Fax"
value={leadData.fax}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setLeadData({ ...leadData, fax: inputText });
}
}}
variant="outlined"
/> */}
{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Fax"
value={leadData.fax}
onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>

<Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
<Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Additional Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

<Grid >
<div>Position</div>
<FormControl fullWidth >

<Select
sx={{height:"40px"}}
labelId="second-select-label"
id="second-select"
fullWidth
value={leadData.position}
onChange={(e) => setLeadData({ ...leadData, position: e.target.value })}
>
<MenuItem value="Manager">Manager</MenuItem>
<MenuItem value="Department Head">Department Head</MenuItem>
<MenuItem value="Team Leader">Team Leader</MenuItem>
<MenuItem value="Project Manager">Project Manager</MenuItem>

</Select>
</FormControl>
</Grid>

<Grid >
<div>Department</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="third-select-label"
id="third-select"
fullWidth
defaultValue=""
value={leadData.department}
onChange={(e) => setLeadData({ ...leadData, department: e.target.value })}
>
<MenuItem value="sales">Sales</MenuItem>
<MenuItem value="marketing">Marketing</MenuItem>
<MenuItem value="product_development">Product Development</MenuItem>
<MenuItem value="management">Management</MenuItem>

</Select>
</FormControl>
</Grid>

{/* <Grid >
<div >Organization</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="first-select-label"
id="first-select"
fullWidth
defaultValue=""
value={leadData.organization}
onChange={(e) => setLeadData({ ...leadData, organization: e.target.value })}
>

<MenuItem value="org_1">Org. 1</MenuItem>
<MenuItem value="org_2">Org. 2</MenuItem>
<MenuItem value="org_3">Org. 3 </MenuItem>
</Select>
</FormControl>
</Grid>

<Grid >
<div >Permission</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="first-select-label"
id="first-select"
fullWidth
defaultValue=""
value={leadData.permission}
onChange={(e) => setLeadData({ ...leadData, permission: e.target.value })}
>

<MenuItem value="option_1">Option 1</MenuItem>
<MenuItem value="option_2">Option 2</MenuItem>
<MenuItem value="option_3">Option 3 </MenuItem>
</Select>
</FormControl>
</Grid> */}




</Box>
</Box>

</div>












 



  <br />


 



      




   



   






















    <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>


   <div style={{ display: "flex",  padding: "20px" }}>
    <div style={{ display: "flex", gap: "10px" }}>

      <Button sx={{
        // backgroundColor: "#9B56FF", 
        color: "#9B56FF",
        border: " 1px solid #9B56FF",
        '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

      onClick={() => {
        handleUpdate();
      }}
      >Update</Button>


      <Button sx={{
        backgroundColor: "#9B56FF", color: "white", '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

        onClick={backToPage}> Cancel</Button>

    </div>
  </div>
 

</div>






























  









</div>
  <ToastContainer/>
</Box>
      </>
    )}
    </>
  

  );
};

export default EditAgent;
