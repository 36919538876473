import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

function Cards() {
  return (
    <Card  >
      <CardContent >
       
        <Typography variant="h5" component="div" style={{display:"grid", gridTemplateColumns:"1fr 1fr " , width:"100%" }} >
         <img src ="/img/allinvoice.png" style={{height:"40px"}}/> <h3 style={{color:"#9B56FF"}}>$15,23,661</h3>
        </Typography>
        <h3 style={{marginLeft:"7px"}}>All Invoices</h3>
      
      </CardContent>
     
    </Card>
  );
}

export default Cards;
