import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { getCookie } from "../../components/Cookies";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";

import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import Topbar from "../global/Topbar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TextField, FormControl } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";


const Reminders = ({ isLogging }) => {
  const [loading , setLoading] = useState(false)
  const [reminderType, setReminderType] = useState("once");
  const [reminder, setReminder] = useState({
    dueDate: "", frequency: ""
    , description: "", time: ""


  })
  const { id } = useParams();



  const [getReminder, setgetReminder] = useState([]);

const[editPopUp, setEditPopUp] = useState(false)

  const [open, setOpen] = useState(false);


  const handleCreateReminder = async () => {

  

    const payload = { ...reminder, frequency: reminderType }




    try {
      setLoading(true)
      const response = await Api.create_Reminder(payload, token)
      console.log(payload)
      console.log("response of api to create reminder", response);
      setReminder(response.data.data)
      if (response.data.message === "Reminder notification created successfully") {
        setLoading(false)

        setOpen(false);
       
        toast.success('Successfully Created');
        fetchData()
          navigate("/reminders")

      }
      // setReminder(response.data.data)
    
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }


  const openPopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const token = getCookie("leadmanagement");

  useEffect(() => {
    fetchData();

  }, [isDelete ]);

  // useEffect(() => {
  //   fetchData();

  // }, [getReminder ]);


  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await Api.getReminder(token);
   
      if(response.data && Array.isArray(response.data.data)){
        setLoading(false)
      console.log("response of api get reminder", response);
     setgetReminder(mapReminderData(response.data.data))
  
  
    }else{
      console.error('Data is not an array or is missing:', response.data.data);
    }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    !isLogging && navigate('/')
  },[])

  const backToPage = () => {
    navigate("/reminders")
  }

  const handleUpdate = async () => {
    try {

      const response = await Api.update_Reminder(id, { ...getReminder }, token );


      console.log("API Response:", response);
 
      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/leads"),
        });
        
      }
    } catch (error) {

      console.error("Error updating property:", error);
    }
  };




  const handleEdit = async ()=>{
    setEditPopUp(true);
  }

   const handleCloseEdit = () =>{
    setEditPopUp(!editPopUp);
   }

  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      await Api.delete_reminder(selectedUserId, token);
      setLoading(false)
      setIsDelete(!isDelete);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };

  

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "date", headerName: "Date", width: 200},
    { field: "time", headerName: "Time", width: 200 },
    { field: "type", headerName: "Type", width: 200 }

    ,  {
      field: 'delete_reminder',
      headerName: 'Delete Reminder'
      , width: 100 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)} style={{color:"black"}}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }
    
  ];


  const handleInputeChange = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    setReminder({ ...reminder, [e.target.name]: e.target.value })


  }

  const mapReminderData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      message: d.description || "No",
      date: d.dueDate || "Daily",

      time: d.time || "No",

      type: d.frequency || "No"

    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad">
        <Header
          title="Reminders"
          subTitle="Add Reminder"
          openPopup={openPopup}
        />
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            },
          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //  checkboxSelection
            rows={getReminder}
            // rows={plotData}

            columns={columns}
          />
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this reminder?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>




        <Dialog
         open={editPopUp} 
         onClose={handleCloseEdit}>
          <DialogTitle >Edit Reminder</DialogTitle>
         
          <DialogContent >
              <DialogContentText id="alert-dialog-description">
                <div style={{ display: "flex", gap: "50px" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      checked={reminderType === "once" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      value="once"

                      



                      style={{ height: "18px", width: "18px" }}
                      id="onceRadioButton"
                    
                    />

                    <label>
                      <strong>Once</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "daily" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      value="daily"
                      style={{ height: "18px", width: "18px" }}
                      id="dailyRadioButton"
                    
                    />

                    <label>
                      {" "}
                      <strong>Daily</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "weekly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="weeklyRadioButton"
                      value="weekly"
                    />

                    <label>
                      {" "}
                      <strong>Weekly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "monthly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="monthlyRadioButton"
                      value="monthly"
                    />

                    <label>
                      {" "}
                      <strong>Monthly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "yearly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="yearlyRadioButton"
                      value="yearly"
                    />

                    <label>
                      {" "}
                      <strong>Yearly</strong>
                    </label>
                  </div>
                </div>
                <br />

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto ",
                    width: "100%",
                  }}
                >
                  {reminderType === "once" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                        
                          name="dueDate"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          name="time"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>




                    </>
                  ) : reminderType === "daily" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          name="time"
                          // value={reminder.time}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                
                    </>
                  ) : reminderType === "weekly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Week</strong>
                        <Select
                          //   value={age}
                          //   onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{ height: "40px" }}
                          icon={<PersonAddAltIcon />}
                          name="dueDate"
                          onChange={handleInputeChange}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="monday">Monday</MenuItem>
                          <MenuItem value="tuesday">Tuesday</MenuItem>
                          <MenuItem value="wednesday">Wednesday</MenuItem>

                          <MenuItem value="thursday">Thursday</MenuItem>
                          <MenuItem value="friday">Friday</MenuItem>
                          <MenuItem value="saturday">Saturday</MenuItem>
                          <MenuItem value="sunday">Sunday</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          value={reminder.time}
                          name="time"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "monthly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          name="dueDate"
                          // label="Select Date"
                          // value={selectedDate}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          name="time"
                          // label="Select Date"
                          value={reminder.time}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "yearly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          name="dueDate"
                          // label="Select Date"
                          // value={selectedDate}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"

                          name="time"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : (
                    ""
                  )}


                </div>

                <br />
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <strong>Message</strong>
                  <br />


                  <TextField
                    label="Message  "

                    type="text"

                    id="second-text-field"
                    name="description"
                    multiline

                    onChange={handleInputeChange}


                    style={{ width: "100%" }}

                  />




                </FormControl>



              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "white ",
                backgroundColor: "#9B56FF",



                "&:hover": {
                  color: "#9B56FF"
                }



              }}>Submit</Button>



              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "#9B56FF ",




                "&:hover": {
                  color: "#9B56FF"
                }



              }}



                onClick={handleCloseEdit}>Cancel</Button>
            </DialogActions>
        </Dialog>





        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"


        >


          <div style={{}}>  <DialogTitle id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> <strong style={{ fontSize: "20px" }}>Add Reminder</strong>    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} /></DialogTitle>



            <DialogContent >
              <DialogContentText id="alert-dialog-description">
                <div style={{ display: "flex", gap: "50px" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      checked={reminderType === "once" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="onceRadioButton"
                      value="once"
                    />

                    <label>
                      <strong>Once</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "daily" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="dailyRadioButton"
                      value="daily"
                    />

                    <label>
                      {" "}
                      <strong>Daily</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "weekly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="weeklyRadioButton"
                      value="weekly"
                    />

                    <label>
                      {" "}
                      <strong>Weekly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "monthly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="monthlyRadioButton"
                      value="monthly"
                    />

                    <label>
                      {" "}
                      <strong>Monthly</strong>
                    </label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      name="frequency"
                      checked={reminderType === "yearly" ? true : false}
                      onChange={(e) => {
                        setReminderType(e.target.value)
                        setReminder({})
                      }}
                      style={{ height: "18px", width: "18px" }}
                      id="yearlyRadioButton"
                      value="yearly"
                    />

                    <label>
                      {" "}
                      <strong>Yearly</strong>
                    </label>
                  </div>
                </div>
                <br />

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto ",
                    width: "100%",
                  }}
                >
                  {reminderType === "once" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          // label="Select Date"
                          // value={selectedDate}
                          name="dueDate"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          name="time"
                          onChange={handleInputeChange}
                          sx={{ height: "40px" }}
                        />
                      </FormControl>




                    </>
                  ) : reminderType === "daily" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          name="time"
                          // value={reminder.time}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      {/* <FormControl sx={{ m: 1 }}>
            <strong>User</strong>
            <Select
              //   value={age}
              //   onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "40px" }}
              icon={<PersonAddAltIcon />}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={10}>Russel Copeland</MenuItem>
              <MenuItem value={20}>Brian Jhonson</MenuItem>
            </Select>
          </FormControl> */}
                    </>
                  ) : reminderType === "weekly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Week</strong>
                        <Select
                          //   value={age}
                          //   onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{ height: "40px" }}
                          icon={<PersonAddAltIcon />}
                          name="dueDate"
                          onChange={handleInputeChange}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="monday">Monday</MenuItem>
                          <MenuItem value="tuesday">Tuesday</MenuItem>
                          <MenuItem value="wednesday">Wednesday</MenuItem>

                          <MenuItem value="thursday">Thursday</MenuItem>
                          <MenuItem value="friday">Friday</MenuItem>
                          <MenuItem value="saturday">Saturday</MenuItem>
                          <MenuItem value="sunday">Sunday</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"
                          value={reminder.time}
                          name="time"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "monthly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          name="dueDate"
                          // label="Select Date"
                          // value={selectedDate}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          name="time"
                          // label="Select Date"
                          value={reminder.time}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : reminderType === "yearly" ? (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <strong>Date</strong>

                        <TextField
                          type="date"
                          name="dueDate"
                          // label="Select Date"
                          // value={selectedDate}
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1 }}>
                        <strong>Time</strong>

                        <TextField
                          type="time"
                          // label="Select Date"

                          name="time"
                          onChange={handleInputeChange}

                          sx={{ height: "40px" }}
                        />
                      </FormControl>


                    </>
                  ) : (
                    ""
                  )}


                </div>

                <br />
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <strong>Message</strong>
                  <br />


                  <TextField
                    label="Message  "

                    type="text"

                    id="second-text-field"
                    name="description"
                    multiline

                    onChange={handleInputeChange}


                    style={{ width: "100%" }}

                  />




                </FormControl>



              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "white ",
                backgroundColor: "#9B56FF",



                "&:hover": {
                  color: "#9B56FF"
                }



              }} onClick={handleCreateReminder} >Submit</Button>



              <Button sx={{
                border: "2px solid #9B56FF", height: "37.3px",
                color: "#9B56FF ",




                "&:hover": {
                  color: "#9B56FF"
                }



              }}



                onClick={handleClose}>Cancel</Button>
            </DialogActions></div>
        </Dialog>
        {loading && <Loader />}
      </Box>
    </>
  );
};

export default Reminders;
