import React, { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { useParams } from "react-router-dom";


// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditProject = ({ onClose }) => {

  const navigate = useNavigate()
  const { id } = useParams();

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [projectData, setProjectData] = useState(null)

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {

    try {
      const response = await Api.getProjectById(id);
      console.log(response.data.data)
      setProjectData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleUpdate = async () => {

    try {

      const response = await Api.updateProject(id, { ...projectData });


      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/projects"),
        });

      }
    } catch (error) {

      console.error("Error updating property:", error);
    }
  };



  const backToPage = () => {
    navigate("/projects")
  }










  return (
    <>

      <Topbar />

      {projectData && (
        <>
          <Box m="20px" className="margin_in_mobile_ipad">

            <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

              <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                <Box sx={{

                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}  >

                  <Typography

                    variant="h4"

                    fontWeight="550"
                    sx={{ m: "0 0 5px 0", }}

                    className="heading_animation"
                  >
                    Project Information

                  </Typography>

                  <br /><br />




                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Project Name"
                      value={projectData.projectName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setProjectData({ ...projectData, projectName: inputText });
                        }
                      }}
                      variant="outlined"
                    />




                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Project Owner"
                      value={projectData.projectOwner}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setProjectData({ ...projectData, projectOwner: inputText });
                        }
                      }}
                      variant="outlined"
                    />






                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Budget"
                      value={projectData.budget}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 35) {
                          setProjectData({ ...projectData, budget: inputText });
                        }
                      }}
                      variant="outlined"
                    />




                  </Box></Box>

                <Box sx={{

                  // border: 'none',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }} >
                  <Typography

                    variant="h4"

                    fontWeight="550"
                    sx={{ m: "0 0 5px 0", }}

                    className="heading_animation"
                  >
                    Address Information

                  </Typography>
                  <br /><br />




                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Street"
                      value={projectData.street}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 40) {
                          setProjectData({ ...projectData, street: inputText });
                        }
                      }}
                      variant="outlined"
                    />



                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="City"
                      value={projectData.city}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setProjectData({ ...projectData, city: inputText });
                        }
                      }}
                      variant="outlined"
                    />



                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="State"
                      value={projectData.state}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setProjectData({ ...projectData, state: inputText });
                        }
                      }}
                      variant="outlined"
                    />


                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Country"
                      value={projectData.country}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setProjectData({ ...projectData, country: inputText });
                        }
                      }}
                      variant="outlined"
                    />





                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Postal Code"
                      value={projectData.zipCode}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 10) {
                          setProjectData({ ...projectData, zipCode: inputText });
                        }
                      }}
                      variant="outlined"
                    />
                  </Box>

                </Box>

              </div>


              <br />
              <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                <Box sx={{

                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}   >
                  <Typography


                    variant="h4"

                    fontWeight="550"
                    sx={{ m: "0 0 5px 0", }}

                    className="heading_animation"
                  >
                    Company Information

                  </Typography>
                  <br /><br />

                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off">


                    <TextField

                      className="class_input"
                      id="outlined-controlled"
                      label="Industry"
                      value={projectData.industry}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setProjectData({ ...projectData, industry: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField

                      className="class_input"
                      id="outlined-controlled"
                      label="WebSite"
                      value={projectData.website}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setProjectData({ ...projectData, website: inputText });
                        }
                      }}
                      variant="outlined"
                    />



                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Number of Employees"
                      value={projectData.employNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 3) {
                          setProjectData({ ...projectData, employNumber: inputText });
                        }
                      }}
                      variant="outlined"
                    />




                    <TextField

                      className="class_input"
                      id="outlined-controlled"
                      label="Lead Source"
                      value={projectData.leadSource}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setProjectData({ ...projectData, leadSource: inputText });
                        }
                      }}
                      variant="outlined"
                    />



                  </Box>
                </Box>



                <Box sx={{


                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}    >
                  <Typography

                    variant="h4"

                    fontWeight="550"

                    sx={{ m: "0 0 5px 0", }}
                    className="heading_animation"
                  >
                    Additional Information

                  </Typography>
                  <br /><br />

                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off">

                    <Grid >
                      <div>Project Status</div>
                      <FormControl fullWidth >

                        <Select
                          sx={{ height: "40px" }}
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={projectData.projectStatus}
                          onChange={(e) => setProjectData({ ...projectData, projectStatus: e.target.value })}
                        >
                          <MenuItem value="Not Started">Not Started</MenuItem>
                          <MenuItem value="In Progress"> In Progress</MenuItem>
                          <MenuItem value="On Hold">On Hold</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid >
                      <div>Project Category</div>
                      <FormControl fullWidth>

                        <Select
                          sx={{ height: "40px" }}
                          labelId="third-select-label"
                          id="third-select"
                          fullWidth
                          defaultValue=""
                          value={projectData.projectCategory}
                          onChange={(e) => setProjectData({ ...projectData, projectCategory: e.target.value })}
                        >
                          <MenuItem value="Marketing">Marketing</MenuItem>
                          <MenuItem value="Sales"> Sales</MenuItem>
                          <MenuItem value="Product Development">Product Development</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid >
                      <div >Start Date</div>
                      <FormControl fullWidth>



                        <TextField
                          type="date"
                          className="class_input"
                          id="outlined-controlled"
                          // label="Lead Source"
                          value={projectData.startDate}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (inputText.length <= 15) {
                              setProjectData({ ...projectData, startDate: inputText });
                            }
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid >
                      <div >End Date</div>
                      <FormControl fullWidth>



                        <TextField
                          type="date"
                          className="class_input"
                          id="outlined-controlled"

                          value={projectData.endDate}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (inputText.length <= 15) {
                              setProjectData({ ...projectData, endDate: inputText });
                            }
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>


                  </Box>
                </Box>
              </div>

              <br />
              <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>


                <Box sx={{


                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }} >
                  <Typography

                    variant="h4"

                    fontWeight="550"
                    sx={{ m: "0 0 5px 0", }}

                    className="heading_animation"
                  >
                    Title

                  </Typography>

                  <br />
                  <br />


                  <TextField
                    label="Title"

                    type="text"

                    id="second-text-field"
                    value={projectData.title}
                    multiline

                    onChange={(e) => {
                      const inputText = e.target.value;
                      const words = inputText.trim().split(/\s+/);
                      if (words.length <= 100) {
                        setProjectData({ ...projectData, title: inputText });
                      }
                    }}


                    style={{ width: "100%" }}

                  />
                </Box>




                <Box sx={{


                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}>   <Typography

                  variant="h4"

                  fontWeight="550"
                  sx={{ m: "0 0 5px 0", }}
                  className="heading_animation"
                >
                    Description

                  </Typography>
                  <br />
                  <br />
                  <Box
                    component="form"

                    noValidate
                    autoComplete="off"
                  >



                    <TextField
                      label="Description"

                      type="text"

                      id="second-text-field"
                      value={projectData.description}
                      multiline

                      onChange={(e) => {
                        const inputText = e.target.value;
                        const words = inputText.trim().split(/\s+/);
                        if (words.length <= 500) {
                          setProjectData({ ...projectData, description: inputText });
                        }
                      }}


                      style={{ width: "100%" }}

                    />

                  </Box></Box>
              </div>


              <br />


              <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>


                <div style={{ display: "flex", gap: "10px" }}>

                  <Button sx={{
                    // backgroundColor: "#9B56FF", 
                    color: "#9B56FF",
                    border: " 1px solid #9B56FF",
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF"
                    }
                  }}

                    onClick={() => { handleUpdate() }}
                  >Save</Button>


                  <Button sx={{
                    backgroundColor: "#9B56FF", color: "white", '&:hover': {
                      backgroundColor: '#ffffff',
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF"
                    }
                  }}

                    onClick={backToPage}> Cancel</Button>

                </div>


              </div>

            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>


  );
};

export default EditProject;
