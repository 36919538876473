import React, { useState ,useEffect} from 'react';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { store } from "../../redux/store";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../Api";
import { Link } from 'react-router-dom';
import { setCookie } from "../../components/Cookies";
import { useDispatch } from 'react-redux';
import {Formik} from "formik"
import MuiAlert from "@mui/material/Alert";
import './register.css'; // Make sure to import your CSS file
import { Snackbar } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    input: {
     borderTop:"0px",
     borderRight:"0px",
     borderLeft:"0px",
        border: '1px  solid rgb(223, 219, 219)',
        // borderRadius: "100px",
        outline: 'none',
        color: "white",
        padding: "5px 10px 5px 10px",
        width: '100%', // Ensure input takes full width

        '& input::placeholder': {
            color: 'white'

        },
    },
}));

const Register = ({isLogging}) => {

    const dispatch = useDispatch()

    const setUserDataStore = (data) => {
        store.dispatch({
          type: "User_Data",
          payload: data,
        });
      };

  
    const classes = useStyles();

    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    //   const isNonMobile = useMediaQuery("(min-width:500px)");
    const [userData, setUserData] = useState({ firstName: "", lastName: ""  , email:"" , mobileNumber:"" , password:"" });
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");



    const handleCloseSnackbar = () => {
        setErrorSnackbarOpen(false);
        setErrorMessage("");
      };

      
//     const handleChecked = async(values)=>{

// try {
// const response = await Api.check_Mobile_Email({
//     MobileNumber: values.MobileNumber,
//     email: values.email,
// })
// console.log("jfgeagreg",response.data.message)
// if(response.data.message==="successful"){

//     setUserDataStore({
//         isLogging: true,
       
//     })

// }
    
// } catch (uploadError) {
//     setErrorMessage(
//         uploadError.response.data.message || "Something went wrong"
//       );
//     setErrorSnackbarOpen(true);
// }
//     }



    const handleRegister = async () => {
        try {
            const response = await Api.signup(userData);
            console.log( response.data.msg)
            if (response.data.msg === "Signup successfull") {
                setCookie("leadmanagement", response.data.accessToken , 7);
                setUserDataStore({
                    isLogging: true,
                    role:response.data.data.role
                    // userName: response.data.data.firstName,
                })
               
                toast.success('Signup successfull');
                Navigate("/dashboard"); 
                
            }
            
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            toast.error('Error during registration');
        }
    };

  


    return (
        <div className="container" style={{backgroundImage:"url(/img/image.jpg)" , backgroundRepeat:"no-repeat" , backgroundSize:"cover" }} >
            <div className="login">
                <div className="login__content">
                    {/* <img  className="login__img" src="./img/back.png" alt="Login image" /> */}
                    <div className="login__img" ></div>
                    <div className="login__form">
                        

                       <div    >
                        {/* <div>
              <h1 className="login__title">
                <span>Welcome</span> Back
              </h1>

              <p className="login__description">
                Welcome! Please login to continue.
              </p>
            </div> */}
                        <div className="login__title">
                        Register
                        </div>
                        {/* <div  className="">
              Access to our dashboard
              </div> */}
                        <div>
                            <div className="login__inputs" >



                            <div style={{display:"flex" , gap:"10px" }}>
                                    {/* <label htmlFor="email" className="login__label">Email</label> */}
                                    <InputBase
                                        className={classes.input}
                                        type="text"
                                        placeholder='First Name'
                                        value={userData.firstName}
                                        onChange={(e) =>
                                            setUserData({ ...userData, firstName: e.target.value })
                                        }
                                    />



<InputBase
                                        className={classes.input}
                                        type="text"
                                        placeholder='Last Name'
                                        value={userData.lastName}
                                        onChange={(e) =>
                                            setUserData({ ...userData, lastName: e.target.value })
                                        }
                                    />
                                </div>

                                <div>
                                    {/* <label htmlFor="email" className="login__label">Email</label> */}
                                    <InputBase
                                        className={classes.input}
                                        type="text"
                                        placeholder='Email'
                                        value={userData.email}
                                        onChange={(e) =>
                                            setUserData({ ...userData, email: e.target.value })
                                        }
                                    />
                                </div>

                                <div>
                                    {/* <label htmlFor="email" className="login__label">Email</label> */}
                                    <InputBase
                                        className={classes.input}
                                        type="text"
                                        placeholder='Mobile Number'
                                        value={userData.mobileNumber}
                                        onChange={(e) =>
                                            setUserData({ ...userData, mobileNumber: e.target.value })
                                        }
                                    />
                                </div>




                               

                                <div>
                                    {/* <label htmlFor="password" className="login__label">Password</label> */}
                                    <div className="login__box">
                                        <InputBase
                                            className={classes.input}
                                            type="password"
                                            placeholder='Password'
                                            value={userData.password}
                                            onChange={(e) =>
                                                setUserData({ ...userData, password: e.target.value })
                                            }

                                        />
                                        {/* <i className="ri-eye-off-line login__eye" id="input-icon"></i> */}
                                    </div>
                                </div>
                                {/* <Link className="login__forgot" to=" ">Forgot Password?</Link> */}
                            </div>

                            {/* <div className="login__check">
                <label className="login__check-label" htmlFor="check">
                  <input className="login__check-input" type="checkbox" id="check" />
                  <i className="ri-check-line login__check-icon"></i>
                  Remember me
                </label>
              </div> */}
                        </div>

                        <div>
                            <div onClick={() =>
                                { handleRegister()}} className="login__buttons">
                                <button className="login__button"    >Register</button>

                                {/* <button className="login__button login__button-ghost">Sign Up</button> */}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <div className="login__forgot"  >Already have an account? <span  ><Link className="login__forgot" to="/login ">Login</Link></span></div>
                            </div>
                        </div>
                       </div>

                       <Snackbar
                  open={errorSnackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity="error"
                  >
                    {errorMessage}
                  </MuiAlert>
                </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
