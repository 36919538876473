import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Grid,
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../components/Cookies";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Topbar from "../global/Topbar";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TextField, FormControl, InputAdornment } from "@mui/material";
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



const label = { inputProps: { 'aria-label': 'Switch demo' } };






const Followups = ({ isLogging ,role }) => {
  const [reminderType, setReminderType] = useState("once");

  const [open, setOpen] = useState(false);
  const [loading , setLoading] = useState(false)

 const [followUP , setFollowUp] = useState({
  description:"",  time:"" , dueDate:"" ,leadId:""
 })
 const [follow , setFollow] = useState([])


 const handleInputeChange = (e) => {
  // console.log(e.target.value)
  // console.log(e.target.name)
  setFollowUp({ ...followUP, [e.target.name]: e.target.value })


}






  const openPopup = () => {
    navigate("/leads");
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const navigate = useNavigate();
 
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [lead, setLead] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const token = getCookie("leadmanagement");


  const handleCreateFollowups = async () => {
   

try {
  setLoading(true)
  const response = await Api.create_Follow(followUP, token)
    
      console.log("response of api to create reminder", response);

      if (response.data.message === "followup create successfully") {
        setLoading(false)
        setOpen(false);
        toast.success('Successfully Created');
        fetchData()
        navigate("/follow-ups")
      }
} catch (error) {
  setLoading(false)
  console.log(error)
}

  }

  useEffect(()=>{
    fetchData()
    fetchData1()
  },[isDelete , role])


  const fetchData = async ()=>{

try {
  setLoading(true)
  const response = await Api.get_followups(token)
  console.log("followup data is", response.data.data);
  setFollowUp(mapFollowData( response.data.data))
  setLoading(false)

} catch (error) {
  setLoading(false)
  console.log(error)
}
  }

  const fetchData1 = async()=>{

    try { 
      setLoading(true)
      let response;
      if (role === 'admin') {
        response = await Api.get_All_Leads(token);
      } else if (role === 'manager') {
        response = await Api.get_Manager_Leads(token);
      } else if (role === 'agent') {
        response = await Api.get_Agent_Leads(token);
      }

      if (response.data && Array.isArray(response.data.data)) {
        setFollow(mapFollowData(response.data.data));
        setLoading(false)
        console.log("i want ",response.data.data);

      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }



  }






//   useEffect(() => {
//     fetchData();
//   }, [isDelete]);

//   const fetchData = async () => {
//     try {
//       const response = await Api.getNotesByUser(token);
//       if (response.data && Array.isArray(response.data.data)) {
//         setLead(mapNoteData(response.data.data));
//         // setLead(response.data.data)
//         console.log(response.data.data);
//       } else {
//         console.error(
//           "Data is not an array or is missing:",
//           response.data.data
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

  useEffect(() => {
    !isLogging && navigate("/");
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      await Api.delete_follow(selectedUserId, token);
      setLoading(false)
      setIsDelete(!isDelete);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "date", headerName: "Date", width: 200 },
    { field: "time", headerName: "Time", width: 200 },
    { field: "email", headerName: "follwup To", width: 200 },
    // {
    //   field: 'edit_followup',
    //   headerName: 'Edit Followup'
    //   , width: 150 ,
    //   renderCell: (params) => (
    //     <Link to={`/edit-followup/${params.row.id}`} style={{color:"black"}}>
    //       <EditIcon />
    //     </Link>
    //   ),

    
    // }
    ,  {
      field: 'delete_followup',
      headerName: 'Delete Followup'
      , width: 150 ,
      renderCell: (params) => (
      <button      
      onClick={() => handleDeleteClick(params.row.id)} style={{color:"black"}}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }
  ];

  // setEmailSave(params.row.email)

  const mapFollowData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      message: d.description || "No",
      date: d.dueDate || "Daily",
      time: d.time || "No",
      email:d.email || "No",
     

    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad">
        <Header
          title="Follow Ups"
          subTitle="Add Followup"
          openPopup={openPopup}
        />
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            },
          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //  checkboxSelection
            rows={followUP}
            // rows={plotData}

            columns={columns}
          />
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>





        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
             <DialogTitle id="alert-dialog-title"   
             style={{display:"flex" , justifyContent:"space-between" , alignItems:"center"}}> 
             <strong style={{fontSize:"20px"}}>Add Follow Up</strong>   
              <CloseIcon  onClick={handleClose} style={{cursor:"pointer"}} />
              </DialogTitle>
   
          
        


          <DialogContent>
            <DialogContentText id="alert-dialog-description">
          
              

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto ",
                  width: "100%",
                }}
              > 
                
                    <FormControl sx={{ m: 1 }}>
                      <strong>Date</strong>

                      <TextField
                        type="date"
                        name="dueDate"
                          onChange={handleInputeChange}
                             value={followUP.dueDate}
                        sx={{ height: "40px" }}
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                      <strong>Time</strong>

                      <TextField
                        type="time"
                        name="time"
                        onChange={handleInputeChange}
                        value={followUP.time}

                        sx={{ height: "40px" }}
                      />
                    </FormControl>


                    <Grid>
      <div>User Responsible</div>
      <FormControl fullWidth>
        <Select
          sx={{ height: "40px" }}
          labelId="third-select-label"
          id="third-select"
          fullWidth
          defaultValue=""
          value={followUP.leadId}
          onChange={handleInputeChange}
        >
          {follow.map((user) => (
            <MenuItem key={user._id} value={user._id}>
              {`${user.firstName} ${user.LastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>



                    {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}
              

              
              </div>

             <br />
              <FormControl sx={{ m: 1, width: "100%" }}>
                {/* <strong>Message</strong> */}


           

<TextField
                  label="Message  "
           
                  type="text"
           
                  id="second-text-field"
                  value={followUP.description}
                  name="description"
                  onChange={handleInputeChange}
                  multiline

               

                  style={{width:"100%"}}
             
                />




              
              </FormControl>























            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button sx={{border:"2px solid #9B56FF",height:"37.3px" ,
      color:"white ", 
      backgroundColor:"#9B56FF",
     


       "&:hover": {
color:"#9B56FF"
      }

      
       
       }} 
       onClick={handleCreateFollowups} >Submit</Button>



            <Button sx={{border:"2px solid #9B56FF",height:"37.3px" ,
      color:"#9B56FF ", 
   
     


       "&:hover": {
color:"#9B56FF"
      }

      
       
       }} 
       
       
       
       onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {loading && <Loader />}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Followups);
