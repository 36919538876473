import { Box, Button, TextField, Avatar, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Formik } from "formik";
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState , useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Form = ({isLogging}) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
 
  
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  
  useEffect(()=>{
    !isLogging && navigate('/')
  },[])


  const handleCloseSnackbar = () => {
    setErrorSnackbarOpen(false);
    setErrorMessage("");
  };

  const handleFormSubmit = async (values , { resetForm }) => {
    // if (!selectedImage) {
    //   setErrorMessage("Please select an image");
    //   setErrorSnackbarOpen(true);
    //   return;
    // }

    // const formData = new FormData();
    // formData.append('image', selectedImage);

   
      setLoading(true);
        await axios.post(`${BACKEND_URL}/admin/create-host`, {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          MobileNumber: values.MobileNumber,
          profilePhoto: values.profilePhoto,
          password: values.password,
          countryCode: "91",
        }).then((res) => {
          if (res.data.message === "user successfully create") {
            setSuccessSnackbarOpen(true);
            resetForm();
            setLoading(false);
          }
        }).catch((error) => {
          console.log(error.response.data.message);
          setErrorMessage(error.response.data.message);
          setErrorSnackbarOpen(true);
          setLoading(false);
        });
  };

  return (
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="MobileNumber Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MobileNumber}
                name="MobileNumber"
                error={!!touched.MobileNumber && !!errors.MobileNumber}
                helperText={touched.MobileNumber && errors.MobileNumber}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Confirm password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: "span 4" }}
              />
              {/* <TextField
                fullWidth
                type="file"
                label="Select Photo"
                InputProps={{ inputProps: { accept: "image/*" } }}
                onChange={(event) => handleImageChange(event, handleChange)}
                name="profilePhoto"
                sx={{ gridColumn: "span 4" }}
              />
              {selectedImage ? (
                <Avatar sx={{ width: 100, height: 100 }} src={URL.createObjectURL(selectedImage)} />
              ) : (
                <Avatar sx={{ width: 100, height: 100 }}>📷</Avatar>
              )} */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Create New User'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccessSnackbarOpen(false)}
          severity="success"
        >
          User Created Successfully
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  MobileNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  password: yup.string().required("required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "passwords must match")
    .required("required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  MobileNumber: "",
  profilePhoto: "",
  password: "",
  confirmPassword: "",
};

export default Form;
