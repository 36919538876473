import React, { useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const CreateContact = ({ onClose }) => {

  const navigate = useNavigate()


  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [leadData, setLeadData] = useState({
    firstName: "", lastName: "", email: "", phoneNumber: "", fax: "",
    city: "", state: "", street: "", country: "", zip: "",
    industry: "", owner: "", website: "", employNumber: "", leadSource: "",
    leadStatus: "", responsible: "", permission: "", organization: "",
    // title: "", description: "",
  })
  const handleSave = async () => {


    if(
      !leadData.firstName
    ){
      return toast.error("first name must be required")
    }else if(
      !leadData.lastName
    ){
      return toast.error("last name must be required")
    }else if(
      !leadData.email
    ){
      return toast.error("email must be required")
    }else if(
      !leadData.mobileNumber
    ){
      return toast.error("phone number must be required")
    }
    
    // else if(
    //   !leadData.fax
    // ){
    //   return toast.error("fax must be required")
    // } 
    
    else if(
      !leadData.street
    ){
      return toast.error("street must be required")
    }else if(
      !leadData.city
    ){
      return toast.error("city must be required")
    }else if(
      !leadData.state
    ){
      return toast.error("state must be required")
    }else if(
      !leadData.country
    ){
      return toast.error("country must be required")
    }else if(
      !leadData.zip
    ){
      return toast.error("postal code must be required")
    }else if(
      !leadData.industry
    ){
      return toast.error("industry must be required")
    }else if(
      !leadData.owner
    ){
      return toast.error("owner must be required")
    }else if(
      !leadData.website
    ){
      return toast.error("website must be required")
    }else if(
      !leadData.employNumber
    ){
      return toast.error("Number of employe must be required")
    }else if(
      !leadData.leadSource
    ){
      return toast.error("lead Source must be required")
    }else if(
      !leadData.leadStatus
    ){
      return toast.error("lead Status must be required")
    }else if(
      !leadData.responsible
    ){
      return toast.error("user responsibe must be required")
    }else if(
      !leadData.organization
    ){
      return toast.error("organization must be required")
    }else if(
      !leadData.permission
    ){
      return toast.error("permission must be required")
    
    }
    // else if(
    //   !leadData.title
    // ){
    //   return toast.error("title must be required")
    // }
    // else if(
    //   !leadData.description
    // ){
    //   return toast.error("description must be required")
    // }






    try {
      const response = await Api.create_Contact(leadData);
      console.log("Response from createLead API:", response.data.message);
      if (response.data.message === "successfully create contact") {
        toast.success('Successfully Created');
        navigate("/contact")
      }
      else {
        console.log("error")

      }


    } catch (error) {
      console.error('Error during saving lead:', error);
    }
  };


  const backToPage = () => {
    navigate("/contact")
  }










  return (
    <>

<Topbar />
<Box m="20px"
 className="margin_in_mobile_ipad"
         sx={{

          // border: 'none',



          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          // borderRadius: "10px"

          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}

      >

        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Contact Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, firstName: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, lastName: inputText });
    }
  }}
  variant="outlined"
/>



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}



<TextField
  className="class_input"
  id="outlined-controlled"
  label="Email"
  value={leadData.email}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 35) {
      setLeadData({ ...leadData, email: inputText });
    }
  }}
  variant="outlined"
/>


{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Email"
  value={leadData.email}
  onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}




<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Phone"
  value={leadData.mobileNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, mobileNumber: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Phone"
  value={leadData.phoneNumber}
  onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}


{/* <TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, fax: inputText });
    }
  }}
  variant="outlined"
/> */}



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>

<Box       sx={{

// border: 'none',
boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
borderRadius: "10px"
, padding:"20px"
, width:"100%"

// "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//   color: `${colors.grey[100]} !important`,
// },
}} >
       <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Address Information

</Typography>
<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">
<TextField
  className="class_input"
  id="outlined-controlled"
  label="Street"
  value={leadData.street}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 40) {
      setLeadData({ ...leadData, street: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Street"
  value={leadData.street}
  onChange={(e) => setLeadData({ ...leadData, street: e.target.value })}
  sx={{ paddingTop: "" }}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="City"
  value={leadData.city}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, city: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="City"
  value={leadData.city}
  onChange={(e) => setLeadData({ ...leadData, city: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="State"
  value={leadData.state}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, state: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="State"
  value={leadData.state}
  onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Country"
  value={leadData.country}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, country: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Country"
  value={leadData.country}
  onChange={(e) => setLeadData({ ...leadData, country: e.target.value })}
/> */}



<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Postal Code"
  value={leadData.zip}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, zip: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Postal Code"
  value={leadData.zip}
  onChange={(e) => setLeadData({ ...leadData, zip: e.target.value })}
/> */}

</Box>










       </Box>

</div>


      


        



      

          <br /> 
          <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

          <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}   >
        <Typography
        

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Company Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto auto ", gap: "20px"
}}
noValidate
autoComplete="off">


<TextField

  className="class_input"
  id="outlined-controlled"
  label="Industry"
  value={leadData.industry}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, industry: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Industry"
  value={leadData.industry}
  onChange={(e) => setLeadData({ ...leadData, industry: e.target.value })}
/> */}


<TextField

  className="class_input"
  id="outlined-controlled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, owner: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => setLeadData({ ...leadData, owner: e.target.value })}
/> */}

<TextField

  className="class_input"
  id="outlined-controlled"
  label="WebSite"
  value={leadData.website}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, website: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="WebSite"
  value={leadData.website}
  onChange={(e) => setLeadData({ ...leadData, website: e.target.value })}
/> */}


<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Number of Employees"
  value={leadData.employNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 3) {
      setLeadData({ ...leadData, employNumber: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Number of Employees"
  value={leadData.employNumber}
  onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
/> */}


<TextField

  className="class_input"
  id="outlined-controlled"
  label="Lead Source"
  value={leadData.leadSource}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, leadSource: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Lead Source"
  value={leadData.leadSource}
  onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
/> */}

</Box>
        </Box>

        

        <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
        <Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Additional Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off">

<Grid >
  <div>Lead Status</div>
  <FormControl fullWidth >

    <Select
    sx={{height:"40px"}}
      labelId="second-select-label"
      id="second-select"
      fullWidth
      value={leadData.leadStatus}
      onChange={(e) => setLeadData({ ...leadData, leadStatus: e.target.value })}
    >
      <MenuItem value="active">Active</MenuItem>
      <MenuItem value="close">Close</MenuItem>
      <MenuItem value="pending">Pending</MenuItem>
    </Select>
  </FormControl>
</Grid>

<Grid >
  <div>User Responsible</div>
  <FormControl fullWidth>

    <Select
         sx={{height:"40px"}}
      labelId="third-select-label"
      id="third-select"
      fullWidth
      defaultValue=""
      value={leadData.responsible}
      onChange={(e) => setLeadData({ ...leadData, responsible: e.target.value })}
    >
      <MenuItem value="user_1">User 1</MenuItem>
      <MenuItem value="user_2">User 2</MenuItem>
      <MenuItem value="user_3">User 3</MenuItem>
    </Select>
  </FormControl>
</Grid>

<Grid >
  <div >Organization</div>
  <FormControl fullWidth>

    <Select
         sx={{height:"40px"}}
      labelId="first-select-label"
      id="first-select"
      fullWidth
      defaultValue=""
      value={leadData.organization}
      onChange={(e) => setLeadData({ ...leadData, organization: e.target.value })}
    >

      <MenuItem value="org_1">Org. 1</MenuItem>
      <MenuItem value="org_2">Org. 2</MenuItem>
      <MenuItem value="org_3">Org. 3 </MenuItem>
    </Select>
  </FormControl>
</Grid>

<Grid >
  <div >Permission</div>
  <FormControl fullWidth>

    <Select
         sx={{height:"40px"}}
      labelId="first-select-label"
      id="first-select"
      fullWidth
      defaultValue=""
      value={leadData.permission}
      onChange={(e) => setLeadData({ ...leadData, permission: e.target.value })}
    >

      <MenuItem value="option_1">Option 1</MenuItem>
      <MenuItem value="option_2">Option 2</MenuItem>
      <MenuItem value="option_3">Option 3 </MenuItem>
    </Select>
  </FormControl>
</Grid>




</Box>
        </Box>


            </div>
         

      

          {/* <br /> */}


         

      

              
          {/* <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

                
           <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}} >
           <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Title

</Typography>

<br />
<br/>


<TextField
                        label="Title"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.title}
                        multiline

                        onChange={(e) => {
                          const inputText = e.target.value;
                          const words = inputText.trim().split(/\s+/);
                          if (words.length <= 100) { 
                            setLeadData({ ...leadData, title: inputText });
                          }
                        }}
                     

                        style={{width:"100%"}}
                   
                      />
           </Box>


             
                
             <Box    sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}>   <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Description

</Typography>
<br />
<br/>
<Box
component="form" 

noValidate
autoComplete="off"
>



<TextField
                        label="Description"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.description}
                        multiline

                        onChange={(e) => {
                          const inputText = e.target.value;
                          const words = inputText.trim().split(/\s+/);
                          if (words.length <= 500) { 
                            setLeadData({ ...leadData, description: inputText });
                          }
                        }}
                     

                        style={{width:"100%"}}
                   
                      />

</Box></Box>
            </div> */}



           
       


           
     


       



        
      


      

    









   




       
<br/>
      

       


<div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
         
        
            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
         

        </div>


    







   






          
       
     
  






        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};

export default CreateContact;
