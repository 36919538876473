import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
// import LandslideIcon from '@mui/icons-material/Landslide';
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
// import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Topbar from "../global/Topbar";


import { useEffect, useState } from "react";
// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;;

const mData = [4000, 3000, 2000, 2780];
const nData = [4500, 4000,2300, 3908];

const mLabels = [
  "o",
  '100',
  '150',
  '200',
 
];

const rData = [100, 75, 50, 100];
const sData = [90, 65, 40, 90];
const zLabels = [
  "2006",
  "2010",
  "2014",
  "2018"
];



const aData = [2478, 5267, 734, 784, 433, 2390, 2090];

const yLabels = [
  "2006",
  "2010",
  "2011",
  "2012",
  "2018",
  "2020",
  "2022",
];

const uData = [, 60, 50, 60, 50, 60, 50];
const pData = [, 50, 40, 50, 40, 50, 40, 60];


const xLabels = [
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
];

const chartSetting = {
  xAxis: [
  ],
  width: 400,
  height: 300,
};
const dataset = [

  {
    london: 2300,
    paris: 2408,
    newYork: 2250,
    seoul: 2478,
    year: 2000,
  },
  {
    london: 5000,
    paris: 5100,
    newYork: 5200,
    seoul: 5267,
    year: 2010,
  },
  {
    london: 7084,
    paris: 7300,
    newYork: 7342,
    seoul: 734,
    year: 2011
  },
  {
    london: 4784,
    paris: 2784,
    newYork: 6784,
    seoul: 784,
    year: 2015
  },
  {
    london: 6433,
    paris: 4433,
    newYork: 9433,
    seoul: 433,
    year: 2020
  },





];

const valueFormatter = (value) => `${value}`;


const LeadsDashboard = () => {
  const navigate = useNavigate()
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null)






  return (


  <>
       <Topbar />


       <Box m="20px" className="margin_in_mobile_ipad" >


<Header title=" Leads Dashboard" subtitle="Welcome to your dashboard" />






<Box



>

  <div style={{ display:"grid" ,gridTemplateColumns:"auto auto",  gap:"20px" }} className='invoicesalign'  >




<div  style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
    <h2 style={{marginLeft:"20px", display:"flex" }}>  Sales Statistics</h2>

    
      <BarChart
width={400}
height={300}
series={[
  { data: mData, label: 'Total Cost', id: 'pvId'  },
  { data: nData, label: 'Total Revenue', id: 'uvId' },
]}
xAxis={[{ data: mLabels, scaleType: 'band' }]}
/> 


    </div>




    <div style={{ boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)" }}       >
      <h2 style={{marginLeft:"20px", display:"flex" }}>Completed Tasks</h2>   

        <LineChart
          xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}

          series={[
            {
              data: [0, 30, 60, 90, 120, 150],
            },
          ]}
          width={400}
          height={300}
        />


      </div>


   








    




  </div>

</Box>
<br/>


<Box



>

  <div style={{ display:"grid" ,gridTemplateColumns:"auto auto",  gap:"20px" }} className='invoicesalign' >






<div style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
<h2 style={{marginLeft:"20px"}}>Total Leads </h2>
    <div >
    <PieChart 

className="piewidth"
series={[
{
data: [
  { id: 0, value: 2478, label: 'Asia', color: "#9B56FF" },
  { id: 1, value: 5267, label: 'Europe', color: "#FE7096" },
],
},
]}
width={400}
height={200}
/>
    </div>


    </div>




    <div style={{ boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)" }}       >
      <h2 style={{marginLeft:"20px", display:"flex" }}>
Number of Employees</h2>
        <BarChart
          dataset={dataset}
          yAxis={[{ scaleType: 'band', dataKey: 'year' }]}
          series={[
            { 
              dataKey: 'seoul', 
              valueFormatter,
            
            }
          ]}
          layout="horizontal"

          {...chartSetting}
        />
        












      </div>





  


  </div>

</Box>
<br/>

<Box


>

  <div style={{ display:"grid" ,gridTemplateColumns:"auto auto",  gap:"20px" }} className='invoicesalign' >



<div  style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
    <h2 style={{marginLeft:"20px", display:"flex" }}>Sales Overview</h2>

      <LineChart
        width={400}
        height={300}
        series={[
          { data: pData },
          { data: uData },
        ]}
        xAxis={[{ data: xLabels }]}
      />



    </div>





      <div style={{ boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)" }}       >

      <h2 style={{marginLeft:"20px", display:"flex" }}>Total Revenue</h2>    
  
        <BarChart
width={400}
height={300}
series={[
  { data: rData,  id: 'pvId' },
  { data: sData,  id: 'uvId' },
]}
xAxis={[{ data: zLabels, scaleType: 'band' }]}
/>

      </div>



  </div>

</Box>
<br/>

<Box



>

  <div style={{ display:"grid" ,gridTemplateColumns:"auto auto",  gap:"20px" }} className='invoicesalign' >



<div  style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
    <h2 style={{marginLeft:"20px", display:"flex" }}>Yearly Project</h2>

      <BarChart
        width={400}
        height={300}
        series={[
          { data: aData, id: 'pvId' },

        ]}
        Axis={[{ data: yLabels, scaleType: 'band' }]}
      />

    </div>

   
    <div style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)" }}       >
      <h2 style={{marginLeft:"20px", display:"flex" }}>Total Sales</h2>

        <LineChart
          xAxis={[{ data: [ 0, 2, 3, 5, 8, 10] }]}

          series={[
            {
              data: [0, 30, 60, 90, 120, 150],
            },
          ]}
          width={400}
          height={300}
        />


      </div>

   

  

  </div>

</Box>


</Box>
  </>
  );
};

export default LeadsDashboard;
