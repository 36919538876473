import React, { useState , useEffect } from "react";
import Loader from "../Loader";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate  } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { store } from "../../redux/store";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditTask = ({ role, userName }) => {

    const { id } = useParams();
  const navigate = useNavigate()


  

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
const token = getCookie("leadmanagement")

  const [taskData, setTaskData] = useState()
const [loading , setLoading] = useState(false)
  useEffect(() => {
    fetchData();
  }, []);


  const fetchData= async ()=>{
    setLoading(true)
    try {
      const response= await Api.get_Task_By_Id( id ,token );
        console.log(response.data.data)
        setTaskData(response.data.data)
        setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  }


  const handleSave = async () => {


    if(
      !taskData.subject
    ){
      return toast.error("subject must be required")
    }
    
    else if(
       !taskData.priority
    ){
      return toast.error("priority must be required")
    } 
    
    else if(
      !taskData.status
    ){
      return toast.error("status must be required")
    }
    
    else if(
       !taskData.dueDate
    ){
      return toast.error("due date must be required")
    }
    
    else if(
       !taskData.description
    ){
      return toast.error("description must be required")
    }





    try {
      setLoading(true)
      const response = await Api.update_Task( id, { ...taskData }, token  );
      console.log(response)


      if (response.data.message == "task successfully assigned to manager or agent"){
        toast.success("Task updated successfully")
        navigate("/tasks")
        setLoading(false)
      }
    //   console.log("Response from createLead API:", response.data.message);
    //   if (response.data.message === "successfully create task") {
    //     toast.success('Successfully Created');
    //     navigate("/tasks")
    //   }
    //   else {
    //     console.log("error")

    //   }

    } catch (error) {
      setLoading(false)
      console.error('Error during saving lead:', error);
    }
  };


  const [users, setUsers] = useState([]);

  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true)
        let response;
        if (role === 'admin') {
          response = await Api.getAllUsers(token);
        } else if (role === 'manager') {
          response = await Api.getAllManagerAgent(token);
        } else if (role === 'agent') {
          response = await Api.getAllAgent(token);
        }
        
        setUsers(response.data.data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [role])

  const backToPage = () => {
    navigate("/tasks")
  }














  return (
    <>
     <Topbar />

     {taskData && (
        <>
         <Box m="20px" className="margin_in_mobile_ipad"

sx={{

  // border: 'none',



  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
  // borderRadius: "10px"

  // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
  //   color: `${colors.grey[100]} !important`,
  // },
}}

>





<div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Task Information

</Typography>

<br /><br />
<TextField
className="class_input"
id="outlined-controlled"
label="Subject"
fullWidth
multiline
value={taskData.subject}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 500) {
setTaskData({ ...taskData, subject: inputText });
}
}}
variant="outlined"
/>
<br /><br />


<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
id="outlined-controlled"
label="First Name"
value={leadData.firstName}
onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<Grid >
<div >Priority</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="first-select-label"
id="first-select"
fullWidth
defaultValue=""
value={taskData.priority}
onChange={(e) => setTaskData({ ...taskData, priority: e.target.value })}
>

<MenuItem value="low">Low</MenuItem>
<MenuItem value="medium">Medium</MenuItem>
<MenuItem value="high">High </MenuItem>
<MenuItem value="urgent">Urgent </MenuItem>

</Select>
</FormControl>
</Grid>

{/* <TextField
className="class_input"
id="outlined-controlled"
label="priority"
value={taskData.priority}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setTaskData({ ...taskData, priority: inputText });
}
}}
variant="outlined"
/> */}



{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Last Name"
value={leadData.lastName}
onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}
<Grid >
<div>User Responsible</div>
<FormControl fullWidth>

<Select
 sx={{height:"40px"}}
labelId="third-select-label"
id="third-select"
fullWidth
defaultValue=""
value={taskData.assignTo}
onChange={(e) => setTaskData({ ...taskData, assignTo: e.target.value })}
>
{/* Dynamically render MenuItem for each user */}
{users.map((user) => (
        <MenuItem key={user._id} value= {user._id} >
         {`${user.firstName} ${user.lastName}`} 
        </MenuItem>
      ))}
</Select>
</FormControl>
</Grid>



<Grid >
<div> Status</div>
<FormControl fullWidth >

<Select
sx={{height:"40px"}}
labelId="second-select-label"
id="second-select"
fullWidth
value={taskData.status}
onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
>
      <MenuItem value="new">New</MenuItem>
      <MenuItem value="processing">In discussion</MenuItem>
      <MenuItem value="confirm">Hired</MenuItem>
      <MenuItem value="cancel">Cold/Dead</MenuItem>
</Select>
</FormControl>
</Grid>

{/* <TextField
className="class_input"
id="outlined-controlled"
label="Status"
value={taskData.status}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 35) {
setTaskData({ ...taskData, status: inputText });
}
}}
variant="outlined"
/> */}


{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Email"
value={leadData.email}
onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */} 






{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Phone"
value={leadData.phoneNumber}
onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}



{/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Fax"
value={leadData.fax}
onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box>
<br/><br/>
<TextField
// type="TYPE"
className="class_input"
id="outlined-controlled"
label="Description"
value={taskData.description}
fullWidth
multiline
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 1000) {
setTaskData({ ...taskData, description: inputText });
}
}}
variant="outlined"
/>



</Box>

<Box       sx={{

// border: 'none',
boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
borderRadius: "10px"
, padding:"20px"
, width:"100%"

// "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//   color: `${colors.grey[100]} !important`,
// },
}} >
<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>

Additional Information

</Typography>
<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">

{/* <TextField
className="class_input"
id="outlined-controlled"
label="responsible"
value={taskData.assignTo}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setTaskData({ ...taskData, assignTo: inputText });
}
}}
variant="outlined"


/> */}

{/* <TextField
className="class_input"
id="outlined-controlled"
label="Label"
value={taskData.label}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 15) {
setTaskData({ ...taskData, label: inputText });
}
}}
variant="outlined"
/> */}





{/* <Grid >
<div>Start Date</div>
<FormControl fullWidth >

<TextField

type="date"
className="class_input"
id="outlined-controlled"
label=""
value={taskData.createdAt}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setTaskData({ ...taskData, createdAt: inputText });
}
}}
variant="outlined"
/>
</FormControl>
</Grid> */}





<Grid >
<div>End Date</div>
<FormControl fullWidth >

<TextField
type="date"
className="class_input"
id="outlined-controlled"
label=""
value={taskData.dueDate}
onChange={(e) => {
const inputText = e.target.value;
if (inputText.length <= 10) {
setTaskData({ ...taskData, dueDate: inputText });
}
}}
variant="outlined"
/>
</FormControl>
</Grid>






</Box>


</Box>

</div>

<br/>



    <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>


    <div style={{ display: "flex", gap: "10px" }}>

      <Button sx={{
        // backgroundColor: "#9B56FF", 
        color: "#9B56FF",
        border: " 1px solid #9B56FF",
        '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

        onClick={() => { handleSave() }}
      >Update</Button>


      <Button sx={{
        backgroundColor: "#9B56FF", color: "white", '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: " 1px solid #9B56FF"
        }
      }}

        onClick={backToPage}> Cancel</Button>

    </div>
    {loading && <Loader />}

</div>

</div>
  <ToastContainer/>
</Box>
        </>
     )}
     
    </>
  

  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default  connect (mapStateToProps)(EditTask);
