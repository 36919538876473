import { NavLink, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { MenuItem } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import ButtonGroup from "@mui/material/ButtonGroup";

import { Snackbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import {
  drop,
  topoptions,
  amenities,
  safety,
  highlights,
} from "../../components/data";
// import Map from "../pages/Map";
import { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";
import { Button, Grid, TextField, Select } from "@mui/material";
import axios from "axios";
const apiKey = "AIzaSyCtz99HysBUR1PssP3K8UvLbluIpF9kpoc";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const loadScript = async (url, callback) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url + `&callback=${callback}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = (error) => {
      reject(error);
    };

    document.head.appendChild(script);
  });
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  priceInput: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: "20px",
    marginTop: "20px",
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },
}));

const array = [
  {
    title: "One Side",
  },
  {
    title: "Two Sides",
  },
  {
    title: "Three Sides",
  },
  {
    title: "Four Sides",
  },
  {
    title: "Five Sides",
  },
];

const array1 = [
  {
    title: "North",
  },
  {
    title: "South",
  },
  {
    title: "East",
  },
  {
    title: "West",
  },
  {
    title: "North-East",
  },
  {
    title: "North-West",
  },

  {
    title: "South-East",
  },
  {
    title: "South-West",
  },
];
const EditProperty = ({isLogging}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  
  useEffect(()=>{
    !isLogging && navigate('/')
  },[])
  const handleUpdate = async () => {
    try {
      setLoading(true);

      const response = await axios.put(
        `${BACKEND_URL}/admin/update-listing/${id}`,
        { ...property }
      );

      setLoading(false);
      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully edited the properties", {
          autoClose: 800,
          //   onClose: () => navigate("/host-listing"),
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating property:", error);
    }
  };

  console.log(id);
  const [property, setPropertie] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/admin/listing/${id}`);
      console.log(response.data.data);
      setPropertie(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const fileTypes = ["JPG", "PNG", "GIF"];
  const handleAddMore = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef = useRef(null);

  const handleDeleteImage = (indexToDelete) => {
    const updatedImages = property.images.filter(
      (_, index) => index !== indexToDelete
    );
    setPropertie({ ...property, images: updatedImages });
  };

  const [price, setPrice] = useState();
  const [images, setImages] = useState([]);

  const [furnishedStatus, setFurnishedStatus] = useState(" ");

  const handleSelectChange = (event) => {
    setFurnishedStatus(event.target.value);
  };

  const handleItemClick = (value) => {
    setPropertie((prevEditData) => ({
      ...prevEditData,
      numberOfOpenSides: value,
    }));
  };

  const [selectedItem1, setSelectedItem1] = useState(null);

  const handleItemClick1 = (value) => {
    setPropertie((prevEditData) => ({
      ...prevEditData,
      selectedFacing: value,
    }));
  };

  const handleItemClick2 = (value) => {
    setPropertie((prevEditData) => ({
      ...prevEditData,
      hasBoundaryWall: value,
    }));
  };

  const handleItemClick3 = (value) => {
    setPropertie((prevEditData) => ({
      ...prevEditData,
      construction: value,
    }));
  };

  const handleItemClick4 = (value) => {
    setPropertie((prevEditData) => ({
      ...prevEditData,
      furnishedStatus: value,
    }));
  };

  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const handleAmenityClick = (amenity) => {
    setPropertie((prevEditData) => {
      const prevAmenities = prevEditData.amenities || []; // Make sure amenities is initialized

      if (prevAmenities.includes(amenity)) {
        // If amenity is already selected, remove it
        return {
          ...prevEditData,
          amenities: prevAmenities.filter((item) => item !== amenity),
        };
      } else {
        // If amenity is not selected, add it
        return {
          ...prevEditData,
          amenities: [...prevAmenities, amenity],
        };
      }
    });
  };

  const handleChange = (name, e) => {
    if (name === "Beds") {
      setPropertie({ ...property, beds: e.target.value });
    }

    if (name === "Bathrooms") {
      setPropertie({ ...property, bathrooms: e.target.value });
    }

    if (name === "Bedrooms") {
      setPropertie({ ...property, bedrooms: e.target.value });
    }
    console.log({ name });
    console.log(e.target.value);

    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  console.log(property);

  const [formData, setFormData] = useState({
    inputBeds: property && property.beds && property.beds,
    inputBedrooms: property && property.bedrooms,
    inputBathrooms: property && property.bathrooms,
  });
  const [listingData, setListingData] = useState({
    room_type: "sell",
    Sub_property_selectedItem: "",
    name: "",
    description: "",
    living_Part: "residential",
  });

  useEffect(() => {
    setListingData({
      ...listingData,
      Sub_property_selectedItem: "",
      living_Part: "residential",
    });
  }, [listingData.room_type]);
  useEffect(() => {
    setListingData({ ...listingData, Sub_property_selectedItem: "" });
  }, [listingData.living_Part]);

  const classes = useStyles();

  const [address, setAddress] = useState({
    Country: "",
    locality: "",
    city: "",
    pincode: "",
    street: "",
    lat: null,
    lng: null,
  });

  useEffect(() => {
    const initMap = async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
          "initializeAutocomplete"
        );
      } catch (error) {
        console.error("Error loading Google Maps API:", error);
      }
    };

    initMap();
  }, []);
  window.initializeAutocomplete = () => {
    const cityInput = document.getElementById("city");
    const localityInput = document.getElementById("locality");
    const pincodeInput = document.getElementById("pincode");

    const cityAutocomplete = new window.google.maps.places.Autocomplete(
      cityInput,
      {
        types: ["(cities)"], // Restrict to cities
        componentRestrictions: { country: "IN" }, // Restrict to India
      }
    );
    const localityAutocomplete = new window.google.maps.places.Autocomplete(
      localityInput,
      {
        types: ["address"], // Restrict to addresses
        componentRestrictions: { country: "IN" }, // Restrict to India
      }
    );
    const pincodeAutocomplete = new window.google.maps.places.Autocomplete(
      pincodeInput,
      {
        types: ["postal_code"], // Restrict to pincodes
        componentRestrictions: { country: "IN" }, // Restrict to India
      }
    );

    cityAutocomplete.addListener("place_changed", () => {
      const selectedCity = cityAutocomplete.getPlace();
      if (selectedCity.geometry) {
        const cityBounds = selectedCity.geometry.viewport;
        pincodeAutocomplete.setBounds(cityBounds);
        localityAutocomplete.setBounds(cityBounds);
        console.log(selectedCity);
        setPropertie((prevAddress) => ({
          ...prevAddress,

          address: {
            ...prevAddress.address,
            city: selectedCity.formatted_address,
            locality: "",
            pincode: "",
            lat: selectedCity.geometry.location.lat(),
            lng: selectedCity.geometry.location.lng(),
          },

          // city: selectedCity.formatted_address,
          // locality: "",
          // pincode: "",
          // lat: selectedCity.geometry.location.lat(),
          // lng: selectedCity.geometry.location.lng(),
        }));
      }
    });

    localityAutocomplete.addListener("place_changed", () => {
      const selectedLocality = localityAutocomplete.getPlace();
      console.log(selectedLocality);
      if (selectedLocality.geometry) {
        console.log(selectedLocality);
        setAddress((prevAddress) => ({
          ...prevAddress,
          address: {
            ...prevAddress.address,
            locality: selectedLocality.formatted_address,
            pincode: "",
            lat: selectedLocality.geometry.location.lat(),
            lng: selectedLocality.geometry.location.lng(),
          },
        }));
      }
    });

    pincodeAutocomplete.addListener("place_changed", () => {
      const selectedPincode = pincodeAutocomplete.getPlace();
      if (selectedPincode.geometry) {
        const pincodeValue = selectedPincode.formatted_address.replace(
          /[^0-9]/g,
          ""
        );
        setAddress((prevAddress) => ({
          ...prevAddress,
          pincode: pincodeValue,
        }));
      }
    });
  };
  const toggleOption = (name) => {
    setPropertie((prevEditData) => {
      const prevHighlights = prevEditData.highlights || []; // Make sure highlights is initialized

      if (prevHighlights.includes(name)) {
        // If name is already selected, remove it
        return {
          ...prevEditData,
          highlights: prevHighlights.filter((option) => option !== name),
        };
      } else if (prevHighlights.length < 2) {
        // If less than 2 options are selected, add the new selection
        return {
          ...prevEditData,
          highlights: [...prevHighlights, name],
        };
      } else {
        // Replace the second selection with the new selection
        const updatedHighlights = [...prevHighlights];
        updatedHighlights.splice(1, 1, name); // Replace the second item
        return {
          ...prevEditData,
          highlights: updatedHighlights,
        };
      }
    });
  };

  const handleChangeImage = async (newFiles) => {
    console.log(newFiles);
    if (!Array.isArray(newFiles) || newFiles.length === 0) {
      alert("Please select images to upload.");
      return;
    }
    try {
      const formData = new FormData();
      newFiles.forEach((image) => {
        formData.append("image", image);
      });
      setLoading(true);
      const response = await axios.post(`${BACKEND_URL}/upload_two`, formData);

      console.log(response);
      if (response.status === 200) {
        const data = response.data;
        setLoading(false);
        console.log(response);
        setPropertie({
          ...property,
          images: [...property.images, ...data.imageUrls],
        });
      }
    } catch (uploadError) {
      setLoading(false);
      console.error("Error uploading images:", uploadError);
      alert("Something went wrong");
    }
  };

  return (
    <>
      {property && (
        <div style={{padding:"50px"}}>
          <div>
            <div>
              <div>
                <div>
                  <h1>Edit Your Listing</h1>

                

                  <div className={classes.container}>
                    <div className="">
                      <div>
                        <div className={classes.heading}>
                          Confirm your address
                        </div>
                        <div style={{ marginBottom: "15px" }}>
                          Your address is only shared with guests after they’ve
                          made a reservation.
                        </div>

                        <div style={{ width: "100%" }} className="">
                          <input
                            style={{
                              border: "1px solid #999999",
                              height: "50px",
                              borderRadius: "10px 10px 0px 0px",
                              width:"100%"
                            }}
                            id="city"
                            className="form-control"
                            type="text"
                            placeholder="City"
                            // value={address.city}

                            value={property.address.city || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                address: {
                                  ...property.address,
                                  city: e.target.value,
                                },
                              });
                            }}
                            
                          />
                        </div>

                        <div className="">
                          <input
                            style={{
                              border: "1px solid #999999",
                              height: "50px",
                              borderRadius: "0px",
                              width:"100%"
                            }}
                            id="locality"
                            className="form-control"
                            type="text"
                            placeholder="Locality"
                            // value={address.locality}
                            value={property.address.locality || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                address: {
                                  ...property.address,
                                  locality: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>

                        <div className="">
                          <input
                            style={{
                              border: "1px solid #999999",
                              height: "50px",
                              borderRadius: "0px",
                              width:"100%"
                            }}
                            type="text"
                            required=""
                            id="streetAddress"
                            className="form-control"
                            data-label="STREET_ADDRESS"
                            placeholder="Nearby Address/Optional"
                            value={property.address.street || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                address: {
                                  ...property.address,
                                  street: e.target.value,
                                },
                              });
                            }}
                           
                          />
                          {/* <label className="form-label pt-2" for="form6Example3">
                        Nearby Address
                      </label> */}
                        </div>

                        <div className="">
                          <input
                            style={{
                              border: "1px solid #999999",
                              height: "50px",
                              borderRadius: "0px 0px 10px 10px",
                              width:"100%"
                            }}
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="6"
                            minLength="6"
                            id="pincode"
                            className="form-control"
                            placeholder="PinCode/Optional"
                            // value={address.pincode}
                            value={property.address.pincode || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                address: {
                                  ...property.address,
                                  pincode: e.target.value,
                                },
                              });
                            }}
                            // onChange={(e) => {
                            //   const input = e.target.value.replace(/\D/g, "");
                            //   const pincodeValue = input.replace(/[^0-9]/g, "");
                            //   setAddress((prevAddress) => ({
                            //     ...prevAddress,
                            //     pincode: pincodeValue,
                            //   }));
                            // }}
                          />
                          {/* <label className="form-label pt-2" for="form6Example3">
                        PIN code
                      </label> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.container}>
                    <div className="container">
                      <div style={{ marginTop: "20px" }}>
                        Show your specific location
                      </div>
                    </div>
                  </div>

                  {/* <div>
                    <Map
                      location={{
                        lat: property.address.lat,
                        lng: property.address.lng,
                      }}
                    />
                  </div> */}

                  <div className={classes.container}>
                    <div className="container">
                      <div>
                        <div className={classes.heading}>
                          Now , let's give your property a title
                        </div>
                        <TextField
                          label="Title"
                          variant="outlined"
                          type="text"
                          className={classes.priceInput}
                          // value={listingData.name}
                          value={property.name}
                          multiline
                          onChange={(e) => {
                            setPropertie({
                              ...property,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.container}>
                    <div className="container">
                      <div>
                        <div className={classes.heading}>
                          Create your description
                        </div>
                        <TextField
                          label="Description"
                          variant="outlined"
                          type="text"
                          className={classes.priceInput}
                          // value={listingData.description}
                          value={property.description}
                          multiline
                          onChange={(e) => {
                            setPropertie({
                              ...property,
                              description: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {property.Sub_property_selectedItem === "flatapartment " && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Share some basics about your place
                          </div>
                          <div>
                            You'll add more details later, such as bed types.
                          </div>

                          <Grid container spacing={2}>
                            {drop.map((item) => (
                              <Grid item xs={12} sm={6} md={3} key={item.name}>
                                <TextField
                                  select
                                  label={item.name}
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    item.name === "Beds"
                                      ? property.beds
                                      : item.name === "Bedrooms"
                                      ? property.bedrooms
                                      : item.name === "Bathrooms"
                                      ? property.bathrooms
                                      : " "
                                  } // Use item.name instead of item
                                  onChange={(e) => {
                                    handleChange(item.name, e);
                                  }} // Use item.name instead of item
                                  style={{ marginTop: 8 }}
                                >
                                  <MenuItem value="1">{item.one}</MenuItem>
                                  <MenuItem value="2">{item.two}</MenuItem>
                                  <MenuItem value="3">{item.three}</MenuItem>
                                  <MenuItem value="4">{item.four}</MenuItem>
                                </TextField>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <hr style={{ width: "80%" }} /> */}

                  {property.living_Part === "residential" &&
                    property.Sub_property_selectedItem !== "plotland" && (
                      <div className={classes.container}>
                        <div className="container">
                          <div>
                            <div className={classes.heading}>
                              Tell guests what your place has to offer
                            </div>
                            <div>
                              Share some basic info, such as where it is and how
                              many guest can stay.
                            </div>
                            <br />
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {topoptions.map((data, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item
                                    className="Capitalise"
                                    // className={`${
                                    //   property.amenities.includes(data.title) ? "selectedAmenities" : ""
                                    // } Capitalise `}
                                    onClick={() => {
                                      handleAmenityClick(data.title);
                                    }}
                                    sx={{
                                      padding: 2,
                                      backgroundColor:
                                        property.amenities.includes(data.title)
                                          ? "#fda94f"
                                          : "inherit",
                                      color: property.amenities.includes(
                                        data.title
                                      )
                                        ? "white"
                                        : "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.title}
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                            <br />

                            <div className={classes.subhead}>
                              Do you have any standout amenities?
                            </div>
                            <br />

                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {amenities.map((data, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item
                                    className="Capitalise"
                                    onClick={() => {
                                      handleAmenityClick(data.title);
                                    }}
                                    sx={{
                                      padding: 2,
                                      backgroundColor:
                                        property.amenities.includes(data.title)
                                          ? "#fda94f"
                                          : "inherit",
                                      color: property.amenities.includes(
                                        data.title
                                      )
                                        ? "white"
                                        : "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.title}
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                            <br />

                            <div className={classes.subhead}>
                              Do you have any of these safety items?
                            </div>
                            <br />

                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {safety.map((data, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item
                                    className="Capitalise"
                                    onClick={() => {
                                      handleAmenityClick(data.title);
                                    }}
                                    sx={{
                                      padding: 2,
                                      backgroundColor:
                                        property.amenities.includes(data.title)
                                          ? "#fda94f"
                                          : "inherit",
                                      color: property.amenities.includes(
                                        data.title
                                      )
                                        ? "white"
                                        : "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.title}
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                            <br />
                          </div>
                        </div>
                      </div>
                    )}

                  {/* <hr style={{ width: "80%" }} /> */}

                  {/* plot/land =============================================*/}

                  {(property.living_Part === "commercial" ||
                    property.Sub_property_selectedItem === "plotland") && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Property Dimensions:
                          </div>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Length"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={
                                  property.propertyDimensions
                                    ? property.propertyDimensions.length || ""
                                    : ""
                                }
                                onChange={(e) => {
                                  setPropertie({
                                    ...property,
                                    propertyDimensions: {
                                      ...property.propertyDimensions,
                                      length: e.target.value,
                                    },
                                  });
                                }}
                                InputProps={{
                                  style: {
                                    paddingLeft: "13px",
                                    paddingRight: "10px",
                                  }, // Adjust padding for spacing
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Width"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={
                                  property.propertyDimensions
                                    ? property.propertyDimensions.width || ""
                                    : ""
                                }
                                onChange={(e) => {
                                  setPropertie({
                                    ...property,
                                    propertyDimensions: {
                                      ...property.propertyDimensions,
                                      width: e.target.value,
                                    },
                                  });
                                }}
                                InputProps={{
                                  style: {
                                    paddingLeft: "13px",
                                    paddingRight: "10px",
                                  }, // Adjust padding for spacing
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.Sub_property_selectedItem === "plotland" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Floors Allowed for Construction
                          </div>

                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Number of Floors"
                              type="number"
                              variant="outlined"
                              fullWidth
                              value={property.numberOfFloors || ""}
                              onChange={(e) => {
                                setPropertie({
                                  ...property,
                                  numberOfFloors: e.target.value,
                                });
                              }}
                              InputProps={{
                                style: {
                                  paddingLeft: "13.5px",
                                  paddingRight: "10px",
                                }, // Adjust padding for spacing
                              }}
                            />
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.living_Part === "commercial" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>Floor Details:</div>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Current Floor"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={property.currentFloor || ""}
                                InputProps={{
                                  style: {
                                    paddingLeft: "13px",
                                    paddingRight: "10px",
                                  }, // Adjust padding for spacing
                                }}
                                onChange={(e) =>
                                  setPropertie({
                                    ...property,
                                    currentFloor: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Total Floors"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={property.totalFloors || ""}
                                onChange={(e) =>
                                  setPropertie({
                                    ...property,
                                    totalFloors: e.target.value,
                                  })
                                }
                                InputProps={{
                                  style: {
                                    paddingLeft: "13px",
                                    paddingRight: "10px",
                                  }, // Adjust padding for spacing
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.Sub_property_selectedItem === "plotland" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Number of Open Sides:
                          </div>

                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {array.map((data, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item
                                    onClick={() => handleItemClick(data.title)}
                                    sx={{
                                      padding: 2,
                                      backgroundColor:
                                        property.numberOfOpenSides ===
                                        data.title
                                          ? "#fda94f"
                                          : "inherit",
                                      color:
                                        property.numberOfOpenSides ===
                                        data.title
                                          ? "white"
                                          : "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.title}
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.Sub_property_selectedItem === "plotland" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Has Boundary Wall:
                          </div>

                          <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                            <button
                              onClick={() => handleItemClick2("Yes")}
                              style={{
                                border: "black",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.hasBoundaryWall === "Yes"
                                    ? "#fda94f"
                                    : "inherit",
                                color:
                                  property.hasBoundaryWall === "Yes"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleItemClick2("No")}
                              style={{
                                border: "black",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.hasBoundaryWall === "No"
                                    ? "#fda94f"
                                    : "inherit",
                                color:
                                  property.hasBoundaryWall === "No"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              No
                            </button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  )}

                  {(property.living_Part === "commercial" ||
                    property.Sub_property_selectedItem === "plotland") && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Construction done or not :
                          </div>

                          <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                            <button
                              onClick={() => handleItemClick3("Yes")}
                              style={{
                                border: "2px solid grey",
                                borderRadius: "5px",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.construction === "Yes"
                                    ? "#fda94f"
                                    : "inherit",
                                color:
                                  property.construction === "Yes"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleItemClick3("No")}
                              style={{
                                border: "2px solid grey",
                                borderRadius: "5px",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.construction === "No"
                                    ? "#fda94f"
                                    : "inherit",

                                color:
                                  property.construction === "No"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              No
                            </button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.Sub_property_selectedItem === "plotland" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Property Facing:
                          </div>

                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {array1.map((data, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                  <Item
                                    onClick={() => handleItemClick1(data.title)}
                                    sx={{
                                      padding: 2,
                                      backgroundColor:
                                        property.selectedFacing === data.title
                                          ? "#fda94f"
                                          : "inherit",
                                      color:
                                        property.selectedFacing === data.title
                                          ? "white"
                                          : "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.title}
                                  </Item>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* commercial ============================================ */}

                  {property.living_Part === "commercial" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Furnished Status:
                          </div>

                          <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                            <button
                              onClick={() => handleItemClick4("Yes")}
                              style={{
                                border: "2px solid grey",
                                borderRadius: "5px",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.furnishedStatus === "Yes"
                                    ? "#fda94f"
                                    : "inherit",
                                color:
                                  property.furnishedStatus === "Yes"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleItemClick4("No")}
                              style={{
                                border: "2px solid grey",
                                borderRadius: "5px",
                                padding: "2px 20px",
                                backgroundColor:
                                  property.furnishedStatus === "No"
                                    ? "#fda94f"
                                    : "inherit",
                                color:
                                  property.furnishedStatus === "No"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              No
                            </button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  )}

                  {property.living_Part === "commercial" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Number of Washrooms:
                          </div>

                          <TextField
                            label="Enter Number of Washrooms"
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={property.numberOfWashroom || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                numberOfWashroom: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {property.living_Part === "commercial" && (
                    <div className={classes.container}>
                      <div className="container">
                        <div>
                          <div className={classes.heading}>
                            Number of Personal Rooms:
                          </div>

                          <TextField
                            label="Enter Number of Personal Rooms"
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={property.personalRooms || ""}
                            onChange={(e) => {
                              setPropertie({
                                ...property,
                                personalRooms: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* both========================================= */}

                  <div className={classes.container}>
                    <div className="container">
                      <div>
                        <div className={classes.heading}>
                          Now, set your price
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          You can change it anytime.
                        </div>

                        <TextField
                          label="Price"
                          variant="outlined"
                          type="number"
                          value={property.price}
                          onChange={(e) => {
                            setPropertie({
                              ...property,
                              price: e.target.value,
                            });
                          }}
                          className={classes.priceInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.container}>
                    <div className="container">
                      <div>
                        <div className={classes.heading}>
                          Add some photos of your house
                        </div>
                        <div>
                          You'll need 5 photos to get started. You can add more
                          or make changes later.
                        </div>
                        <br />

                        {property.images && property.images.length >= 1 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ImageList
                              sx={{
                                width: 700,
                                height: 450,
                                transform: "translateZ(0)",
                              }}
                              rowHeight={200}
                              gap={1}
                            >
                              {property.images.map((item, index) => {
                                {
                                  return (
                                    <ImageListItem key={item}>
                                      <img
                                        src={item.url}
                                        srcSet={`${item.url} 250w, ${item.url} 500w, ${item.url} 800w`} // Add responsive image sources
                                        alt="images"
                                        loading="lazy"
                                      />
                                      <ImageListItemBar
                                        position="top"
                                        actionIcon={
                                          <IconButton
                                            onClick={() => {
                                              handleDeleteImage(index);
                                            }}
                                            aria-label={`close ${item.title}`}
                                            sx={{ color: "white" }}
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        }
                                        actionPosition="right"
                                        sx={{
                                          background:
                                            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                        }}
                                      />
                                    </ImageListItem>
                                  );
                                }
                              })}
                            </ImageList>
                          </div>
                        )}

                        <input
                          type="file"
                          id="imageInput"
                          ref={fileInputRef} // Assign the ref to the file input
                          onChange={(e) =>
                            handleChangeImage(Array.from(e.target.files))
                          }
                          name="files"
                          multiple
                          accept={fileTypes.map((type) => `.${type}`).join(",")}
                          style={{ display: "none" }} // Hide the actual file input
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            onClick={() => {
                              handleAddMore();
                            }}
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.container}>
                    <div className="container">
                      <div>
                        <div className={classes.heading}>
                          Next let's describe your house
                        </div>

                        <div className={classes.subhead}>
                          Choose upto 2 highlights ,we will use these to get
                          your description started
                        </div>
                        <br />

                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {highlights.map((data, index) => {
                            const isSelected = property.highlights.includes(
                              data.title
                            );
                            return (
                              <Grid item xs={2} sm={4} md={4} key={index}>
                                <Item
                                  className="Capitalise"
                                  onClick={() => toggleOption(data.title)}
                                  sx={{
                                    padding: 2,
                                    backgroundColor: isSelected
                                      ? "#fda94f"
                                      : "inherit",
                                    color: isSelected ? "white" : "inherit",
                                    cursor: "pointer",
                                  }}
                                >
                                  {data.title}
                                </Item>
                              </Grid>
                            );
                          })}
                        </Grid>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div
                    className={classes.container}
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="container">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div>{/* Your existing content */}</div>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                          <Link
                            // to={listingData.Sub_property_selectedItem==="plotland"?"/property-listing/plot-step":"/property-listing/step2"}
                            to=" "
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                backgroundColor: "#fda94f",

                                border: "2px solid #fda94f ",
                              }}
                              onClick={() => {
                                handleUpdate();
                              }}
                            >
                              Update
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Snackbar
            open={false}
            /* Set to true to show the Snackbar */

            autoHideDuration={6000}
            onClose={() => {}}
            /* Add a function to handle close if needed */
            message="Successfully edited the properties"
            action={
              <Button color="inherit" size="small" onClick={() => {}}>
                OK
              </Button>
            }
          />

          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default EditProperty;
