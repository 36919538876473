import React from "react";
import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { connect } from "react-redux";
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotesIcon from '@mui/icons-material/Notes';
import SummarizeIcon from '@mui/icons-material/Summarize';
// import DropdownItem from "../../components/dropdown";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { store } from "../../redux/store";

const DropdownItem = ({ title, to, icon, selected, setSelected, children }) => {
  console.log()
  console.log(children)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setSelected(title)
    setOpen(!open);
  };


  return (
    <>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],

        }}

        icon={icon}
        onClick={handleToggle}

      >

        <Typography style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>{title} <ExpandMoreIcon /></Typography>

      </MenuItem>


      {open && children && children.map((child, index) => (
        <MenuItem
          key={index}
          active={selected === child.title}
          style={{
            color: colors.grey[100],
          }}
          onClick={() => setSelected(child.title)}

        >
          <Typography style={{ padding: "10px 0px 0px 40px" }}>{child.title}</Typography>
          <Link to={child.to} />
        </MenuItem>
      ))}
    </>
  );
};

const Item = ({ title, to, icon, selected, setSelected, childItem }) => {

  console.log(childItem)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (childItem && childItem.length > 0) {
    return (





      DropdownItem({
        title: title,
        to: to,
        icon: icon,
        selected: selected,
        setSelected: setSelected,
        children: childItem
      })


    );
  } else {


    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >

        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  }
};



const Sidebar = ({ role }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [selected, setSelected] = useState("Dashboard ");









  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);








  return (
    <Box



      sx={{
        // "& .pro-sidebar-inner": {
        //   background: `${colors.primary[400]} !important`,
        // },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: isCollapsed ? "5px 20px !important" : "5px 10px !important"
        },
        // "& .pro-inner-item:hover": {
        //   backgroundColor: "#9B56FF !important",
        //   color:"white"
        // },
        "& .pro-menu-item.active": {
          backgroundColor: "#9B56FF !important",
          color: "white !important "
        },

        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Adding box shadow
        ,
        paddingBottom: "50px"




      }}
      className='yash mobileHideSidebar'
    >
      { role ==="admin"  ? 
      
        ( <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <div className="logo-item">
            <div

              // onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                // margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                  <img src="/img/logo.svg" alt="" style={{width:"70%"}}  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              ) : (


                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </div>

      





        

          <br />




          <Box paddingLeft={isCollapsed ? undefined : "2%"} >





            <Item
              title="Dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
              // childItem={[{ title: "Deals Dashboard", to: "/dashboard" }, { title: "Projects Dashboard", to: "/project-dashboard" },
              // { title: "Leads Dashboard", to: "/leads-dashboard" },
              // ]}
            >




            </Item>


           
            {/* <Item
              title="Contacts"
              to="/contact"
              icon={<ContactPageOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}



            {/* <Item
              title="Companies"
              to="/companies"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Item
              title="Leads"
              to="/leads"
              icon={<LeaderboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />



<Item
              title="Reminders"
            to="/reminders"
              icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
              icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />



<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />








 <Item
              title="Invoices"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
              childItem={[{ title: "Invoices List", to: "/invoices-list" },
              
   
              ]}
            >




            </Item> 

           

            {/* <Item
              title="Projects"
              to="/projects"
              icon={<AccountTreeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            
<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title=" Users"
              to="/users"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Item
              title="Reports"
              to="/reports"
              icon={<SummarizeIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}



            


            <Item
              title="Settings"
              to="/setting"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

          </Box>
        </Menu>
      </ProSidebar>)





  :  
  role === "manager" ?
 ( <ProSidebar collapsed={isCollapsed}>
  <Menu iconShape="square">
    {/* LOGO AND MENU ICON */}
    <div className="logo-item">
      <div

    
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
       
          color: colors.grey[100],
        }}
      >
        {!isCollapsed ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            <img src="/img/logo.svg" alt="" />
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        ) : (


          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
           
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
    <br />
    <Box paddingLeft={isCollapsed ? undefined : "2%"} >
    <Item
        title="Dashboard"
        to="/manager-dashboard"
        icon={<DashboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />

      
      <Item
        title="Leads"
        to="/leads"
        icon={<LeaderboardIcon />}
        selected={selected}
        setSelected={setSelected}
      />


<Item
              title="Tasks"
              to="/tasks"
              icon={<AddTaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Reminders"
            to="/reminders"
            icon={<CalendarTodayIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
            icon={<MeetingRoomIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
            icon={<BookmarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      <Item
        title="Users"
        to="/agents"
        icon={<GroupOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

<Item
              title="Invoices"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
              to="/admin-dashboard"
              childItem={[{ title: "Invoices List", to: "/invoices-list" },
              ]}
            />
          


<Item
              title="Notes"
              to="/notes"
              icon={<NotesIcon />}
              selected={selected}
              setSelected={setSelected}
            />

      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
  </Menu>
</ProSidebar> ) :



( <ProSidebar collapsed={isCollapsed}>
  <Menu iconShape="square">
 
    <div className="logo-item">
      <div

       
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
       
          color: colors.grey[100],
        }}
      >
        {!isCollapsed ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            <img src="/img/logo.svg" alt="" />
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        ) : (


          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
          
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
    <br />
    <Box paddingLeft={isCollapsed ? undefined : "2%"} >

      <Item
        title="Leads"
        to="/leads"
        icon={<ReceiptOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />


<Item
              title="Tasks"
              to="/tasks"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Item
              title="Reminders"
            to="/reminders"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Meetings"
            to="/meetings"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />




<Item
              title="Follow Up"
            to="/follow-ups"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Item
              title="Notes"
              to="/notes"
              icon={<NoteAddOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

  


      <Item
        title="Settings"
        to="/setting"
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

    </Box>
  </Menu>
</ProSidebar> )



}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Sidebar);
// export default Sidebar
