import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
} from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { connect } from "react-redux";

// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LeadsDetails = ({ role, userName }) => {
  const token = getCookie("leadmanagement");
  const { id } = useParams();
  const navigate = useNavigate();

  const [leadData, setLeadData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.get_LeadsBy_Id(id);
      console.log(response.data.data);
      setLeadData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const backToPage = () => {
    navigate("/leads");
  };

  // State to store fetched users
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === "admin") {
          response = await Api.getAllUsers(token);
        } else if (role === "manager") {
          response = await Api.getAllManagerAgent(token);
        } else if (role === "agent") {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [role]);

  return (
    <>
      <Topbar />
      {leadData && (
        <>
          <Box
            m="20px"
            className="margin_in_mobile_ipad"
            sx={
              {
                // border: 'none',
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                // borderRadius: "10px"
                // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                //   color: `${colors.grey[100]} !important`,
                // },
              }
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 20px",
                alignItems: "center",
                backgroundColor: "white",
                zIndex: "9999999",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    sx={{
                      backgroundColor: "#9B56FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={backToPage}
                  >
                    {" "}
                    Back
                  </Button>
                </div>
              </div>
            </div>

            <hr />

            <div style={{ padding: " 20px", alignItems: "center" }}>
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Lead Information
                  </Typography>

                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto   ",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>
                      {" "}
                      <strong>Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.firstName || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Last Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.lastName || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Email :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.email || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Phone :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.phoneNumber || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Alternate Number :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.alternateNumber || "No"}
                      </span>
                    </h3>
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Listing Information
                  </Typography>

                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto   ",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>
                      {" "}
                      <strong>Listing Address :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.listingAddress || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Host Mail :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.hostMail || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Host Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.hostName || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Host Number :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.hostNumber || "No"}
                      </span>
                    </h3>

                    {/* <h3 > <strong>Url :</strong> <span style={{fontWeight:"600"}}>{leadData.listingUrl || "No"}</span></h3>  */}
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Address Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>
                      {" "}
                      <strong>Street :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.street || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>City :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.city || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Postal Code :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.zipCode || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Postal Code :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.state || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Postal Code :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.country || "No"}
                      </span>
                    </h3>
                  </Box>
                </Box>
              </div>

              <br />
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Company Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >



                    <h3>
                      {" "}
                      <strong>Company Name :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.industry || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Owner :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.owner || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>WebSite :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.website || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Number of Employees :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.employNumber || "No"}
                      </span>
                    </h3>


                  
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Additional Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                     
                      gridTemplateColumns: "auto auto   ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >

<h3>
                      {" "}
                      <strong>Lead Source :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.leadSource || "No"}
                      </span>
                    </h3>

                    <h3>
                      {" "}
                      <strong>Lead Status :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.leadStatus || "No"}
                      </span>
                    </h3>
                    <h3>
                      {" "}
                        <strong>User Responsible :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData?.assignTo?.firstName && leadData?.assignTo?.lastName
                            ? `${leadData.assignTo.firstName} ${leadData.assignTo.lastName}`
                            : "No"}
                        </span>
                      </h3>


                    
                    <h3>
                      {" "}
                      <strong>Property Id  :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.propertyId || "No"}
                      </span>
                    </h3>


                  
                    {/* <Grid>
                      <div>User Responsible</div>
                      <FormControl fullWidth>
                     

                        <Select
                          sx={{ height: "40px" }}
                          labelId="third-select-label"
                          id="third-select"
                          fullWidth
                          defaultValue=""
                          value={leadData.assignTo}
                    
                        >
                       
                          {users.map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                  </Box>
                </Box>
              </div>

              <br />

              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                

              

                  <h3>
                      {" "}
                      <strong>Title :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.title || "No"}
                      </span>
                    </h3>

                 
                 
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                 
                  
                  <Box component="form" noValidate autoComplete="off">

                  <h3>
                      {" "}
                      <strong>Description :</strong>{" "}
                      <span style={{ fontWeight: "600" }}>
                        {leadData.description || "No"}
                      </span>
                    </h3>
                   
                  </Box>
                </Box>
              </div>
            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(LeadsDetails);
