import { NavLink ,useParams } from "react-router-dom";


import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Grid, TextField, MenuItem, Snackbar } from "@mui/material";
import { drop, topoptions, amenities, safety } from "../../components/data/index";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  nextback: {
    justifyContent: "space-between",
    display: "flex",
  },

  mainContainer: {
    display: "flex",
    justifyContent: "space-between",

    // Media Query for Mobile
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },

    // Media Query for iPad
    [theme.breakpoints.between("sm", 900)]: {
      flexDirection: "column", // Change it as per your requirement for iPad
    },
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },

  subhead: {
    fontSize: "15px",
    fontWeight: "600",
  },
}));
const Step2 = ({hostProperty}) => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const setUserData = (data) => {
    store.dispatch({
      type: "set_host_property",
      payload: data,
    });
  };
  const [selectedAmenities, setSelectedAmenities] = useState( hostProperty.amenities || []);
  const handleAmenityClick = (amenity) => {
    if (selectedAmenities.includes(amenity)) {
      setSelectedAmenities((prevSelected) =>
        prevSelected.filter((item) => item !== amenity)
      );
    } else {
      setSelectedAmenities((prevSelected) => [...prevSelected, amenity]);
    }
  };

  const classes = useStyles();
  const [formData, setFormData] = useState({
    inputBeds: hostProperty.beds || "",
    inputBedrooms:hostProperty.bedrooms || "",
    inputBathrooms:hostProperty.bathrooms || "",
  });





   const handleChange = (name, e) => {
    if (name == "Beds") {
      setFormData({ ...formData, inputBeds: e.target.value });
    }

    if (name == "Bathrooms") {
      setFormData({ ...formData, inputBathrooms: e.target.value });
    }

    if (name == "Bedrooms") {
      setFormData({ ...formData, inputBedrooms: e.target.value });
    }
   

    // setFormData({
    //   ...formData,
    //   [name]: e.target.value,
    // });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSubmit = () => {
    const isAmenitiesValid = selectedAmenities.length > 0;
    const isBedsValid = formData.inputBeds !== "";
    const isBedroomsValid = formData.inputBedrooms !== "";
    const isBathroomsValid = formData.inputBathrooms !== "";

    if (
      !(isAmenitiesValid && isBedsValid && isBedroomsValid && isBathroomsValid)
    ) {
      showSnackbar(
        "Validation failed. Please fill in all required fields.",
        "error"
      );

      return;
    }

    setUserData({
      amenities: selectedAmenities,
      beds: formData.inputBeds,
      bathrooms: formData.inputBathrooms,
      bedrooms: formData.inputBedrooms,
    });
    Navigate(`/property-listing/${id}/step3`);
  };

  return (
    <>
      
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>
            
                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Share some basics about your place
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        You'll add more details later, such as bed types.
                      </div>
                      <div
                        style={{
                          // overflowY: "auto",
                          maxHeight:
                            "400px" /* Set a max height for the scrollable container */,
                        }}
                      >
                        <Grid container spacing={2}>
                          {drop.map((item) => (
                            <Grid item xs={12} sm={6} md={3} key={item.name}>
                              <TextField
                                select
                                label={item.name}
                                variant="outlined"
                                fullWidth
                                value={
                                  item.name === "Beds"
                                    ? formData.inputBeds
                                    : item.name === "Bedrooms"
                                    ? formData.inputBedrooms
                                    : item.name === "Bathrooms"
                                    ? formData.inputBathrooms
                                    : " "
                                }// Use item.name instead of item
                                onChange={(e) => {
                                  handleChange(item.name, e);
                                }} // Use item.name instead of item
                                style={{ marginTop: 8 }}
                              >
                                <MenuItem value="1">{item.one}</MenuItem>
                                <MenuItem value="2">{item.two}</MenuItem>
                                <MenuItem value="3">{item.three}</MenuItem>
                                <MenuItem value="4">{item.four}</MenuItem>
                              </TextField>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Tell guests what your place has to offer
                      </div>
                      <div>
                        Share some basic info, such as where it is and how many
                        guest can stay.
                      </div>
                      <br />
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {topoptions.map((data, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Item
                              className="Capitalise"
                              onClick={() => {
                                handleAmenityClick(data.title);
                              }}
                              sx={{
                                padding: 2,
                                backgroundColor: selectedAmenities.includes(
                                  data.title
                                )
                                  ? "#fda94f"
                                  : "inherit",
                                color: selectedAmenities.includes(data.title)
                                  ? "white"
                                  : "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data.title}
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                      <br />

                      <div className={classes.subhead}>
                        Do you have any standout amenities?
                      </div>
                      <br />

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {amenities.map((data, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Item
                              className="Capitalise"
                              onClick={() => {
                                handleAmenityClick(data.title);
                              }}
                              sx={{
                                padding: 2,
                                backgroundColor: selectedAmenities.includes(
                                  data.title
                                )
                                  ? "#fda94f"
                                  : "inherit",
                                color: selectedAmenities.includes(data.title)
                                  ? "white"
                                  : "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data.title}
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                      <br />

                      <div className={classes.subhead}>
                        Do you have any of these safety items?
                      </div>
                      <br />

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {safety.map((data, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Item
                              className="Capitalise"
                              onClick={() => {
                                handleAmenityClick(data.title);
                              }}
                              sx={{
                                padding: 2,
                                backgroundColor: selectedAmenities.includes(
                                  data.title
                                )
                                  ? "#fda94f"
                                  : "inherit",
                                color: selectedAmenities.includes(data.title)
                                  ? "white"
                                  : "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data.title}
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                      <br />
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div
                  className={classes.container}
                  style={{ marginBottom: "15px" }}
                >
                  <div className="container">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>{/* Your existing content */}</div>
                      </Grid>
                      <Grid item xs={12} className={classes.nextback}>
                        <Link to={`/property-listing/${id}/step1`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                        {/* /property-listing/step3 */}

                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#fda94f",

                            border: "2px solid #fda94f ",
                          }}

                          // disabled={isNextButtonDisabled}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>

                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                      >
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
};




const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty,

});


export default connect(mapStateToProps)(Step2);
