import { NavLink, useNavigate ,useParams} from "react-router-dom";
// import Grid from '@mui/material/Grid';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import TextField from '@mui/material/TextField';
// import Map from "../pages/Map";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { Button, Grid, TextField, Snackbar } from "@mui/material";
import { residentialOptions, Commercial } from "../../components/data/index";
import { store } from "../../redux/store";
const apiKey = "AIzaSyCtz99HysBUR1PssP3K8UvLbluIpF9kpoc";
const loadScript = async (url, callback) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url + `&callback=${callback}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = (error) => {
      reject(error);
    };

    document.head.appendChild(script);
  });
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  priceInput: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",

 // Media Query for Mobile
 [theme.breakpoints.down('xs')]: {
  flexDirection: "column",
},

// Media Query for iPad
[theme.breakpoints.between('sm', 900)]: {
  flexDirection: "column", // Change it as per your requirement for iPad
},


  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "30px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },
}));
const Step1 = ({hostProperty}) => {
  console.log(hostProperty)
  hostProperty  = hostProperty || {}
  const { id } = useParams();


  const wordLimit = 20; // Set your desired word limit

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/); // Split words using whitespace as a delimiter
    const isValid = words.length <= wordLimit;

    if (isValid) {
      setListingData({
        ...listingData,
        name: inputValue,
      });
    } else {
      // Handle the error (you can display an error message or any other action)
      alert('Exceeded word limit');
    }
  };





  const wordLimit1 = 30; // Set your desired word limit

  const handleDesChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/); // Split words using whitespace as a delimiter
    const isValid = words.length <= wordLimit1;

    if (isValid) {
      setListingData({
        ...listingData,
        description: inputValue,
      });
    } else {
      // Handle the error (you can display an error message or any other action)
      alert('Exceeded word limit');
    }
  };




  const Navigate = useNavigate()
 
  const [listingData, setListingData] = useState({
    room_type: hostProperty.room_type || "sell",
    Sub_property_selectedItem: hostProperty.Sub_property_selectedItem || "" ,
    name:hostProperty.name ||  "",
    description: hostProperty.description || "",
    living_Part:hostProperty.living_Part || "residential",
  });
  const setUserData = (data) => {
    store.dispatch({
      type: "set_host_property",
      payload: data,
    });
  };
 

  useEffect(() => {

    if(
      listingData.room_type==="hotel"
      ){
        setListingData({
          ...listingData,
          Sub_property_selectedItem: "hotel",
      
        })}else{
          setListingData({
            ...listingData,
            Sub_property_selectedItem: hostProperty.Sub_property_selectedItem || "",
            living_Part: "residential",
          });
        } 


  
  }, [listingData.room_type]);
  useEffect(() => {
    setListingData({ ...listingData, Sub_property_selectedItem:  hostProperty.Sub_property_selectedItem || "" });
  }, [listingData.living_Part]);

  const classes = useStyles();

  const [address, setAddress] = useState({
    Country: "",
    locality: hostProperty.address && hostProperty.address.locality  ||  "",
    city: hostProperty.address &&  hostProperty.address.city ||  "",
    pincode: hostProperty.address && hostProperty.address.pincode ||  "",
    street: hostProperty.address && hostProperty.address.street || "",
    lat: hostProperty.address && hostProperty.address.lat || null,
    lng:hostProperty.address && hostProperty.address.lng || null,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const initMap = async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
          "initializeAutocomplete"
        );
      } catch (error) {
        console.error("Error loading Google Maps API:", error);
      }
    };

    initMap();
  }, []);
  window.initializeAutocomplete = () => {
    const cityInput = document.getElementById("city");
    const localityInput = document.getElementById("locality");
    const pincodeInput = document.getElementById("pincode");

    const cityAutocomplete = new window.google.maps.places.Autocomplete(
      cityInput,
      {
        types: ["(cities)"], 
        componentRestrictions: { country: "IN" }, 
      }
    );
    const localityAutocomplete = new window.google.maps.places.Autocomplete(
      localityInput,
      {
        types: ["address"], 
        componentRestrictions: { country: "IN" }, 
      }
    );
    const pincodeAutocomplete = new window.google.maps.places.Autocomplete(
      pincodeInput,
      {
        types: ["postal_code"], 
        componentRestrictions: { country: "IN" }, 
      }
    );

    cityAutocomplete.addListener("place_changed", () => {
      const selectedCity = cityAutocomplete.getPlace();
      if (selectedCity.geometry) {
        const cityBounds = selectedCity.geometry.viewport;
        pincodeAutocomplete.setBounds(cityBounds);
        localityAutocomplete.setBounds(cityBounds);
        console.log(selectedCity);
        setAddress((prevAddress) => ({
          ...prevAddress,
          city: selectedCity.formatted_address,
          locality: "",
          pincode: "",
          lat: selectedCity.geometry.location.lat(),
          lng: selectedCity.geometry.location.lng(),
        }));
      }
    });

    localityAutocomplete.addListener("place_changed", () => {
      const selectedLocality = localityAutocomplete.getPlace();
      console.log(selectedLocality);
      if (selectedLocality.geometry) {
        console.log(selectedLocality);
        setAddress((prevAddress) => ({
          ...prevAddress,
          locality: selectedLocality.formatted_address,
          pincode: "",
          lat: selectedLocality.geometry.location.lat(),
          lng: selectedLocality.geometry.location.lng(),
        }));
      }
    });

    pincodeAutocomplete.addListener("place_changed", () => {
      const selectedPincode = pincodeAutocomplete.getPlace();
      if (selectedPincode.geometry) {
        const pincodeValue = selectedPincode.formatted_address.replace(
          /[^0-9]/g,
          ""
        );
        setAddress((prevAddress) => ({
          ...prevAddress,
          pincode: pincodeValue,
        }));
      }
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = () => {
    console.log(listingData);
    const mandatoryFields = [ "locality", "city", "pincode"];
    const areMandatoryFieldsValid = mandatoryFields.every(field => address[field] !== "" && address[field] !== null);
    if (!areMandatoryFieldsValid) {
      showSnackbar('Validation failed. Please fill in all required fields.', 'error');
      return;
    }


    const isFieldValid = Object.values(listingData).every((value) => value !== "" && value !== null);

    if (!isFieldValid) {
      showSnackbar('Validation failed. Please fill in all required fields.', 'error');
      return;
    }


    
    // if (listingData.name.length < 20 ) {
    //   showSnackbar(' Title must contains  20 words.', 'error');
    //   return;
    // }

    // if (listingData.description.length < 60) {
    // showSnackbar('Description must contains 60 words.', 'error');
    //   return;
    // }


    console.log(address);
    setUserData({
      ...listingData,
      address,
    });
    Navigate(
     
        listingData.Sub_property_selectedItem === "plotland"
          ? `/property-listing/${id}/plot-step`
          : listingData.living_Part === "commercial"
          ? `/property-listing/${id}/commercial-step`
          : `/property-listing/${id}/step2`
      
    
    )
  };

  return (
    <>
     
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Which of these best describes your place?
                      </div>

                      <div className={classes.optionsContainer}>
                        <Paper
                          onClick={() =>
                            setListingData({
                              ...listingData,
                              room_type: "sell",
                            })
                          }
                          sx={{
                            backgroundColor:
                              listingData.room_type === "sell"
                                ? "#fda94f"
                                : "inherit",
                            color:
                              listingData.room_type === "sell"
                                ? "white"
                                : "inherit",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ padding: "2px 20px" }}
                          >
                            Sell
                          </Typography>
                        </Paper>

                        <Paper
                          onClick={() =>
                            setListingData({
                              ...listingData,
                              room_type: "rent/lease",
                            })
                          }
                          sx={{
                            backgroundColor:
                              listingData.room_type === "rent/lease"
                                ? "#fda94f"
                                : "inherit",
                            color:
                              listingData.room_type === "rent/lease"
                                ? "white"
                                : "inherit",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ padding: "2px 20px" }}
                          >
                            Rent/Lease
                          </Typography>
                        </Paper>

                        <Paper
                          onClick={() =>
                            setListingData({
                              ...listingData,
                              room_type: "hotel",
                            })
                          }
                          sx={{
                            backgroundColor:
                              listingData.room_type === "hotel"
                                ? "#fda94f"
                                : "inherit",
                            color:
                              listingData.room_type === "hotel"
                                ? "white"
                                : "inherit",
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ padding: "2px 20px" }}
                          >
                            Hotel
                          </Typography>
                        </Paper>
                      </div>

                     {listingData.room_type==="hotel"?"": <div className={classes.radioButtonContainer}>
                        <RadioGroup row>
                          <FormControlLabel
                            onChange={() => {
                              setListingData({
                                ...listingData,
                                living_Part: "residential",
                              });
                            }}
                            value="residential"
                            control={<Radio />}
                            label="Residential"
                            name="living_Part"
                            checked={listingData.living_Part === "residential"}
                          />
                          <FormControlLabel
                            onChange={() => {
                              setListingData({
                                ...listingData,
                                living_Part: "commercial",
                              });
                            }}
                            value="commercial"
                            control={<Radio />}
                            label="Commercial"
                            name="living_Part"
                            checked={listingData.living_Part === "commercial"}
                          />
                        </RadioGroup>
                      </div>}

                   {listingData.room_type==="hotel"?""  : <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {(listingData.living_Part === "residential"
                            ? residentialOptions
                            : Commercial
                          ).map((data, index) => {
                            if (
                              (listingData.room_type === "rent/lease" &&
                                data.value === "plotland") ||
                              (listingData.room_type === "sell" &&
                                data.value === "pg")
                            ) {
                              return null;
                            }

                            return (
                              <Grid item xs={2} sm={4} md={4} key={index}>
                                <Item
                                  onClick={() =>
                                    setListingData({
                                      ...listingData,
                                      Sub_property_selectedItem: data.value,
                                    })
                                  }
                                  sx={{
                                    padding: 2,
                                    backgroundColor:
                                      listingData.Sub_property_selectedItem ===
                                      data.value
                                        ? "#fda94f"
                                        : "inherit",
                                    color:
                                      listingData.Sub_property_selectedItem ===
                                      data.value
                                        ? "white"
                                        : "inherit",
                                    cursor: "pointer",
                                  }}
                                >
                                  {data.title}
                                </Item>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>}
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Confirm your address
                      </div>
                      <div>
                        Your address is only shared with guests after they’ve
                        made a reservation.
                      </div>

                      <form>
                        <div className="">
                          <input
                            style={{
                             
                              borderRadius: "10px 10px 0px 0px",
                              border: "1px solid #999999",
                              height: "50px",
                             
                              width:"100%"
                            }}
                            id="city"
                            className="form-control"
                            type="text"
                            placeholder="City"
                            value={address.city}
                            onChange={(e) => {
                              setAddress((prevAddress) => ({
                                ...prevAddress,
                                city: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        <div className="">
                          <input
                            style={{
                              borderRadius: " 0px",
                              border: "1px solid #999999",
                              height: "50px",
                             
                              width:"100%"
                            }}
                            id="locality"
                            className="form-control"
                            type="text"
                            placeholder="Locality"
                            value={address.locality}
                            onChange={(e) => {
                              setAddress((prevAddress) => ({
                                ...prevAddress,
                                locality: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        <div className="">
                          <input
                            style={{
                              borderRadius: " 0px",
                              border: "1px solid #999999",
                              height: "50px",
                             
                              width:"100%"
                            }}
                            type="text"
                            required=""
                            id="streetAddress"
                            className="form-control"
                            data-label="STREET_ADDRESS"
                            placeholder="Nearby Address/Optional"
                            value={address.street}
                            onChange={(e) => {
                              setAddress((prevAddress) => ({
                                ...prevAddress,
                                street: e.target.value,
                              }));
                            }}
                          />
                          {/* <label className="form-label pt-2" for="form6Example3">
                        Nearby Address
                      </label> */}
                        </div>

                        <div className="">
                          <input
                            style={{
                              borderRadius: "0px 0px 10px 10px",
                              border: "1px solid #999999",
                              height: "50px",
                             
                              width:"100%"
                            }}
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="6"
                            minLength="6"
                            id="pincode"
                            className="form-control"
                            placeholder="PinCode/Optional"
                            value={address.pincode}
                            onChange={(e) => {
                              const input = e.target.value.replace(/\D/g, "");
                              const pincodeValue = input.replace(/[^0-9]/g, "");
                              setAddress((prevAddress) => ({
                                ...prevAddress,
                                pincode: pincodeValue,
                              }));
                            }}
                          />
                          {/* <label className="form-label pt-2" for="form6Example3">
                        PIN code
                      </label> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

             {/*    <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div>Show your specific location</div>

                      <Map
                        location={{
                          lat: address.lat,
                          lng: address.lng,
                        }}
                      />
                    </div>
                  </div>
                </div>
                */}

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Now , let's give your property a title
                      </div>
                      <TextField  
                
                        label="Title"
                        variant="outlined"
                        type="text"
                        className={classes.priceInput}
                        value={listingData.name}
                        multiline

                        onChange={handleInputChange}
                        // onChange={(e) => {
                        //   setListingData({
                        //     ...listingData,
                        //     name: e.target.value,
                        //   });
                        // }}

                      
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>

                        Create your description

                      </div>
                      <TextField
                        label="Description"
                        variant="outlined"
                        type="text"
                        className={classes.priceInput}
                        value={listingData.description}
                        multiline
                        onChange={handleDesChange}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.container}  style={{marginBottom:"15px"}}>
                  <div className="container">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>
                          {/* Your existing content */}
                          </div>
                      </Grid>
                      <Grid item xs={12} textAlign="right">
                       
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Next
                          </Button>
                       
                      </Grid>
                    </Grid>

                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                      >
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};



const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty,

});


export default connect(mapStateToProps)(Step1);
