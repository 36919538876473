export const safety = [
    {
      title: "smoke alarm"
    },
    {
      title: "first aid kit"
    },
    {
      title: "fire extinguisher"
    },
    {
      title: "carbon monoxide alarm"
    },
  
  ]
  

  
export const amenities = [
    {
      title: "pool"
    },
    {
      title: "hot tub"
    },
    {
      title: "patio"
    },
    {
      title: " BBQ grill"
    },
    {
      title: "outdoor dining Area"
    },
    {
      title: "fire pit"
    },
    {
      title: "pool table"
    },
  
  
    {
      title: "indoor fire place"
    }
    ,
    {
      title: "paino"
    }
    ,
    {
      title: "exercise equipment"
    }
    ,
    {
      title: "lake access"
    }
  
    ,
    {
      title: "beach access"
    }
  
    ,
    {
      title: "ski-in/Ski-out"
    }
  
    ,
    {
      title: "outdoor shower"
    }
  
  
  ]

  
export const topoptions = [
    {
      title: " wifi"
    },
    {
      title: " tV"
    },
    {
      title: " kitchen"
    },
    {
      title: " washing machine"
    },
    {
      title: " free parking on premises"
    },
    {
      title: " paid parking on premises"
    },
    {
      title: "air conditioning"
    }
    ,
    {
      title: "dedicated workspace"
    }
  
  ]

  

export const drop = [

    {
      name: "Beds",
      one: 1,
      two: 2,
      three: 3,
      four: "4+"
    }
    , {
      name: "Bedrooms",
      one: 1,
      two: 2,
      three: 3,
      four: "4+"
    }
    , {
      name: "Bathrooms",
      one: 1,
      two: 2,
      three: 3,
      four: "4+"
    }
  ]
  
  export const highlights = [
    {
      title: "peaceful"
    },
    {
      title: "unique"
    },
    {
      title: "family-friendly"
    },
    {
      title: "stylish"
    },
    {
      title: "central"
    },
    {
      title: "spacious"
    },
  
  ]
    
  

export const array = [
  {
    title: "One Side",
  },
  {
    title: "Two Sides",
  },
  {
    title: "Three Sides",
  },
  {
    title: "Four Sides",
  },
  {
    title: "Five Sides",
  },
];

export const array1 = [
  {
    title: "North",
  },
  {
    title: "South",
  },
  {
    title: "East",
  },
  {
    title: "West",
  },
  {
    title: "North-East",
  },
  {
    title: "North-West",
  },

  {
    title: "South-East",
  },
  
  {
    title: "South-West"
  }
]


export const residentialOptions = [
  { title: 'Flat/Apartment', value: "flatapartment" },
  { title: 'Serviced Apartments', value: "servicedapartments" },
  { title: 'Independent/Builder Floor', value: "independentbuilderfloor" },
  { title: '1 RK/ Studio Apartment', value: "1rkstudioapartment" },
  { title: 'Independent House/Villa', value: "independenthousevilla" },
  { title: 'Farm House', value: "farmhouse" },
  { title: 'Plot/Land', value: "plotland" },
  { title: 'PG', value: "pg" },


];



export const Commercial = [
  { title: "Office space", value: "officespace" },
  { title: "Shop", value: "Shop" },
  { title: "Warehouse/Godown", value: "warehousegodown" },
  { title: "Industrial Land", value: "industrialland" },
  { title: "Industrial Shed", value: "industrialshed" },
  { title: "ShowRooms", value: "showRooms" },
]