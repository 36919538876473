import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Button,
} from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const EditLead = ({ role, userName }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const token = getCookie("leadmanagement");
  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;

    setLeadData({
      ...leadData,
      assignTo: selectedValue ? { _id: selectedValue } : null, // If no value, set assignTo to null
    });
  };
  const [leadData, setLeadData] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.get_LeadsBy_Id(id);
      console.log(response.data.data);
      setLeadData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const backToPage = () => {
    navigate("/leads");
  };

  const handleUpdate = async () => {
    try {
      const response = await Api.update_Lead(id, { ...leadData }, token);

      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/leads"),
        });
      }
    } catch (error) {
      console.error("Error updating property:", error);
    }
  };

  // State to store fetched users
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === "admin") {
          response = await Api.getAllUsers(token);
        } else if (role === "manager") {
          response = await Api.getAllManagerAgent(token);
        } else if (role === "agent") {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [role]);

  return (
    <>
      <Topbar />

      {leadData && (
        <>
          <Box m="20px" className="margin_in_mobile_ipad" sx={{}}>
            <div style={{ padding: " 20px", alignItems: "center" }}>
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Lead Information
                  </Typography>

                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto   ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="First Name"
                      value={leadData.firstName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({ ...leadData, firstName: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Last Name"
                      value={leadData.lastName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({ ...leadData, lastName: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Email"
                      value={leadData.email}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 35) {
                          setLeadData({ ...leadData, email: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Phone"
                      value={leadData.phoneNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 10) {
                          setLeadData({ ...leadData, phoneNumber: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Alternate Number"
                      value={leadData.alternateNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setLeadData({
                            ...leadData,
                            alternateNumber: inputText,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Address Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Street"
                      value={leadData.street}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 40) {
                          setLeadData({ ...leadData, street: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="City"
                      value={leadData.city}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, city: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="State"
                      value={leadData.state}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, state: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Country"
                      value={leadData.country}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, country: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Postal Code"
                      value={leadData.zipCode}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 10) {
                          setLeadData({ ...leadData, zipCode: inputText });
                        }
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </div>
              <br /> <br />
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Company Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto  ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Company Name"
                      value={leadData.industry}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, industry: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Owner"
                      value={leadData.owner}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, owner: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="WebSite"
                      value={leadData.website}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 20) {
                          setLeadData({ ...leadData, website: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <Grid>
                      <div>Number of Employees</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={leadData.employNumber}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              employNumber: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="1-9employees">
                            1-9 employees
                          </MenuItem>
                          <MenuItem value="10-50employees">
                            10-50 employees
                          </MenuItem>
                          <MenuItem value="50-200employees">
                            {" "}
                            50-200 employees
                          </MenuItem>
                          <MenuItem value="200+">
                            more than 200 employees
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Additional Information
                  </Typography>
                  <br />
                  <br />

                  <Box
                    component="form"
                    className="LMS-Info-section"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto   ",
                      gap: "20px",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid>
                      <div>Lead Status</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={leadData.leadStatus}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              leadStatus: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="new">New</MenuItem>
                          <MenuItem value="processing">In discussion</MenuItem>
                          <MenuItem value="confirm">Hired</MenuItem>
                          <MenuItem value="cancel">Cold/Dead</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <div>Lead Source</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="first-select-label"
                          id="first-select"
                          fullWidth
                          defaultValue=""
                          value={leadData.leadSource}
                          onChange={(e) =>
                            setLeadData({
                              ...leadData,
                              leadSource: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="facebook">Facebook</MenuItem>
                          <MenuItem value="instagram">Instagram</MenuItem>
                          <MenuItem value="whatsapp">Whatsapp </MenuItem>
                          <MenuItem value="linkdin">LinkdIn </MenuItem>
                          <MenuItem value="googleAds">Google Ads </MenuItem>
                          <MenuItem value="websites">Websites </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <div>User Responsible</div>

                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px" }}
                          labelId="third-select-label"
                          id="third-select"
                          value={leadData.assignTo?._id}
                          onChange={handleChange}
                        >
                          {users.map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Box>
                </Box>
              </div>
              <br />
              <div
                className="column_form"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Title
                  </Typography>

                  <br />
                  <br />

                  <TextField
                    label="Title"
                    type="text"
                    id="second-text-field"
                    value={leadData.title}
                    multiline
                    onChange={(e) => {
                      const inputText = e.target.value;
                      const words = inputText.trim().split(/\s+/);
                      if (words.length <= 100) {
                        setLeadData({ ...leadData, title: inputText });
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                </Box>

                <Box
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Typography
                    variant="h4"
                    fontWeight="550"
                    sx={{ m: "0 0 5px 0" }}
                    className="heading_animation"
                  >
                    Description
                  </Typography>
                  <br />
                  <br />
                  <Box component="form" noValidate autoComplete="off">
                    <TextField
                      label="Description"
                      type="text"
                      id="second-text-field"
                      value={leadData.description}
                      multiline
                      onChange={(e) => {
                        const inputText = e.target.value;
                        const words = inputText.trim().split(/\s+/);
                        if (words.length <= 500) {
                          setLeadData({ ...leadData, description: inputText });
                        }
                      }}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    sx={{
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update
                  </Button>

                  <Button
                    sx={{
                      backgroundColor: "#9B56FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF",
                      },
                    }}
                    onClick={backToPage}
                  >
                    {" "}
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(EditLead);
