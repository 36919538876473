import { NavLink, useNavigate,useParams } from "react-router-dom";
import { store } from "../../redux/store";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { Grid, InputLabel, MenuItem, Select, TextField ,Snackbar } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { array , array1} from '../../components/data/index'




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  opensides: {
    backgroundColor: "#fda94f",
    color: "white",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },

  nextback: {
    justifyContent: "space-between",
    display: "flex",
  },

  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoContainer: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },

  subhead: {
    fontSize: "15px",
    fontWeight: "600",
  },
}));
const PlotStep = ({hostProperty}) => {
  const { id } = useParams();
  const classes = useStyles();
  const Navigate = useNavigate()
  const [property, setProperty] = useState({
    areaDetails:hostProperty.areaDetails || "",
    areaType:hostProperty.areaType || "sq.ft",
    propertyDimensions: { length: hostProperty && hostProperty.propertyDimensions && hostProperty.propertyDimensions.length || "", width:hostProperty && hostProperty.propertyDimensions && hostProperty.propertyDimensions.width || "" },
    numberOfFloors:hostProperty.numberOfFloors || "",
    numberOfOpenSides:hostProperty.numberOfOpenSides || "",
    hasBoundaryWall:hostProperty.hasBoundaryWall ||    "",
    construction:hostProperty.construction || "",
    selectedFacing:hostProperty.selectedFacing || '',
  });
  const setUserData = (data) => {
    store.dispatch({
      type: "set_host_property",
      payload: data,
    });
  };
  const validateAndUpdatePropertyDimensions = (newLength, newWidth) => {
    const newArea = Number(newLength) * Number(newWidth);
    if (newArea <= property.areaDetails) {
      setProperty({ ...property, propertyDimensions: { length: newLength, width: newWidth } });
    }
  };
  const setPropertyDetails = (details) => {
    setProperty({ ...property, ...details });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


 

  const handleSubmit = () => {

    const isAreaDetailsValid = !!property.areaDetails;
    const arePropertyDimensionsValid =
      !!property.propertyDimensions.length &&
      !!property.propertyDimensions.width;
    const isNumberOfFloorsValid = !!property.numberOfFloors;
    const isNumberOfOpenSidesValid = !!property.numberOfOpenSides;
    const isConstructionValid = !!property.construction;
    const isHasBoundaryWallValid = !!property.hasBoundaryWall;
    const isSelectedFacingValid = !!property.selectedFacing;

   
    if (
      !(
        isAreaDetailsValid &&
        arePropertyDimensionsValid &&
        isNumberOfFloorsValid &&
        isNumberOfOpenSidesValid &&
        isConstructionValid &&
        isHasBoundaryWallValid &&
        isSelectedFacingValid
      )
    ) {
      showSnackbar('Validation failed. Please fill in all required fields.', 'error');
     
      return;
    }

  
    setUserData({
      ...property,
    });
    Navigate(`/property-listing/${id}/step3`);
  };

  
  return (
    <>
   
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>
             

                <br />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Tell About Your Property
                      </div>
                      <div>Area Details:</div>

                      <br />
                      <div className={classes.inputContainer}>
                        <TextField
                          label="Area"
                          type="number"
                          variant="outlined"
                          size="small"
                          value={property.areaDetails}
                          onChange={(e) => setPropertyDetails({ areaDetails: e.target.value })}
                          // Add any additional props you need for validation or styling
                        />
                        <InputLabel>Dimensions</InputLabel>
                        <select
                className="px-3"
                value={property.areaType}
                onChange={(e) => setPropertyDetails({ areaType: e.target.value })}
                style={{ padding: "10px", border: "1px solid #ddd", borderRadius: "5px", boxSizing: "border-box" }}
              >
                <option value="sq.ft">sq.ft</option>
                <option value="sq.yard">sq.yard</option>
                <option value="sq.m">sq.m</option>
                <option value="acres">acres</option>
                <option value="marla">marla</option>
                <option value="cents">cents</option>
              </select>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Property Dimensions:
                      </div>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Length"
                            type="number"
                            variant="outlined"
                            value={property.propertyDimensions.length}
                            onChange={(e) => validateAndUpdatePropertyDimensions(e.target.value, property.propertyDimensions.width)}
                            fullWidth
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Width"
                            value={property.propertyDimensions.width}
                            onChange={(e) => validateAndUpdatePropertyDimensions(property.propertyDimensions.length, e.target.value)}
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Floors Allowed for Construction
                      </div>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Number of Floors"
                          value={property.numberOfFloors}
                          onChange={(e) => setPropertyDetails({ numberOfFloors: e.target.value })}
                          type="number"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            style: { paddingLeft: '13px', paddingRight: '10px' }, // Adjust padding for spacing
                          }}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Number of Open Sides:
                      </div>

                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {array.map((data, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                              <Item
                                onClick={() =>(
                                  setProperty({...property , numberOfOpenSides:data.title})
                                )}
                                sx={{
                                  padding: 2,
                                  backgroundColor:
                                  property.numberOfOpenSides === data.title
                                      ? "#fda94f"
                                      : "inherit",
                                  color:
                                  property.numberOfOpenSides === data.title
                                      ? "white"
                                      : "inherit",
                                  cursor: "pointer",
                                }}
                              >
                                {data.title}
                              </Item>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>
                        Any Construction done on this property:
                      </div>

                      <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                        <button
                          onClick={() => {
                            setProperty({...property ,construction:"Yes" })
                          }}
                          style={{
                            border: "black",
                            padding: "2px 20px",
                            backgroundColor:
                            property.construction === "Yes" ? "#fda94f" : "inherit",
                            color: property.construction === "Yes" ? "white" : "inherit",
                          }}
                        >
                          Yes
                        </button>
                        <button
                            onClick={() => {
                              setProperty({...property ,construction:"No" })
                            }}
                          style={{
                            border: "black",
                            padding: "2px 20px",
                            backgroundColor:
                            property.construction === "No" ? "#fda94f" : "inherit",
                            color:property.construction === "No" ? "white" : "inherit",
                          }}
                        >
                          No
                        </button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Has Boundary Wall:</div>

                      <ButtonGroup style={{ display: "flex", gap: "20px" }}>
                        <button
                          onClick={() => {
                            setProperty({...property ,hasBoundaryWall:"Yes" })
                          }}
                          style={{
                            border: "black",
                            padding: "2px 20px",
                            backgroundColor:
                            property.hasBoundaryWall === "Yes" ? "#fda94f" : "inherit",
                            color:   property.hasBoundaryWall === "Yes" ? "white" : "inherit",
                          }}
                        >
                          Yes
                        </button>
                        <button
                         onClick={() => {
                          setProperty({...property ,hasBoundaryWall:"No" })
                        }}
                          style={{
                            border: "black",
                            padding: "2px 20px",
                            backgroundColor:
                            property.hasBoundaryWall === "No" ? "#fda94f" : "inherit",
                            color:   property.hasBoundaryWall === "No" ? "white" : "inherit",
                          }}
                        >
                          No
                        </button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Property Facing:</div>

                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {array1.map((data, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                              <Item
                                onClick={() => setPropertyDetails( {selectedFacing:data.title } )}
                                sx={{
                                  padding: 2,
                                  backgroundColor:
                                    property.selectedFacing === data.title
                                      ? "#fda94f"
                                      : "inherit",
                                  color:
                                    property.selectedFacing === data.title
                                      ? "white"
                                      : "inherit",
                                  cursor: "pointer",
                                }}
                              >
                                {data.title}
                              </Item>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  </div>
                </div>

                <hr style={{ width: "100%" }} />

                <div className={classes.container} style={{marginBottom:"15px"}}>
                  <div className="container">
                    <Grid container spacing={2}>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12} className={classes.nextback}>
                        <Link to={`/property-listing/${id}/step1`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Back
                          </Button>
                        </Link>

                        {/* <Link to="/property-listing/step3"> */}
                          <Button
                          onClick={()=>{
                           handleSubmit()
                          }}
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Next
                          </Button>
                     
                      </Grid>
                    </Grid>

                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                      >
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
};


const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty,

});


export default connect(mapStateToProps)(PlotStep);
