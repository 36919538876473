import React, { useState , useEffect } from "react";
import Loader from "../Loader";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { store } from "../../redux/store";
import { connect } from "react-redux";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const CreateTask = ({ role, userName }) => {

  const navigate = useNavigate()

  

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
const token = getCookie("leadmanagement")
const [loading , setLoading] = useState(false)
  const [taskData, setTaskData] = useState({
    subject:"", priority: "", status: "", dueDate: "", description: "",createdAt:"" , assignTo:""
  })
  const handleSave = async () => {


    if(
      !taskData.subject
    ){
      return toast.error("subject must be required")
    }else if(
       !taskData.priority
    ){
      return toast.error("priority must be required")
    }else if(
      !taskData.status
    ){
      return toast.error("status must be required")
    }else if(
       !taskData.dueDate
    ){
      return toast.error("due date must be required")
    }else if(
       !taskData.description
    ){
      return toast.error("description must be required")
    }

    else if(
      !taskData.assignTo
   ){
     return toast.error("User Responsible is required")
   }

    try {
      setLoading(true)
      const response = await Api.create_Task(taskData,token );
      console.log("Response from createLead API:", response.data.message);
      if (response.data.message === "successfully create task") {
        setLoading(false)
        toast.success('Successfully Created');
        navigate("/tasks")
      }
      else {
        console.log("error")

      }

    } catch (error) {
      setLoading(false)
      console.error('Error during saving lead:', error);
    }
  };

  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true)
        let response;
        if (role === 'admin') {
          response = await Api.getAllUsers(token);
        } else if (role === 'manager') {
          response = await Api.getAllManagerAgent(token);
        } else if (role === 'agent') {
          response = await Api.getAllAgent(token);
        }
        
        setUsers(response.data.data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [role])

  const backToPage = () => {
    navigate("/tasks")
  }



  return (
    <>
     <Topbar />
      <Box m="20px" className="margin_in_mobile_ipad">
     

        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Task Information

</Typography>

<br /><br />
<TextField
  className="class_input"
  id="outlined-controlled"
  label="Subject"
  fullWidth
  multiline
  value={taskData.subject}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setTaskData({ ...taskData, subject: inputText });
    }
  }}
  variant="outlined"
/>
<br /><br />

<Box
  component="form"
  className="LMS-Info-section"
  sx={{
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "20px",
    width: "100%",
  }}
  noValidate
  autoComplete="off"
>
  {/* Priority */}
  <Grid>
    <div style={{ width: "100px" }}>Priority</div> {/* Fixed width for label */}
    <FormControl fullWidth>
      <Select
        sx={{ height: "40px", minWidth: "150px" }} // Fixed width for select box
        labelId="first-select-label"
        id="first-select"
        value={taskData.priority}
        onChange={(e) => setTaskData({ ...taskData, priority: e.target.value })}
      >
        <MenuItem value="low">Low</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="high">High</MenuItem>
        <MenuItem value="urgent">Urgent</MenuItem>
      </Select>
    </FormControl>
  </Grid>

  {/* User Responsible */}
  <Grid>
    <div style={{ width: "150px" }}>User Responsible</div> {/* Fixed width for label */}
    <FormControl fullWidth>
      <Select
        sx={{ height: "40px", minWidth: "200px" }} // Fixed width for select box
        labelId="third-select-label"
        id="third-select"
        value={taskData.assignTo}
        onChange={(e) => setTaskData({ ...taskData, assignTo: e.target.value })}
      > 
        {/* Dynamically render MenuItem for each user */}
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {`${user.firstName} ${user.lastName}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>

  {/* Status */}
  <Grid>
    <div style={{ width: "100px" }}>Status</div> {/* Fixed width for label */}
    <FormControl fullWidth>
      <Select
        sx={{ height: "40px", minWidth: "150px" }} // Fixed width for select box
        labelId="second-select-label"
        id="second-select"
        value={taskData.status}
        onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
      >
        <MenuItem value="new">New</MenuItem>
        <MenuItem value="processing">In discussion</MenuItem>
        <MenuItem value="confirm">Hired</MenuItem>
        <MenuItem value="cancel">Cold/Dead</MenuItem>
      </Select>
    </FormControl>
  </Grid>
</Box>


<br/><br/>
<TextField
  className="class_input"
  id="outlined-controlled"
  label="Description"
  fullWidth
  multiline
  value={taskData.description}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setTaskData({ ...taskData, description : inputText });
    }
  }}
  variant="outlined"
/>



</Box>

<Box       sx={{

// border: 'none',
boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
borderRadius: "10px"
, padding:"20px"
, width:"100%"

// "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//   color: `${colors.grey[100]} !important`,
// },
}} >
       <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>

Additional Information

</Typography>
<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">

{/* <TextField
  className="class_input"
  id="outlined-controlled"
  label="responsible"
  value={taskData.assignTo}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setTaskData({ ...taskData, assignTo: inputText });
    }
  }}
  variant="outlined"

  
/> */}

{/* <TextField
  className="class_input"
  id="outlined-controlled"
  label="Label"
  value={taskData.label}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setTaskData({ ...taskData, label: inputText });
    }
  }}
  variant="outlined"
/> */}





<Grid >
  <div>Start Date</div>
  <FormControl fullWidth >

  <TextField

type="date"
  className="class_input"
  id="outlined-controlled"
  label=""
  value={taskData.createdAt}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setTaskData({ ...taskData, createdAt: inputText });
    }
  }}
  variant="outlined"
/>
  </FormControl>
</Grid>
 




<Grid >
  <div>End Date</div>
  <FormControl fullWidth >

  <TextField
type="date"
  className="class_input"
  id="outlined-controlled"
  label=""
  value={taskData.dueDate}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setTaskData({ ...taskData, dueDate: inputText });
    }
  }}
  variant="outlined"
/>
  </FormControl>
</Grid>






</Box>


       </Box>

</div>

<br/>



            <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>
        
      
            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
         

        </div>
   
        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default  connect (mapStateToProps)(CreateTask);
