import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Typography
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Topbar from "../global/Topbar";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const options = [ "My Personal Reports" , "Shared Reports" , "All Reports" , "Task Reports" ,
 "Contact Reports" , "Company Reports" , "Lead Reports" , "Deal Reports" , "Project Reports " , "Other Reports"]






const Report = ({isLogging}) => {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleClick = (index) => {
    setSelectedOption(selectedOption === index ? null : index);
  };


  const [backgroundColor, setBackgroundColor] = useState('white');

  const changeBackgroundColor = (color) => {
    setBackgroundColor(color);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();

  
  useEffect(()=>{
    !isLogging && navigate('/')
  },[])

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "deal_name", headerName: "Deal Name", flex: 1 },
    { field: "company", headerName: "Company", flex: 1 },
    { field: 'pipeline', headerName: 'Pipeline', flex: 1 },
    { field: 'forecast_close_date', headerName: 'Forecast close Date', flex: 1 },
    { field: 'user_responsible', headerName: 'User Responsible ', flex: 1 },
    { field: 'deal_value', headerName: 'Deal Value ', flex: 1 },
    // { field: 'lead_created', headerName: 'Lead Created ', flex: 1 },
    // { field: 'lead_owner', headerName: 'Lead Owner ', flex: 1 },

    {
      field: 'blank',
      headerName: '',
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <img src="/img/star.png" alt="" />
      ),
    }

    ,

    ,{
      field: 'action',
      headerName: 'Action',
      flex: 1,
      width: 100, // Set the desired width
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },




    // { field: "location", headerName: "Location", flex: 1 },
    // { field: "type", headerName: "Type", flex: 1 },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id,params.row.type)}>
    //       Edit
    //     </Button>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id,params.row.type)}>
    //       Delete
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {



    const staticData = [
      { id: 1, sno: 1,deal_name: 'deal 1',  company: 'abc ' ,  user_responsible:'user1' ,forecast_close_date:"12/69/4567" , deal_value:"$326"  },
      { id: 2, sno: 2, deal_name: 'deal 2', company: 'xyz'  , user_responsible:'user2 ' ,forecast_close_date:"12/69/4567" , deal_value:"$78"  },
      
      // Add more tasks as needed
    ];
    setPlotData(staticData);

    // fetch(`${BACKEND_URL}/admin/featured-property`)
    //   .then((response) => response.json())        
    //   .then((data) => {
    //     // console.log(data);
    //     setPlotData(mapPlotData(data.data));
    //   })
    //   .catch((error) => console.error("Error fetching plot data:", error));
  },[ ]
  //  [isSnackbarOpen]
   ); // Add isSnackbarOpen as a dependency

  const mapPlotData = (data) => {
    return data.map((plot, index) => ({
      sno: index + 1,
      id: plot._id,
      title: plot.name || "No", 
      userName: plot.host[0].firstName || "No",
      location: plot.address.city || "No",
      type: plot.Sub_property_selectedItem,
    }));
  };

  return (
<>
    <Topbar/>
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Reports" subTitle="New Report" />


      <Box
   m="20px 0 0 0"
   height="75vh"
   sx={{
     "& .MuiDataGrid-root": {
   
       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
       borderRadius:"10px"
     },
 
   }}
 >
      <DataGrid components={{ Toolbar: GridToolbar }}
      //  checkboxSelection
        rows={ plotData} columns={columns} />

 </Box>




    

      {/* Delete Confirmation Dialog */}
      {/* <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error"> 
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Success Snackbar */}
                                            {/* <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" /> */}
    </Box>
    </>
  );
};

export default Report;
