import React, { useEffect, useState } from "react";
// import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { store } from "../../redux/store";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../Api";
import { connect } from "react-redux";

import { setCookie } from "../../components/Cookies";
import { useDispatch } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LoginPage = ({isLogging ,role}) => {

console.log("dghdthutdy",role)
  
  const Navigate = useNavigate();
const dispatch = useDispatch()

  const setUserDataa = (data) => {
    store.dispatch({
      type: "User_Data",
      payload: data,
      
    });
  };
 
  useEffect(()=>{
     if(isLogging){
    return role==="admin" ?Navigate("/admin-dashboard"): Navigate("/manager-dashboard")
     }
  },[isLogging, role])

  const [loading, setLoading] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:500px)");

  const [userData, setUserData] = useState({ emailORMobile: "", password: "" });

  const handleLogin = async () => {

    if (userData.emailORMobile === "" ) {
      return toast.error("Fill email or mobile number", {
          autoClose: 2000,
      });
  }
  
    if (userData.password === "") {
      return toast.error("fill password", {
        autoClose: 2000,
      });
    }
    try {
      setLoading(true);
      const response = await Api.login(userData)

      if(response.data.message === "Login successfully"){
        console.log(response)
        // setUserData(response.data)
        toast.success('Login successfully');
        setCookie("leadmanagement", response.data.accesosTken, 7);
        setLoading(false);
        setUserDataa({
          isLogging: true,
        });
        // localStorage.setItem("login", true)
        {role ==="admin"?  Navigate("/admin-dashboard"):  Navigate("/manager-dashboard")}
      ;
        
      }
      
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message );
      console.error('Error during login:', error);

  
   
    } 
  };

    // setLoading(true);
    // await axios
    //   .post(`${BACKEND_URL}/admin/login`, userData)
    //   .then((res) => {
    //     if (res.data.message === "Login successfully") {
    //       localStorage.setItem('loginAdmin', true);
    //       naviagte("/dashboard");
    //       console.log(res);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     console.log(error);
    //     return toast.error(error.response.data.message, {
    //       autoClose: 2000,
    //     });
    //   });
      
    // console.log( "jggfjgn" ,userData);
  

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{  width: isNonMobile ? "60%" : "90%" }}>
          <h1>Login</h1>

          <div>
            <h3>User name</h3>

            <input
              name="groupTwentyFive"
              placeholder="Enter your Email/Phone"
              className=" "
              value={userData.emailORMobile}

              onChange={(e) =>
                setUserData({ ...userData, emailORMobile : e.target.value })
              }
              style={{
                border: "2px solid grey",
                height: "45px",
                borderRadius: "10px",
                padding: "10px",
                width: "100%",
              }}
            />
          </div>

          <div>
            <h3>password</h3>

            <input
              name="groupTwentyFive"
              placeholder="Enter your Password"
              type="password"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
              style={{
                border: "2px solid grey",
                height: "45px",
                borderRadius: "10px",
                padding: "10px",
                width: "100%",
              }}
            />
          </div>

          <div style={{ marginTop: "30px" }}>
            <button
              disabled={loading}
              name="groupTwentyFive"
              placeholder="Enter your username"
              onClick={() => handleLogin()}
              style={{
                backgroundColor: "#006093",
                color: "white",
                border: "2px solid grey",
                height: "45px",
                borderRadius: "10px",
                width: "100px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                cursor:"pointer"
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};
const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});


export default connect(mapStateToProps)(LoginPage) ;
