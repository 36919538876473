// actions.js
export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

export const clearHostProperty = () => ({
  type: "clear_host_property",
});



export const loginSuccess = () => ({
  type: 'LOGIN_SUCCESS',
});

export const openMenu = () => ({
  type: "OPEN_MENU"
});

export const closeMenu = () => ({
  type: "CLOSE_MENU"
});
