import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import {  Typography, Button } from '@mui/material';
import Api from "../../Api";
import { useNavigate, } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import Topbar from "../global/Topbar";




const AgentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const [leadData, setLeadData] = useState(null)

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {

    try {
      const response = await Api.getAgentById(id);
      console.log(response.data.data)
      setLeadData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }




  const backToPage = () => {
    navigate("/leads")
  }


  return (
    <>


      <Topbar />
      {
        leadData && (
          <>

            <Box m="20px" className="margin_in_mobile_ipad">

              <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">
              <Button sx={{marginBottom:"10px",
                      backgroundColor: "#9B56FF", color: "white", '&:hover': {
                        backgroundColor: '#ffffff',
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF"
                      }
                    }}

                      onClick={backToPage}> Back</Button>
                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}  >

                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Contact Information

                    </Typography>


                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto   "
                      }}
                      noValidate
                      autoComplete="off"
                    >

                      <h3>
                        {" "}
                        <strong>First Name :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.firstName || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong>Last Name :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.lastName || "No"}
                        </span>
                      </h3>



                      <h3>
                        {" "}
                        <strong>Email :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.email || "No"}
                        </span>
                      </h3>

                      <h3>
                        {" "}
                        <strong>Phone :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.mobileNumber || "No"}
                        </span>
                      </h3>


                    </Box></Box>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}    >
                    <Typography

                      variant="h4"

                      fontWeight="550"

                      sx={{ m: "0 0 5px 0", }}
                      className="heading_animation"
                    >
                      Additional Information

                    </Typography>


                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto   "
                      }}
                      noValidate
                      autoComplete="off">


                      <h3>
                        {" "}
                        <strong>Position :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.position || "No"}
                        </span>
                      </h3>


                      <h3>
                        {" "}
                        <strong>Department :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {leadData.department || "No"}
                        </span>
                      </h3>

                    </Box>
                  </Box>

                </div>
              </div>
              <ToastContainer />
            </Box>

          </>)
      }

    </>

  );
};

export default AgentDetails;
