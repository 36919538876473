
import "../Loader/index.css"
import useMediaQuery from "@mui/material/useMediaQuery";
const Loader = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  return (
    isNonMobile ? (
    
      <div style={{ zIndex: "1000", position: "fixed", top: "0px", backdropFilter: "blur(7px)", height: "100vh", overflow: "hidden" }}>
        <main className="main">
          <img src="/img/LoaderLogo.gif" alt="Loader" style={{width:"15%"}}/>
        </main>
      </div>
    ) : (
    
      <div style={{ zIndex: "1000", position: "fixed", top: "0px", backdropFilter: "blur(7px)", height: "100vh", overflow: "hidden" }}>
        <main className="main">
          <img src="/img/LoaderLogo.gif" alt="Loader" style={{width:"45%"}}/>
        </main>
      </div>
    )
  );
};

export default Loader;

  