import React from 'react';
import Topbar from "../global/Topbar";


const GoogleCalendar = () => {
  return (
   <>
        <Topbar />

        <div>
 
 <iframe
   src="https://calendar.google.com/calendar/u/0/embed?src=912520d7d6319744ab4ed7db417d1ae5126a6589d376009a967ca04445025878@group.calendar.google.com&ctz=Asia/Kolkata"
   style={{ border: '0', width: '100%', height: '600px', frameborder: '0', scrolling: 'no' }}
   title="My Google Calendar"
 ></iframe>
</div>
        
   </>
  );
};

export default GoogleCalendar;
