import React, { useState , useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { store } from "../../redux/store";
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";




// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const CreateLead = ({ role, userName }) => {

  const navigate = useNavigate()


  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
const token = getCookie("leadmanagement")
const [fileUpload , setFileUpload] = useState("")
  const [leadData, setLeadData] = useState({
    firstName: "", lastName: "", email: "", phoneNumber: "", alternateNumber:"",
    city: "", state: "", street: "", country: "", zipCode: "",
    industry: "", owner: "", website: "", employNumber: "", leadSource: "",
    leadStatus: "", listingUrl:"",listingAddress:"",hostName:"",
    hostNumber:"",hostMail:"",title: "", description: "",assignTo:""
  })


  const handleUploadCsv = async() =>{

    if (!fileUpload) {
      return toast.error("No file selected");
    }

    if (fileUpload.type !== "text/csv") {
      return toast.error("Please choose a CSV file");
    }
  
    const formData = new FormData();
    formData.append("csvfile", fileUpload);



try {

   const response = await Api.uploadCsvToJson(formData, token);
    console.log(response.data.data);
    setFileUpload(response.data.data);
    return toast.success("File uploaded successfully");
  
} catch (error) {
  console.log(error)
}


  }





  const handleSave = async () => {


    if(
      !leadData.firstName
    ){
      return toast.error("first name must be required")
    }else if(
      !leadData.lastName
    ){
      return toast.error("last name must be required")
    }else if(
      !leadData.phoneNumber
    ){
      return toast.error("phone number must be required")
    }else if(
      !leadData.leadStatus
    ){
      return toast.error("lead Status must be required")
    }else if(
      !leadData.leadSource
    ){
      return toast.error("lead Source must be required")
    }
    else if(
      !leadData.assignTo
   ){
     return toast.error("User Responsible is required")
   }
    else if(
      !leadData.title
    ){
      return toast.error("title must be required")
    }else if(
      !leadData.description
    ){
      return toast.error("description must be required")
    }

    






    try {
      const response = await Api.createLead(leadData,token );
      console.log("Response from createLead API:", response.data.message);
      if (response.data.message === "successfully create") {
        toast.success('Successfully Created');
        navigate("/leads")
      }
      else {
        console.log("error")

      }


    } catch (error) {
      console.error('Error during saving lead:', error);
    }
  };




  
  const [users, setUsers] = useState([]);

  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let response;
        if (role === 'admin') {
          response = await Api.getAllUsers(token);
        } else if (role === 'manager') {
          response = await Api.getAllManagerAgent(token);
        } else if (role === 'agent') {
          response = await Api.getAllAgent(token);
        }
        
        setUsers(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [role]); // Depend on userRole to re-fetch users when it changes
  


  const backToPage = () => {
    navigate("/leads")
  }










  return (
    <>
     <Topbar />
      <Box m="20px" className="margin_in_mobile_ipad">
     


   
     <input
      type="file"
      onChange={(e) => setFileUpload(e.target.files[0])}
    />
    
    <Button
      sx={{
        color: "#9B56FF",
        border: "1px solid #9B56FF",
        '&:hover': {
          backgroundColor: '#ffffff',
          color: "#9B56FF",
          border: "1px solid #9B56FF"
        }
      }}
      onClick={handleUploadCsv}
    >
      Upload CSV File
    </Button>
    

     

  


        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Lead Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, firstName: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, lastName: inputText });
    }
  }}
  variant="outlined"
/>



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}



<TextField
  className="class_input"
  id="outlined-controlled"
  label="Email"
  value={leadData.email}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 35) {
      setLeadData({ ...leadData, email: inputText });
    }
  }}
  variant="outlined"
/>


{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Email"
  value={leadData.email}
  onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}




<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Phone"
  value={leadData.phoneNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, phoneNumber: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Phone"
  value={leadData.phoneNumber}
  onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}


<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Alternate Number"
  placeholder="Optional"
  value={leadData.alternateNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, alternateNumber: inputText });
    }
  }}
  variant="outlined"
/>
{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Listing Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>


<TextField
  className="class_input"
  id="outlined-controlled"
  label="Listing Url"
  value={leadData.listingUrl}
  onChange={(e) => {
    const inputText = e.target.value;
   
      setLeadData({ ...leadData, listingUrl: inputText });
    
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Listing Address"
  value={leadData.listingAddress}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, listingAddress: inputText });
    }
  }}
  variant="outlined"
/>






<TextField
  className="class_input"
  id="outlined-controlled"
  label="Host Name"
  value={leadData.hostName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 35) {
      setLeadData({ ...leadData, hostName: inputText });
    }
  }}
  variant="outlined"
/>






<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Host Number"
  value={leadData.hostNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, hostNumber: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
type="text"
  className="class_input"
  id="outlined-controlled"
  label="Host Mail"
  value={leadData.hostMail}
  onChange={(e) => {
    const inputText = e.target.value;
      setLeadData({ ...leadData, hostMail: inputText });
  }}
  variant="outlined"
/>
{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>



<Box       sx={{

// border: 'none',
boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
borderRadius: "10px"
, padding:"20px"
, width:"100%"

// "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//   color: `${colors.grey[100]} !important`,
// },
}} >
       <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Address Information

</Typography>
<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">
<TextField
  className="class_input"
  id="outlined-controlled"
  label="Street"
  value={leadData.street}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 40) {
      setLeadData({ ...leadData, street: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Street"
  value={leadData.street}
  onChange={(e) => setLeadData({ ...leadData, street: e.target.value })}
  sx={{ paddingTop: "" }}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="City"
  value={leadData.city}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, city: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Postal Code"
  value={leadData.zipCode}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, zipCode: inputText });
    }
  }}
  variant="outlined"
/>
{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="City"
  value={leadData.city}
  onChange={(e) => setLeadData({ ...leadData, city: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="State"
  value={leadData.state}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, state: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="State"
  value={leadData.state}
  onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}
/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Country"
  value={leadData.country}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, country: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Country"
  value={leadData.country}
  onChange={(e) => setLeadData({ ...leadData, country: e.target.value })}
/> */}





{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Postal Code"
  value={leadData.zipCode}
  onChange={(e) => setLeadData({ ...leadData, zipCode: e.target.value })}
/> */}

</Box>










       </Box>

</div>


      


        



      

          <br /> 
          <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

          <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}   >
        <Typography
        

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Company Information

</Typography>
<br /><br />

<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
}}
noValidate
autoComplete="off">

<TextField

  className="class_input"
  id="outlined-controlled"
  label="Company Name"
  value={leadData.industry}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, industry: inputText });
    }
  }}
  variant="outlined"
/>

<TextField

  className="class_input"
  id="outlined-controlled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, owner: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Owner"
  value={leadData.owner}
  onChange={(e) => setLeadData({ ...leadData, owner: e.target.value })}
/> */}

<TextField

  className="class_input"
  id="outlined-controlled"
  label="WebSite"
  value={leadData.website}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 20) {
      setLeadData({ ...leadData, website: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="WebSite"
  value={leadData.website}
  onChange={(e) => setLeadData({ ...leadData, website: e.target.value })}
/> */}


{/* <TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Number of Employees"
  value={leadData.employNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 3) {
      setLeadData({ ...leadData, employNumber: inputText });
    }
  }}
  variant="outlined"
/> */}


<Grid sx={{ marginTop: "-15px" }}>
  <div>Number of Employees</div>
  <FormControl fullWidth>
    <Select
      sx={{ height: "40px", minWidth: "180px" }} // Added minWidth for fixed width
      labelId="second-select-label"
      id="second-select"
      fullWidth
      value={leadData.employNumber}
      onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
    >
      <MenuItem value="1-9employees">1-9 employees</MenuItem>
      <MenuItem value="10-50employees">10-50 employees</MenuItem>
      <MenuItem value="50-200employees">50-200 employees</MenuItem>
      <MenuItem value="200+">more than 200 employees</MenuItem>
    </Select>
  </FormControl>
</Grid>







{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Number of Employees"
  value={leadData.employNumber}
  onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
/> */}



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Lead Source"
  value={leadData.leadSource}
  onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
/> */}

</Box>
        </Box>

        

        <Box sx={{
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  padding: "20px",
  width: "100%"
}}>
  <Typography variant="h4" fontWeight="550" sx={{ m: "0 0 5px 0", marginTop: "-8px" }} className="heading_animation">
    Additional Information
  </Typography>
  <br /><br />
  <Box component="form" className="LMS-Info-section"
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", // Adjusted to minmax(200px, 1fr)
      gap: "20px"
    }}
    noValidate
    autoComplete="off">

    <Grid>
      <div>Lead Status</div>
      <FormControl fullWidth>
        <Select
          sx={{ height: "40px" }}
          labelId="second-select-label"
          id="second-select"
          fullWidth
          value={leadData.leadStatus}
          onChange={(e) => setLeadData({ ...leadData, leadStatus: e.target.value })}
        >
          <MenuItem value="new">New</MenuItem>
          <MenuItem value="processing">In discussion</MenuItem>
          <MenuItem value="confirm">Hired</MenuItem>
          <MenuItem value="cancel">Cold/Dead</MenuItem>
        </Select>
      </FormControl>
    </Grid>

    <Grid>
      <div>Lead Source</div>
      <FormControl fullWidth>
        <Select
          sx={{ height: "40px" }}
          labelId="first-select-label"
          id="first-select"
          fullWidth
          defaultValue=""
          value={leadData.leadSource}
          onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
        >
          <MenuItem value="facebook">Facebook</MenuItem>
          <MenuItem value="instagram">Instagram</MenuItem>
          <MenuItem value="whatsapp">Whatsapp </MenuItem>
          <MenuItem value="linkedin">LinkedIn </MenuItem> {/* Corrected spelling */}
          <MenuItem value="googleAds">Google Ads </MenuItem>
          <MenuItem value="websites">Websites </MenuItem>
          <MenuItem value="manual">Manual </MenuItem>

        </Select>
      </FormControl>
    </Grid>

    <Grid>
      <div>User Responsible</div>
      <FormControl fullWidth>
        <Select
          sx={{ height: "40px" }}
          labelId="third-select-label"
          id="third-select"
          fullWidth
          defaultValue=""
          value={leadData.assignTo}
          onChange={(e) => setLeadData({ ...leadData, assignTo: e.target.value })}
        >
          {users.map((user) => (
            <MenuItem key={user._id} value={user._id}>
              {`${user.firstName} ${user.lastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>

  </Box>
</Box>



            </div>
         

      

          <br />


         

      

              
          <div className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

                
           <Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}} >
           <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Title

</Typography>

<br />
<br/>

{/* <TextField
className="class_input"
id="second-text-field"
label="Title"
fullWidth

value={leadData.title}
onChange={(e) =>
  setLeadData({ ...leadData, title: e.target.value })
}
/> */}
<TextField
                        label="Title"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.title}
                        multiline

                        onChange={(e) => {
                          const inputText = e.target.value;
                          const words = inputText.trim().split(/\s+/);
                          if (words.length <= 100) { 
                            setLeadData({ ...leadData, title: inputText });
                          }
                        }}
                        // onChange={(e) =>
                        //   setLeadData({ ...leadData, title: e.target.value })
                        // }

                        style={{width:"100%"}}
                   
                      />
           </Box>


             
                
             <Box    sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}>   <Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Description

</Typography>
<br />
<br/>
<Box
component="form" 

noValidate
autoComplete="off"
>

<TextField
                        label="Description"
                 
                        type="text"
                 
                        id="second-text-field"
                        value={leadData.description}
                        multiline

                        onChange={(e) => {
                          const inputText = e.target.value;
                          const words = inputText.trim().split(/\s+/);
                          if (words.length <= 500) { 
                            setLeadData({ ...leadData, description: inputText });
                          }
                        }}
                        // onChange={(e) =>
                        //   setLeadData({ ...leadData, description: e.target.value })
                        // }

                        style={{width:"100%"}}
                   
                      />

</Box></Box>
            </div>


<br/>



            <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>
        
      
            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
         

        </div>
   

        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default  connect(mapStateToProps)(CreateLead);
