import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../components/Cookies";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Topbar from "../global/Topbar";
import Swal from 'sweetalert2'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";



const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const Notes = ({isLogging}) => {

    const [leadData, setLeadData] = useState({
        text: ""
      })
      const [loading , setLoading] = useState(false)

  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);




  const openPopup = () => {
    Swal.fire({
      title: "Add Note",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        // Return the note value here
        return note;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'swal-button',
        cancelButton: 'swal-button'
      },
      buttonsStyling: false,
      padding: '2rem',
  
      background: '#fff',
      backdrop: 'rgba(0, 0, 0, 0.4)',
   
   
  
      html: `
        <style>
          .swal-button {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }
        </style>
      `
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the "Save" button is clicked
        try {
          setLoading(true)
          const response = await Api.createTextNote({ text: result.value } , token);
          setIsDelete(!isDelete)
          console.log("Response from createTextNote API:", response.data.message);
          if (response.data.message === "successfully created note") {
            setLoading(false)
            // Show success message and handle redirection
            Swal.fire('Note Saved!', '', 'success');
            navigate("/notes");
          } else {
            console.log("error");
          }
        } catch (error) {
          setLoading(false)
          console.error('Error during saving note:', error);
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      }
    });
  }
  
  
  
  
  







  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
const [lead ,setLead] =useState([])
const [selectedUserId, setSelectedUserId] = useState(null);
const [isDelete, setIsDelete] = useState(false);

const token = getCookie("leadmanagement")
useEffect(()=>{
fetchData()
},[isDelete])
  
const fetchData =async ()=>{

  try {
    setLoading(true)
    const response =await Api.getNotesByUser(token)
    if(response.data && Array.isArray(response.data.data)){
      setLead(mapNoteData(response.data.data));
      setLoading(false)
      console.log(response.data.data);
      
    }else{
      console.error('Data is not an array or is missing:', response.data.data);
    }
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
}
  

  useEffect(()=>{
    !isLogging && navigate('/')
  },[])



  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      await Api.deleteNote(selectedUserId , token);
      setLoading(false)
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };



  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "note", headerName: "Note", width: 300 },


      {
        field: 'delete_note',
        headerName: 'Delete Note'
        , width: 100 ,
        renderCell: (params) => (
        <button 
        onClick={() => handleDeleteClick(params.row.id)}
         ><DeleteForeverOutlinedIcon/></button>
        ),
  
      
      },

     

  ];







  const mapNoteData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id ||index  ,
      note: d.text || "No",
     

    }));
  };

  return (
  <>
       <Topbar />

       <Box m="20px" className="margin_in_mobile_ipad" >
 

 <Header title="Notes" subTitle="Add Note" openPopup={openPopup} />
 <Box
   m="20px 0 0 0"
   height="75vh"
   sx={{
     "& .MuiDataGrid-root": {
   
       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
       borderRadius:"10px"
     },
 
   }}
 >
   <DataGrid
     components={{ Toolbar: GridToolbar }}
    //  checkboxSelection
     rows={lead}
     // rows={plotData}

     columns={columns}
   />
 </Box>


     {/* Delete Confirmation Dialog */}
     <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
       <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent>
         Are you sure you want to delete this Lead?
       </DialogContent>
       <DialogActions>
         <Button onClick={handleDeleteCancel} color="primary">
           Cancel
         </Button>
         <Button onClick={handleDeleteConfirm} color="error">
           Delete
         </Button>
       </DialogActions>
     </Dialog>

     {loading && <Loader />}
     </Box>
  </>
  );
};

export default Notes;
