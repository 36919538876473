import React, { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Loader";


// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const TaskDetails = ({ role, userName }) => {

  const navigate = useNavigate()
  const { id } = useParams();


  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox
  const [loading, setLoading] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
  const token = getCookie("leadmanagement")

  const [taskData, setTaskData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await Api.get_Task_By_Id(id, token);
      console.log(response.data.data)
      setTaskData(response.data.data)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }

  }



  const [users, setUsers] = useState([]);


  useEffect(() => {

    const fetchUsers = async () => {
      try {
        setLoading(true)
        let response;
        if (role === 'admin') {
          response = await Api.getAllUsers(token);
        } else if (role === 'manager') {
          response = await Api.getAllManagerAgent(token);
        } else if (role === 'agent') {
          response = await Api.getAllAgent(token);
        }

        setUsers(response.data.data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [role])

  const backToPage = () => {
    navigate("/tasks")
  }

  return (
    <>
      <Topbar />

      {
        taskData && (
          <>
            <Box m="20px" className="margin_in_mobile_ipad"

              sx={{

                // border: 'none',



                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                // borderRadius: "10px"

                // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                //   color: `${colors.grey[100]} !important`,
                // },
              }}

            >

              <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 20px", alignItems: "center", backgroundColor: "white", zIndex: "9999999", borderRadius: "10px" }}>

                <div style={{ display: "flex", padding: "20px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>



                    <Button sx={{
                      backgroundColor: "#9B56FF", color: "white", '&:hover': {
                        backgroundColor: '#ffffff',
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF"
                      }
                    }}

                      onClick={backToPage}> Back</Button>

                  </div>
                </div>

              </div>

              <hr />



              <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}  >

                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Task Information

                    </Typography>

              

                      <h3>
                          {" "}
                          <strong> Subject :</strong>{" "}
                          <span style={{ fontWeight: "600" }}>
                            {taskData.subject || "No"}
                          </span>
                        </h3>



                  
                    

                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto   "
                      }}
                      noValidate
                      autoComplete="off"
                    >
                    
                    <h3>
                          {" "}
                          <strong> Priority :</strong>{" "}
                          <span style={{ fontWeight: "600" }}>
                            {taskData.priority || "No"}
                          </span>
                        </h3>


                        <h3>
                        <strong>User Responsible :</strong>{" "}
                        <span style={{ fontWeight: "600" }}>
                          {taskData?.assignTo?.firstName && taskData?.assignTo?.lastName
                            ? `${taskData.assignTo.firstName} ${taskData.assignTo.lastName}`
                            : "No"}
                        </span>
                      </h3>

                      <h3>
                          {" "}
                          <strong> Status :</strong>{" "}
                          <span style={{ fontWeight: "600" }}>
                            {taskData.status || "No"}
                          </span>
                        </h3>

                      


                      

                  
                    </Box>
                   

                  
                       


                  </Box>

                  <Box sx={{

                   
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"

                    
                  }} >
                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >

                      Additional Information

                    </Typography>
                    




                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        gridTemplateColumns: "auto auto  "
                      }}
                      noValidate
                      autoComplete="off">

<h3>
                          {" "}
                          <strong> Description :</strong>{" "}
                          <span style={{ fontWeight: "600" }}>
                            {taskData.description || "No"}
                          </span>
                        </h3>
                  



<h3 >
                          {" "}
                          <strong> End Date :</strong>{" "}
                          <span style={{ fontWeight: "600" }}>
                            {taskData.dueDate || "No"}
                          </span>
                        </h3>






                    </Box>


                  </Box>

                </div>

                <br />

                {loading && <Loader />}




              </div>
              <ToastContainer />
            </Box>
          </>)
      }

    </>


  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(TaskDetails);
