import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
// import LandslideIcon from '@mui/icons-material/Landslide';
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
// import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { DialogTitle, Dialog, DialogContent, DialogActions } from "@mui/material";
import { PieChart } from '@mui/x-charts/PieChart';
import { NavLink, useNavigate } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Topbar from "../global/Topbar";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import Api from "../../Api";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;;
import { getCookie } from "../../components/Cookies";
import { render } from "react-dom";
import Swal from "sweetalert2";







const mData = [4000, 3000, 2000, 2780];
const nData = [4500, 4000, 2300, 3908];

const mLabels = [
  "o",
  '100',
  '150',
  '200',

];

const rData = [100, 75, 50, 100];
const sData = [90, 65, 40, 90];
const zLabels = [
  "2006",
  "2010",
  "2014",
  "2018"
];



const aData = [2478, 5267, 734, 784, 433, 2390, 2090];

const yLabels = [
  "2006",
  "2010",
  "2011",
  "2012",
  "2018",
  "2020",
  "2022",
];

const uData = [, 60, 50, 60, 50, 60, 50];
const pData = [, 50, 40, 50, 40, 50, 40, 60];


const xLabels = [
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
];

const chartSetting = {
  xAxis: [
  ],
  width: 400,
  height: 300,
};
const dataset = [

  {
    london: 2300,
    paris: 2408,
    newYork: 2250,
    seoul: 2478,
    year: 2000,
  },
  {
    london: 5000,
    paris: 5100,
    newYork: 5200,
    seoul: 5267,
    year: 2010,
  },
  {
    london: 7084,
    paris: 7300,
    newYork: 7342,
    seoul: 734,
    year: 2011
  },
  {
    london: 4784,
    paris: 2784,
    newYork: 6784,
    seoul: 784,
    year: 2015
  },
  {
    london: 6433,
    paris: 4433,
    newYork: 9433,
    seoul: 433,
    year: 2020
  },





];

const valueFormatter = (value) => `${value}`;



const Dashboard = ({ isLogging }, params) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [manager, setManager] = useState("")
  const [agent, setAgent] = useState("")
  const [totalLeads, setTotalLeads] = useState("")
  const [assignLeads, setAssignLeads] = useState("")
  const [source, setSource] = useState("")
  const [todayLeads , setTodayLeads] = useState("")

  const navigate = useNavigate()
  const counts = [
    { id: 1, count: totalLeads, description: "Total Leads", image: "/img/icon4.png" },
    { id: 2, count: assignLeads, description: "Assigned Leads", image: "/img/greenimage.png" },
    { id: 3, count: manager, description: "Total Manager", image: "/img/redimage.png" },
    { id: 4, count: agent, description: "Total Agents", image: "/img/blueimage.png" },

    // Add more data objects as needed
  ];

  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const token = getCookie("leadmanagement")
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([])
  useEffect(() => {
    if (isLogging) {
      fetchData(); // Fetch data when the component mounts
      handleManager()
      handleAgent()
      handleAllLead()
      handleAllAssignLead()
      handleSource()
      handleTodayLeads()
    }
  }, [isLogging, allNotes, isDelete, manager, agent, totalLeads, assignLeads,]);







  const openPopup = () => {
    Swal.fire({
      title: "Add Note",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        // Return the note value here
        return note;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'swal-button',
        cancelButton: 'swal-button'
      },
      buttonsStyling: false,
      padding: '2rem',
 
      background: '#fff',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      confirmButtonColor: '#4caf50',
      confirmButtonTextColor: '#fff',
      cancelButtonColor: '#f44336',
      cancelButtonTextColor: '#fff',
      html: `
        <style>
          .swal-button {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }
        </style>
      `
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the "Save" button is clicked
        try {
          // Your asynchronous code for saving the note
          const response = await Api.createTextNote({ text: result.value }, token);
          setIsDelete(!isDelete)
          console.log("Response from createTextNote API:", response.data.message);
          if (response.data.message === "successfully created note") {

            // Show success message and handle redirection
            setAllNotes(!allNotes)
            Swal.fire('Note Saved!', '', 'success');
            navigate("/admin-dashboard");
          } else {
            console.log("error");
          }
        } catch (error) {
          console.error('Error during saving note:', error);
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      }
    });
  }

const handleTodayLeads = async ()=>{
try {
  
  const response = await Api.today_Leads(token)
  console.log("today leads", response.data);
  setTodayLeads(response.data)
} catch (error) {
  console.error('Error fetching data:', error);
}

}



  const handleSource = async () => {

    try {
      const response = await Api.get_Lead_Status_source_Admin(token)
      console.log(response.data)
      setSource(response.data)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAllAssignLead = async () => {
    try {
      const response = await Api.assign_Leads(token)

      // console.log("total leads",response)
      setAssignLeads(response.data.count)


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAllLead = async () => {
    try {
      const response = await Api.total_Leads(token)

      // console.log("total leads",response)
      setTotalLeads(response.data.count)


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleAgent = async () => {

    try {
      const response = await Api.get_Manager_Agent(token)
      // console.log("agent", response.data.count)
      setAgent(response.data.count)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }




  const handleManager = async () => {

    try {
      const response = await Api.getAllManager(token)
      // console.log("dzvgjkdh", response.data.count)
      setManager(response.data.count)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const fetchData = async () => {
    try {
      const response = await Api.getNotesByUser(token);
      if (response.data && Array.isArray(response.data.data)) {
        setNotes(response.data.data);
      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Api.deleteNote(selectedUserId, token);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);

    } catch (error) {

      console.error("Error deleting user:", error);
    }
  };






  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad" >


        {/* <Header title=" Deal Dashboard" subtitle="Welcome to your dashboard" /> */}

        <Box>

          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", gap: "20px", }} className="totalInputsOfDash">
            {counts.map((data, index) => (
              <div key={data.id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", padding: "10px", borderRadius: "10px", }}>
                <img style={{ height: "40px", width: "40px" }} src={data.image} alt="" />
                <div style={{ textAlign: "end" }}  >
                  <p><strong>{data.count}</strong></p>
                  <p>{data.description}</p>
                </div>
              </div>
            ))}




          </div>
        </Box>


        <br />


        <Box >


<div style={{ display: "flex", justifyContent: "space-between" }}>

  <Typography variant="h3" gutterBottom>
     Sticky Notes
  </Typography>



  <Button onClick={openPopup} sx={{ backgroundColor: "#9B56FF", color: "#fff", height: "40px", ':hover': { backgroundColor: "#9B56FF", color: "#fff" } }}>Add Notes</Button>

</div>

<br />

<Box display="flex" flexWrap="wrap" style={{  gap:"20px"}}>
  {notes.map((note, index) => (
    <Card key={index} sx={{  padding: "10px", backgroundColor: "#28c76f", color: "white" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <CloseOutlinedIcon style={{ color: "red", fontSize: "10px" , cursor: "pointer" }}
          onClick={() => handleDeleteClick(note._id)}
        />
      </div>

      <CardContent>

        <Typography variant="body2">
          {note.text}
        </Typography>
      </CardContent>
    </Card>
  ))}
</Box>
</Box>

<br />

        <Box



        >

          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: "20px" }} className='invoicesalign'  >

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Lead Sources </h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value: source.facebook, label: `Facebook ${source.facebookLeads}`,  color: "#0f4a96" },
                        { id: 1, value: source.instagram , label: `Instagram ${source.instagramLeads}`, color: "#c255bb" },
                        { id: 2, value: source.whatsapp, label: `WhatsApp ${source.whatsappLeads}`, color: "#416634" },
                        { id: 3, value: source.linkedin, label: `Linkdin ${source.linkedinLeads}`, color: "#9973d1" },
                        { id: 4, value: source.google, label: `Google ${source.googleLeads}`, color: "#ccffcc" },
                        { id: 5 ,value: source.website, label: `Website ${source.websitesLeads}`, color: "#d9ff66" },


                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>


            </div>







            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Leads Status</h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value:source.newPercentage, label: `New  ${source.newLeads}`, color: "rgb(5, 102, 129)" },
                        { id: 1, value: source.processingPercentage, label: `In Discussion  ${source.processingLeads}`, color: "rgb(58, 50, 133)" },
                        { id: 2, value: source.confirmPercentage, label: `Hired  ${source.confirmLeads}`, color: "rgb(72, 110, 3)" },
                        { id: 3, value: source.cancelPercentage, label: `Cold/Dead  ${source.cancelLeads}`, color: "rgb(230, 0, 35)" },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>


            </div>












            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Today Leads</h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value: todayLeads.facebook, label: `Facebook ${todayLeads.facebookLeads}`,  color: "#0f4a96" },
                        { id: 1, value: todayLeads.instagram , label: `Instagram ${todayLeads.instagramLeads}`, color: "#c255bb" },
                        { id: 2, value: todayLeads.whatsapp, label: `WhatsApp ${todayLeads.whatsappLeads}`, color: "#416634" },
                        { id: 3, value: todayLeads.linkedin, label: `Linkdin ${todayLeads.linkedinLeads}`, color: "#9973d1" },
                        { id: 4, value: todayLeads.google, label: `Google ${todayLeads.googleLeads}`, color: "#ccffcc" },
                        { id: 5 ,value: todayLeads.website, label: `Website ${todayLeads.websitesLeads}`, color: "#d9ff66" },



                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>


            </div>

          </div>
          <br />







        </Box>
        <br />

     

     




        {/* <Box



        >

          <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "20px" }} className='invoicesalign' >

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px", display: "flex" }}>Sales Overview</h2>

              <LineChart
                width={400}
                height={300}
                series={[
                  { data: pData },
                  { data: uData },
                ]}
                xAxis={[{ data: xLabels }]}
              />



            </div>





            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}       >
              <h2 style={{ marginLeft: "20px", display: "flex" }}>Total Sales</h2>

              <LineChart
                xAxis={[{ data: [0, 2, 3, 5, 8, 10] }]}

                series={[
                  {
                    data: [0, 30, 60, 90, 120, 150],
                  },
                ]}
                width={400}
                height={300}
              />


            </div>


          </div>

        </Box>
        <br />

        <Box


        >

          <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "20px" }} className='invoicesalign' >

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px", display: "flex" }}>Yearly Project</h2>

              <BarChart
                width={400}
                height={300}
                series={[
                  { data: aData, id: 'pvId' },

                ]}
                Axis={[{ data: yLabels, scaleType: 'band' }]}
              />

            </div>





            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}       >

              <h2 style={{ marginLeft: "20px", display: "flex" }}>Total Revenue</h2>

              <BarChart
                width={400}
                height={300}
                series={[
                  { data: rData, id: 'pvId' },
                  { data: sData, id: 'uvId' },
                ]}
                xAxis={[{ data: zLabels, scaleType: 'band' }]}
              />

            </div>



          </div>

        </Box>
        <br />

        <Box



        >

          <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "20px" }} className='invoicesalign' >

            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px", display: "flex" }}> Sales Statistics</h2>


              <BarChart
                width={400}
                height={300}
                series={[
                  { data: mData, label: 'Total Cost', id: 'pvId' },
                  { data: nData, label: 'Total Revenue', id: 'uvId' },
                ]}
                xAxis={[{ data: mLabels, scaleType: 'band' }]}



              />


            </div>






            <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}       >
              <h2 style={{ marginLeft: "20px", display: "flex" }}>Completed Tasks</h2>

              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}

                series={[
                  {
                    data: [0, 30, 60, 90, 120, 150],
                  },
                ]}
                width={400}
                height={300}
              />


            </div>


          </div>

        </Box> */}

        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>


      </Box>

    </>
  );
};

export default Dashboard;
