import React, { useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import Loader from "../Loader";



// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const CreateManager = ({ onClose }) => {

  const navigate = useNavigate()
  const token = getCookie("leadmanagement");


  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };
  const [loading , setLoading] = useState(false)
  const [manageData, setManageData] = useState({
    firstName: "", lastName: "", email: "", mobileNumber: "", position: "", department: "", password: ""



  })
  const handleSave = async () => {


    if (
      !manageData.firstName
    ) {
      return toast.error("first name must be required")
    } else if (
      !manageData.lastName
    ) {
      return toast.error("last name must be required")
    } else if (
      !manageData.email
    ) {
      return toast.error("email must be required")
    } else if (
      !manageData.mobileNumber
    ) {
      return toast.error("phone number must be required")
    } else if (
      !manageData.position
    ) {
      return toast.error("Position must be required")
    }
    else if (
      !manageData.department
    ) {
      return toast.error("department must be required")
    }
    else if (
      !manageData.password
    ) {
      return toast.error("password must be required")
    }

    try {
      setLoading(true)
      const response = await Api.create_Manager( manageData, token);
      console.log("Response from createManager API:", response.data.message);
      if (response.data.message === "manager created successfully") {
        setLoading(false)
        toast.success('Successfully Created');
        navigate("/users")
      }
      else {
        console.log("error")

      }

    } catch (error) {
      setLoading(false)
      console.error('Error during creating user:', error);
    }
  };

  const backToPage = () => {
    navigate("/users")
  }

  return (
    <>
      <Topbar />
      <Box m="20px" className="margin_in_mobile_ipad">

        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

          <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

            <Box sx={{

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px"
              , padding: "20px"
              , width: "100%"


            }}  >

              <Typography

                variant="h4"

                fontWeight="550"
                sx={{ m: "0 0 5px 0", }}

                className="heading_animation"
              >
                Contact Information

              </Typography>

              <br /><br />

              <Box
                component="form" className="LMS-Info-section"
                sx={{
                  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                }}
                noValidate
                autoComplete="off"
              >

                <TextField
                  className="class_input"
                  id="outlined-controlled"
                  label="First Name"
                  value={manageData.firstName}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 15) {
                      setManageData({ ...manageData, firstName: inputText });
                    }
                  }}
                  variant="outlined"
                />

                <TextField
                  className="class_input"
                  id="outlined-controlled"
                  label="Last Name"
                  value={manageData.lastName}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 15) {
                      setManageData({ ...manageData, lastName: inputText });
                    }
                  }}
                  variant="outlined"
                />

                <TextField
                  className="class_input"
                  id="outlined-controlled"
                  label="Email"
                  value={manageData.email}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 35) {
                      setManageData({ ...manageData, email: inputText });
                    }
                  }}
                  variant="outlined"
                />

                <TextField
                  type="number"
                  className="class_input"
                  id="outlined-controlled"
                  label="Phone"
                  value={manageData.mobileNumber}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 10) {
                      setManageData({ ...manageData, mobileNumber: inputText });
                    }
                  }}
                  variant="outlined"
                />

                <TextField
                  type="password"
                  className="class_input"
                  id="outlined-controlled"
                  label="Password"
                  value={manageData.password}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 10) {
                      setManageData({ ...manageData, password: inputText });
                    }
                  }}
                  variant="outlined"
                />


              </Box></Box>

            <Box sx={{


              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px"
              , padding: "20px"
              , width: "100%"


            }}    >
              <Typography

                variant="h4"

                fontWeight="550"

                sx={{ m: "0 0 5px 0", }}
                className="heading_animation"
              >
                Additional Information

              </Typography>
              <br /><br />

              <Box
  component="form"
  className="LMS-Info-section"
  sx={{
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "20px",
    width: "100%", // Ensure the container spans the full width
  }}
  noValidate
  autoComplete="off"
>
  {/* Position */}
  <Grid>
    <div style={{ width: "100px" }}>Position</div> {/* Fixed width for label */}
    <FormControl fullWidth>
      <Select
        sx={{ height: "40px", minWidth: "200px" }} // Fixed width for select box
        labelId="second-select-label"
        id="second-select"
        value={manageData.position}
        onChange={(e) => setManageData({ ...manageData, position: e.target.value })}
      >
        <MenuItem value="manager">Manager</MenuItem>
       
      </Select>
    </FormControl>
  </Grid>

  {/* Department */}
  <Grid>
    <div style={{ width: "100px" }}>Department</div> {/* Fixed width for label */}
    <FormControl fullWidth>
      <Select
        sx={{ height: "40px", minWidth: "200px" }} // Fixed width for select box
        labelId="third-select-label"
        id="third-select"
        value={manageData.department}
        onChange={(e) => setManageData({ ...manageData, department: e.target.value })}
      >
        <MenuItem value="sales">Sales</MenuItem>
        <MenuItem value="marketing">Marketing</MenuItem>
        <MenuItem value="product_development">Product Development</MenuItem>
        <MenuItem value="management">Management</MenuItem>
      </Select>
    </FormControl>
  </Grid>
</Box>


            </Box>

          </div>
          <br />

          <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>


            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>


          </div>

        </div>
        {loading && <Loader />}
        <ToastContainer />
      </Box>
    </>

  );
};

export default CreateManager;
