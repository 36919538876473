






import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

} from "@mui/material";

import { getCookie } from "../../components/Cookies";
import Header from "../../components/Header";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import Topbar from "../global/Topbar";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import DialogContentText from "@mui/material/DialogContentText";
import { TextField, FormControl, } from "@mui/material";
import { useParams } from "react-router-dom";



const EditfollowUp = ({ isLogging }) => {
    
    const [editPopUp, setEditPopUp] = useState(true)
    const [open, setOpen] = useState(true);
    const [followUp, setfollowUp] = useState()
    const { id } = useParams();

    const handleInputeChange = (e) => {
        setfollowUp({ ...followUp, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {

        try {
            const response = await Api.get_Follow_By_Id(id, token)
            console.log("response of follow by id", response)
            setfollowUp(response.data.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }


    }

    const HandleEdit = async () => {


        try {
            const response = await Api.update_Follow(id, { ...followUp }, token)
            if (response.status === 201) {
                toast.success("follow update successfully", {
                    autoClose: 800,
                    onClose: () => navigate("/follow-ups"),
                });

            }
        } catch (error) {
            console.error("Error updating property:", error);
        }

    }


   

    const openPopup = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        navigate("/follow-ups")
    };
    const navigate = useNavigate();
    const token = getCookie("leadmanagement");


    useEffect(() => {
        !isLogging && navigate("/");
    }, []);


    return (
        <>
            <Topbar />

            <Box m="20px" className="margin_in_mobile_ipad">
                <Header
                    title="Follow Ups"
                    subTitle=""
                    openPopup={openPopup}
                />
                <Box
                    m="20px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                        },
                    }}
                >

                </Box>




                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <strong style={{ fontSize: "20px" }}>Edit Follow Up</strong>
                        {/* <CloseIcon  onClick={handleClose} style={{cursor:"pointer"}} /> */}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">



                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto auto ",
                                    width: "100%",
                                }}
                            >

                                <FormControl sx={{ m: 1 }}>
                                    <strong>Date</strong>

                                    <TextField
                                        type="date"
                                        name="dueDate"
                                        onChange={handleInputeChange}
                                        value={followUp ? followUp.dueDate : ''}
                                        sx={{ height: "40px" }}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1 }}>
                                    <strong>Time</strong>

                                    <TextField
                                        type="time"
                                        name="time"
                                        onChange={handleInputeChange}
                                        value={followUp ? followUp.time : ''}

                                        sx={{ height: "40px" }}
                                    />
                                </FormControl>

                            </div>

                            <br />
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                {/* <strong>Message</strong> */}




                                <TextField
                                    label="Message  "

                                    type="text"

                                    id="second-text-field"
                                    value={followUp ? followUp.description : ''}
                                    name="description"
                                    onChange={handleInputeChange}
                                    multiline



                                    style={{ width: "100%" }}

                                />





                            </FormControl>




                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{
                            border: "2px solid #9B56FF", height: "37.3px",
                            color: "white ",
                            backgroundColor: "#9B56FF",



                            "&:hover": {
                                color: "#9B56FF"
                            }



                        }}
                            onClick={() => {
                                HandleEdit();
                            }}>Update</Button>



                        <Button sx={{
                            border: "2px solid #9B56FF", height: "37.3px",
                            color: "#9B56FF ",




                            "&:hover": {
                                color: "#9B56FF"
                            }



                        }}

                            onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default EditfollowUp;







