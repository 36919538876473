import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import axios from 'axios';
import Api from '../../Api';
import { useNavigate } from "react-router-dom";
import { NavLink ,Link } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Topbar from "../global/Topbar";

import EditIcon from '@mui/icons-material/Edit';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const Contact = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [userData, setUserData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [contact, setContact] = useState([])
const [isDelete, setIsDelete] = useState(false);


  const openPopup = () =>{
    navigate("/create-contacts");
  
  }


  useEffect(() => {
    fetchData();
  }, [isDelete]);


  const fetchData = async () => {
    try {
      const response = await Api.get_All_Contact()
      if (response.data && Array.isArray(response.data.data)) {
        setContact(mapUserData(response.data.data));
        console.log(response.data.data)
      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


  }
  useEffect(() => {
    !isLogging && navigate('/')
  }, [])



  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
    

      await Api.deleteContact(selectedUserId);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
     
      console.error("Error deleting contact:", error);
    }
  };
 

  const columns = [
    { field: 'sno', headerName: 'S.No', width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First Name', width: 170 ,
  
    renderCell: (params) => (
      <NavLink to={`/contact-details/${params.row.id}`}>
        {params.value}
        </NavLink>
    ),
  
  },


    // { field: 'title', headerName: 'Title', width: 170 },
    { field: 'phone_number', headerName: 'Phone ', width: 170 },
    { field: 'email', headerName: 'Email ', width: 170 },
    { field: 'lead_source', headerName: 'Lead Source ', width: 170 },
    { field: 'lead_status', headerName: 'Lead Status ', width: 170 },

    {
      field: 'edit_contact',
      headerName: 'Edit Contact'
      , width: 170 ,
      renderCell: (params) => (
        <NavLink to={`/edit-contact/${params.row.id}`}>
          <EditIcon />
        </NavLink>
      ),
    }


    ,  {
      field: 'deletecontact',
      headerName: 'Delete Contact'
      , width: 170 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }

    ,
    // {
    //   field: 'tag_list', headerName: 'Tag List', width: 170,

    // },


    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //  <NavLink to={`/edit-user/${params.row.id}`}> 
    //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id)}>
    //       Edit
    //     </Button>
    //     </NavLink>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id)}>
    //       Delete
    //     </Button>
    //   ),
    // }


   
    // {
    //   field: 'blank',
    //   headerName: ''
    //   , width: 40,
    //   renderCell: (params) => (
    //     <img src="/img/star.png" alt="" />
    //   ),
    // }





    // , {
    //   field: 'action',
    //   headerName: 'Action'
    //   , width: 40,
    //   renderCell: (params) => (
    //     <img src="/img/dots3.png" alt="" />
    //   ),
    // },
  ];

  

  const mapUserData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      // login: user.login,
      firstName: d.firstName || 'No',
      // title:d.position || "No",
      // tag_list:d.tagList || "No",
      email: d.email || 'No',
      phone_number: d.mobileNumber || 'No',
      lead_source: d.leadSource || 'No',
      lead_status: d.leadStatus ||'No',
    
    }));
  };






  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };


  
  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
  <>
       <Topbar />

       <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Contacts" subTitle="New Contacts" openPopup={openPopup}  />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={contact} columns={columns} />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="User deleted successfully" />
    </Box>
  </>
  );
};

export default Contact;
