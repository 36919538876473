import React, { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate, } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";



// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ContactDetails = ({ onClose }) => {
  const { id } = useParams();
  const navigate = useNavigate()

  const [contactData, setContactData] = useState(null)

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {

    try {
      const response = await Api.getContactById(id);
      console.log(response.data.data)
      setContactData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



  const backToPage = () => {
    navigate("/contact")
  }











  return (
    <>
      {
        contactData && (
          <>

            <Box m="20px"

              sx={{

                // border: 'none',



                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                // borderRadius: "10px"

                // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                //   color: `${colors.grey[100]} !important`,
                // },
              }}

            >
              <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 20px", alignItems: "center", backgroundColor: "white", zIndex: "9999999", borderRadius: "10px" }}>

                <div style={{ display: "flex", padding: "20px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>



                    <Button sx={{
                      backgroundColor: "#9B56FF", color: "white", '&:hover': {
                        backgroundColor: '#ffffff',
                        color: "#9B56FF",
                        border: " 1px solid #9B56FF"
                      }
                    }}

                      onClick={backToPage}> Cancel</Button>

                  </div>
                </div>

              </div>

              <hr />


              <div style={{ padding: " 20px", alignItems: "center" }}>

                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}  >

                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Contact Information

                    </Typography>

                    <br /><br />




                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        className="class_input"
                        id="outlined-controlled"
                        label="First Name"
                        value={contactData.firstName}
                        // onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

                      />
                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Last Name"
                        value={contactData.lastName}
                        // onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Email"
                        value={contactData.email}
                        // onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Phone"
                        value={contactData.mobileNumber}
                        // onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
                      />
                      {/* <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Fax"
                        value={contactData.fax}
                        // onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
                      /> */}
                    </Box></Box>

                  <Box sx={{

                    // border: 'none',
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"

                    // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    //   color: `${colors.grey[100]} !important`,
                    // },
                  }} >
                    <Typography

                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Address Information

                    </Typography>
                    <br /><br />




                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
                      }}
                      noValidate
                      autoComplete="off">
                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Street"
                        value={contactData.street}
                        // onChange={(e) => setLeadData({ ...leadData, street: e.target.value })}
                        sx={{ paddingTop: "" }}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="City"
                        value={contactData.city}
                        // onChange={(e) => setLeadData({ ...leadData, city: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="State"
                        value={contactData.state}
                        // onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Country"
                        value={contactData.country}
                        // onChange={(e) => setLeadData({ ...leadData, country: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Postal Code"
                        value={contactData.zip}
                        // onChange={(e) => setLeadData({ ...leadData, zipCode: e.target.value })}
                      />

                    </Box>










                  </Box>

                </div>











                <br />
                <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}   >
                    <Typography


                      variant="h4"

                      fontWeight="550"
                      sx={{ m: "0 0 5px 0", }}

                      className="heading_animation"
                    >
                      Company Information

                    </Typography>
                    <br /><br />

                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        display: "grid", gridTemplateColumns: "auto auto auto ", gap: "20px"
                      }}
                      noValidate
                      autoComplete="off">

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Industry"
                        value={contactData.industry}
                        // onChange={(e) => setLeadData({ ...leadData, industry: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Owner"
                        // value={contactData.owner}
                        // onChange={(e) => setLeadData({ ...leadData, owner: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="WebSite"
                        value={contactData.website}
                        // onChange={(e) => setLeadData({ ...leadData, website: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Number of Employees"
                        value={contactData.employNumber}
                        // onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Lead Source"
                        value={contactData.leadSource}
                        // onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
                      />

                    </Box>
                  </Box>



                  <Box sx={{


                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                    , padding: "20px"
                    , width: "100%"


                  }}    >
                    <Typography

                      variant="h4"

                      fontWeight="550"

                      sx={{ m: "0 0 5px 0", }}
                      className="heading_animation"
                    >
                      Additional Information

                    </Typography>
                    <br /><br />

                    <Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                      }}
                      noValidate
                      autoComplete="off">

                      <Grid >
                        <div>Lead Status</div>
                        <FormControl fullWidth >

                          <Select
                            sx={{ height: "40px" }}
                            labelId="second-select-label"
                            id="second-select"
                            fullWidth
                            value={contactData.leadStatus}
                            // onChange={(e) => setLeadData({ ...leadData, leadStatus: e.target.value })}
                          >
                          <MenuItem value="active">Active</MenuItem>
      <MenuItem value="close">Close</MenuItem>
      <MenuItem value="pending">Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid >
                        <div>User Responsible</div>
                        <FormControl fullWidth>

                          <Select
                            sx={{ height: "40px" }}
                            labelId="third-select-label"
                            id="third-select"
                            fullWidth
                            defaultValue=""
                            value={contactData.responsible}
                            // onChange={(e) => setLeadData({ ...leadData, responsible: e.target.value })}
                          >
            <MenuItem value="user_1">User 1</MenuItem>
      <MenuItem value="user_2">User 2</MenuItem>
      <MenuItem value="user_3">User 3</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid >
                        <div >Organization</div>
                        <FormControl fullWidth>

                          <Select
                            sx={{ height: "40px" }}
                            labelId="first-select-label"
                            id="first-select"
                            fullWidth
                            defaultValue=""
                            value={contactData.organization}
                            // onChange={(e) => setLeadData({ ...leadData, organization: e.target.value })}
                          >

<MenuItem value="org_1">Org. 1</MenuItem>
      <MenuItem value="org_2">Org. 2</MenuItem>
      <MenuItem value="org_3">Org. 3 </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid >
                        <div >Permission</div>
                        <FormControl fullWidth>

                          <Select
                            sx={{ height: "40px" }}
                            labelId="first-select-label"
                            id="first-select"
                            fullWidth
                            defaultValue=""
                            value={contactData.permission}
                            // onChange={(e) => setLeadData({ ...leadData, permission: e.target.value })}
                          >

<MenuItem value="option_1">Option 1</MenuItem>
      <MenuItem value="option_2">Option 2</MenuItem>
      <MenuItem value="option_3">Option 3 </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>




                    </Box>
                  </Box>


                </div>




                <br />







            
          </div>
              <ToastContainer />
            </Box>

          </>)
      }

    </>

  );
};

export default ContactDetails;
