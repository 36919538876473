import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import MoneySharpIcon from '@mui/icons-material/MoneySharp';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


function GridCard() {
    return (
        <Card >
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                  <div style={{display:"flex", justifyContent:"space-between"}}><div>  IN093439#@09</div>
              <div> < MoreVertIcon  sx={{marginBottom:"-5px" }} /></div> </div>     
                </Typography>
                <Typography variant="h5" component="div">
                 <div style={{display:"flex"}}>
                 <div><img src='/img/girl1.png'/> </div> 
                 <div><Typography sx={{ mb: 1.5 }} color="text.secondary">
                   <h3 style={{marginLeft:"10px",marginTop:"5px"}}>Malina Devi  </h3> 
                </Typography></div>
                </div>
                </Typography>
                
                <Typography variant="body2">
                    <div style={{display:"flex" ,justifyContent:"space-between"}}>
                 <div>
                 <p> <MoneySharpIcon sx={{marginBottom:"-3px" ,height:"15px"}}/>Amount <br/>$12,345</p>
                 </div>
                  <div>

                  <p><CalendarMonthTwoToneIcon sx={{marginBottom:"-3px" ,height:"15px"}}/>Due Date <br/>23 Mar, 2022</p>
                  </div>

                    </div>
                 
                 
                </Typography>
            </CardContent>
            <CardActions sx={{marginLeft:"8px"}}>
                <Button size="small" sx={{border:"1px" , color:"White", backgroundColor:"#26AF48", marginBottom:"10px" , 
            
       "&:hover": {
        color:"#9B56FF"
              }
        }}>Paid</Button>
            </CardActions>
        </Card>
    )
}

export default GridCard