import React, { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";



// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const EditCompany = ({ onClose }) => {

    const { id } = useParams();
    const navigate = useNavigate()


    const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
    };

    const [companyData, setCompanyData] = useState()


    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {

        try {
            const response = await Api.getCompById(id);
            console.log(response.data.data)
            setCompanyData(response.data.data)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    const backToPage = () => {
        navigate("/companies")
    }









    const handleUpdate = async () => {
        try {

            const response = await Api.updateCompany(id, { ...companyData });


            console.log("API Response:", response);

            if (response.status === 200) {
                toast.success("Successfully updated", {
                    autoClose: 800,
                    onClose: () => navigate("/contact"),
                });

            }
        } catch (error) {

            console.error("Error updating property:", error);
        }
    };










    return (
        <>

            {companyData && (
                <>
                    <Box m="20px"

                        sx={{


                        }}

                    >
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 20px", alignItems: "center", position: "sticky", top: "0", backgroundColor: "white", zIndex: "9999999", borderRadius: "10px" }}>
                            <Typography

                                variant="h4"

                                fontWeight="bold"
                                sx={{ m: "4 0 5px 0", }}
                            >
                                Add Lead

                            </Typography>
                            <div style={{ display: "flex", padding: "20px" }}>
                                <div style={{ display: "flex", gap: "10px" }}>

                                    <Button sx={{
                                        // backgroundColor: "#9B56FF", 
                                        color: "#9B56FF",
                                        border: " 1px solid #9B56FF",
                                        '&:hover': {
                                            backgroundColor: '#ffffff',
                                            color: "#9B56FF",
                                            border: " 1px solid #9B56FF"
                                        }
                                    }}

                                        onClick={() => {
                                            handleUpdate();
                                        }}
                                    >Update</Button>


                                    <Button sx={{
                                        backgroundColor: "#9B56FF", color: "white", '&:hover': {
                                            backgroundColor: '#ffffff',
                                            color: "#9B56FF",
                                            border: " 1px solid #9B56FF"
                                        }
                                    }}

                                        onClick={backToPage}> Cancel</Button>

                                </div>
                            </div>

                        </div>

                        <hr />


                        <div style={{ padding: " 20px", alignItems: "center" }}>

                            <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                                <Box sx={{


                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px"
                                    , padding: "20px"
                                    , width: "100%"


                                }}  >

                                    <Typography

                                        variant="h4"

                                        fontWeight="550"
                                        sx={{ m: "0 0 5px 0", }}

                                        className="heading_animation"
                                    >
                                        Contact Information

                                    </Typography>

                                    <br /><br />




                                    <Box
                                        component="form" className="LMS-Info-section"
                                        sx={{
                                            display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        {/* <TextField
className="class_input"
id="outlined-controlled"
label="First Name"
value={leadData.firstName}
onChange={(e) => setLeadData({ ...leadData , firstName:e.target.value })}


/> */}


                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="First Name"
                                            value={companyData.firstName}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 15) {
                                                    setCompanyData({ ...companyData, firstName: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />


                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Last Name"
                                            value={companyData.lastName}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 15) {
                                                    setCompanyData({ ...companyData, lastName: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />




                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Last Name"
value={leadData.lastName}
onChange={(e) => setLeadData({ ...leadData , lastName:e.target.value })}

/> */}



                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Email"
                                            value={companyData.email}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 35) {
                                                    setCompanyData({ ...companyData, email: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Email"





value={leadData.email || ""}
onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}



                                        <TextField
                                            type="number"
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Phone"
                                            value={companyData.mobileNumber}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 10) {
                                                    setCompanyData({ ...companyData, mobileNumber: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Phone"
value={leadData.phoneNumber}
onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}



                                        {/* <TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setLeadData({ ...leadData, fax: inputText });
    }
  }}
  variant="outlined"
/> */}


                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Fax"
value={leadData.fax}
onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}





                                    </Box></Box>

                                <Box sx={{

                                    // border: 'none',
                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
                                    borderRadius: "10px"
                                    , padding: "20px"
                                    , width: "100%"

                                    // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    //   color: `${colors.grey[100]} !important`,
                                    // },
                                }} >
                                    <Typography

                                        variant="h4"

                                        fontWeight="550"
                                        sx={{ m: "0 0 5px 0", }}

                                        className="heading_animation"
                                    >
                                        Address Information

                                    </Typography>
                                    <br /><br />




                                    <Box
                                        component="form" className="LMS-Info-section"
                                        sx={{
                                            display: "grid", gridTemplateColumns: "auto auto  ", gap: "20px"
                                        }}
                                        noValidate
                                        autoComplete="off"><TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Street"
                                            value={companyData.street}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 40) {
                                                    setCompanyData({ ...companyData, street: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />




                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Street"
value={leadData.street}
onChange={(e) => setLeadData({ ...leadData, street: e.target.value })}
sx={{ paddingTop: "" }}
/> */}

                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="City"
                                            value={companyData.city}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, city: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="City"
value={leadData.city}
onChange={(e) => setLeadData({ ...leadData, city: e.target.value })}
/> */}


                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="State"
                                            value={companyData.state}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, state: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="State"
value={leadData.state}
onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}
/> */}


                                        <TextField
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Country"
                                            value={companyData.country}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, country: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Country"
value={leadData.country}
onChange={(e) => setLeadData({ ...leadData, country: e.target.value })}
/> */}

                                        <TextField
                                            type="number"
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Postal Code"
                                            value={companyData.zip}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 10) {
                                                    setCompanyData({ ...companyData, zip: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Postal Code"
value={leadData.zipCode}
onChange={(e) => setLeadData({ ...leadData, zipCode: e.target.value })}
/> */}

                                    </Box>










                                </Box>

                            </div>











                            <br /> <br />
                            <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                                <Box sx={{


                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px"
                                    , padding: "20px"
                                    , width: "100%"


                                }}   >
                                    <Typography


                                        variant="h4"

                                        fontWeight="550"
                                        sx={{ m: "0 0 5px 0", }}

                                        className="heading_animation"
                                    >
                                        Company Information

                                    </Typography>
                                    <br /><br />

                                    <Box
                                        component="form" className="LMS-Info-section"
                                        sx={{
                                            display: "grid", gridTemplateColumns: "auto auto auto ", gap: "20px"
                                        }}
                                        noValidate
                                        autoComplete="off">


                                        <TextField

                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Industry"
                                            value={companyData.industry}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, industry: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Industry"
value={leadData.industry}
onChange={(e) => setLeadData({ ...leadData, industry: e.target.value })}
/> */}


                                        <TextField

                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Owner"
                                            value={companyData.owner}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, owner: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Owner"
value={leadData.owner}
onChange={(e) => setLeadData({ ...leadData, owner: e.target.value })}
/> */}


                                        <TextField

                                            className="class_input"
                                            id="outlined-controlled"
                                            label="WebSite"
                                            value={companyData.website}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 20) {
                                                    setCompanyData({ ...companyData, website: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="WebSite"
value={leadData.website}
onChange={(e) => setLeadData({ ...leadData, website: e.target.value })}
/> */}

                                        <TextField
                                            type="number"
                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Number of Employees"
                                            value={companyData.employNumber}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 3) {
                                                    setCompanyData({ ...companyData, employNumber: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Number of Employees"
value={leadData.employNumber}
onChange={(e) => setLeadData({ ...leadData, employNumber: e.target.value })}
/> */}


                                        <TextField

                                            className="class_input"
                                            id="outlined-controlled"
                                            label="Lead Source"
                                            value={companyData.leadSource}
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                if (inputText.length <= 15) {
                                                    setCompanyData({ ...companyData, leadSource: inputText });
                                                }
                                            }}
                                            variant="outlined"
                                        />

                                        {/* <TextField
className="class_input"
id="outlined-uncontrolled"
label="Lead Source"
value={leadData.leadSource}
onChange={(e) => setLeadData({ ...leadData, leadSource: e.target.value })}
/> */}

                                    </Box>
                                </Box>



                                <Box sx={{


                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "10px"
                                    , padding: "20px"
                                    , width: "100%"


                                }}    >
                                    <Typography

                                        variant="h4"

                                        fontWeight="550"

                                        sx={{ m: "0 0 5px 0", }}
                                        className="heading_animation"
                                    >
                                        Additional Information

                                    </Typography>
                                    <br /><br />

                                    <Box
                                        component="form" className="LMS-Info-section"
                                        sx={{
                                            display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                                        }}
                                        noValidate
                                        autoComplete="off">

                                        <Grid >
                                            <div>Lead Status</div>
                                            <FormControl fullWidth >

                                                <Select
                                                    sx={{ height: "40px" }}
                                                    labelId="second-select-label"
                                                    id="second-select"
                                                    fullWidth
                                                    value={companyData.leadStatus}
                                                    onChange={(e) => setCompanyData({ ...companyData, leadStatus: e.target.value })}
                                                >
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="close">Close</MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid >
                                            <div>User Responsible</div>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{ height: "40px" }}
                                                    labelId="third-select-label"
                                                    id="third-select"
                                                    fullWidth
                                                    defaultValue=""
                                                    value={companyData.responsible}
                                                    onChange={(e) => setCompanyData({ ...companyData, responsible: e.target.value })}
                                                >
                                                    <MenuItem value="user_1">User 1</MenuItem>
                                                    <MenuItem value="user_2">User 2</MenuItem>
                                                    <MenuItem value="user_3">User 3</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid >
                                            <div >Organization</div>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{ height: "40px" }}
                                                    labelId="first-select-label"
                                                    id="first-select"
                                                    fullWidth
                                                    defaultValue=""
                                                    value={companyData.organization}
                                                    onChange={(e) => setCompanyData({ ...companyData, organization: e.target.value })}
                                                >

                                                    <MenuItem value="org_1">Org. 1</MenuItem>
                                                    <MenuItem value="org_2">Org. 2</MenuItem>
                                                    <MenuItem value="org_3">Org. 3 </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid >
                                            <div >Permission</div>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{ height: "40px" }}
                                                    labelId="first-select-label"
                                                    id="first-select"
                                                    fullWidth
                                                    defaultValue=""
                                                    value={companyData.permission}
                                                    onChange={(e) => setCompanyData({ ...companyData, permission: e.target.value })}
                                                >

                                                    <MenuItem value="option_1">Option 1</MenuItem>
                                                    <MenuItem value="option_2">Option 2</MenuItem>
                                                    <MenuItem value="option_3">Option 3 </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>




                                    </Box>
                                </Box>


                            </div>




                            <br />







                            


                        </div>
                        <ToastContainer />
                    </Box>
                </>
            )}
        </>


    );
};

export default EditCompany;
