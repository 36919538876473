import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import Api from '../../Api';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Topbar from '../global/Topbar';
import { ToastContainer, toast } from "react-toastify";
import Loader from '../Loader';
import { connect } from 'react-redux';
import { getCookie } from '../../components/Cookies';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  const  options = [ "Save Changes" , "Localization" , "Payment Setting" , "Email Setting" , "Social Media Login" , " Social Links" , " SEO Setting" , " Others"] ;

  const settingOptions = [
    "Website Name" , "Logo","Favicon" 
  ];

  const resetPassword = [
    "Current Password" , "New Password","Confirm Password" 
  ];

  const settingOptions2 = [
    "Address Line 1 " , "Address Line 2 ","City " , "State/Province " ,
     "Zip/Postal Code" , "Country " 
  ];


const Settings = ({ isLogging , role }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [taskData, setTaskData] = useState([])
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [generateKeys , setGenerateKeys] = useState("")

  const token  = getCookie("leadmanagement")

  const handleChangePasswords = async () =>{

    if(!currentPassword){
      return toast.error("please fill your current password");
    }

    if(newPassword.length < 8){
      return toast.error( "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character ", "filled");
    }

    if(newPassword !== confirmNewPassword){
      return toast.error("password must be same");
    }

    if(!newPassword){
      return toast.error("fill new password");

    }

try {

  setLoading(true);
  const response = await Api.adminChangePassword({currentPassword , newPassword} , token)
if(response.data.message ==="Password updated successfully"){
  setChangePassword(!changePassword)
  setLoading(false);
  toast.success("Password updated successfully");

}

setCurrentPassword("");
setNewPassword("");
setConfirmNewPassword("");


} catch (error) {
  setLoading(false);
  console.error("Error changing password:", error);
  toast.error(error.response.data.message);
}



  }

  useEffect(()=>{
    fetchSecretKey()
  },[])

  const fetchSecretKey = async  () => {

    try {
    
      const response = await Api.showGenerateKey(token)
      console.log("k.gjuh")
      console.log("cvbh",response.data)
setGenerateKeys(response.data.data.secretKey)

    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(error.response.data.message);
    }
    
  }

  const HandleGenerateKey = async ()=>{
    setLoading(true)
try {
  const response = await Api.generateKey(token)
  console.log(response.data.data)
  setGenerateKeys(response.data.secretKey)
  // fetchSecretKey()
  setLoading(false)
} catch (error) {
  setLoading(false);
  console.error(error);
  toast.error(error.response.data.message);
}
  }


 



  useEffect(() => {
    !isLogging && navigate('/')
  }, [])


  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
<>
    <Topbar/>
    <Box m="30px " className="margin_in_mobile_ipad" >

<Header title="Settings" subTitle="New invoice" />

    <Box  >  


    <div className="respons1" style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr" , width:"100%" , gap:"10px" }}>

    
{options.map((data , index) =>{
    return(
        <Button sx={{
                 
                   color: "#9B56FF",
                   border: " 1px solid #9B56FF" ,
                    '&:hover': {
                     backgroundColor: '#ffffff',
                     color: "#9B56FF",
                     border: " 1px solid #9B56FF"
                   }
                 }} >{data}</Button>
   
   
    )
})}

         </div>
 </Box>

 
 <br />

 <Box  className="display11"  style={{display:"flex" , justifyContent :"space-between" , gap:"20px"}}>
 <Box style={{boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", padding:" 0px 20px" , borderRadius:"10px" , width :"100%"}}
      
      className=" margininvoice"
      
      >

{role === "admin" && (
  <>
  <Button
        sx={{
          backgroundColor: "#9B56FF",
          color: "white",
          '&:hover': {
            backgroundColor: '#ffffff',
            color: "#9B56FF",
            border: "1px solid #9B56FF"
          },
          height: "40px"
        }}
        onClick={HandleGenerateKey}
      >
        Generate Key
      </Button>


      <TextField
        type='text'
        value={generateKeys} 
        style={{ marginLeft: "20px", width: "50%" }}
        InputProps={{ readOnly: true }} 
      />
      </>
      ) }







<div style={{  }}  >


<p style={{fontSize:"20px" }}>Website Basic Details</p>
<hr/>

{settingOptions.map((data,index) =>{
    return (
        <>

<div > 
        
        <div>       
        <p style={{fontSize:"17px", padding:"0px 5px 0px 0px "  }}>{data}</p>
        </div>
               <TextField fullWidth  id="fullWidth" />
                  </div>
        </>
    )
})}

<br/>

<div style={{display:"flex" }}>

  <div style={{display:"flex" ,gap:"10px"}}> 
  
  


<Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }} >Update</Button>


<Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF" ,
                 '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }} >Cancel</Button>
  
   </div>

</div>


<br/>


{role === "admin" && (
  <>
    {resetPassword.map((data, index) => (
      <div key={index}>
        <div>
          <p style={{ fontSize: "17px", padding: "0px 5px 0px 0px" }}>{data}</p>
        </div>
        <TextField
          fullWidth
          id="fullWidth"
          type={data.toLowerCase().includes("password") ? "password" : "text"}
          value={data === "Current Password" ? currentPassword : data === "New Password" ? newPassword : confirmNewPassword}
          onChange={(e) => {
            if (data === "Current Password") setCurrentPassword(e.target.value);
            if (data === "New Password") setNewPassword(e.target.value);
            if (data === "Confirm Password") setConfirmNewPassword(e.target.value);
          }}
        />
      </div>
    ))}
    <br />
    <div style={{ display: "flex "  }}>
      <div style={{ display: "flex", gap: "10px" , paddingBottom:"10px" }}>
        <Button sx={{
          backgroundColor: "#9B56FF", color: "white", '&:hover': {
            backgroundColor: '#ffffff',
            color: "#9B56FF",
            border: "1px solid #9B56FF"
          }
        }}
          onClick={handleChangePasswords}
        >Change Password</Button>
        <Button sx={{
          color: "#9B56FF",
          border: "1px solid #9B56FF",
          '&:hover': {
            backgroundColor: '#ffffff',
            color: "#9B56FF",
            border: "1px solid #9B56FF"
          }
        }}>Cancel</Button>
      </div>
    </div>
  </>
)}


            
            </div>
          </Box>

                   <Box style={{boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", padding:" 0px 20px" , borderRadius:"10px" , width :"100%"}}
      
      className=" margininvoice"
      
      >


   

<div style={{  }}  >


<p style={{fontSize:"20px" }}>Address Details</p>
<hr/>

{settingOptions2.map((data,index) =>{
    return (
        <>

<div     > 
        
        <div>       
        <p style={{fontSize:"17px", padding:"0px 5px 0px 0px "  }}>{data}</p>
        </div>
               <TextField fullWidth  id="fullWidth" />
                  </div>
        </>
    )
})}

<br/>

<div style={{display:"flex" }}>

  <div style={{display:"flex" ,gap:"10px"}}> 
  



<Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }} >Update</Button>


<Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF" ,
                 '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }} >Cancel</Button>
  
   </div>

</div>


<br/>


     





                   </div> </Box>


 </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="User deleted successfully" />
    </Box>
{loading && <Loader />}
    <ToastContainer/>

    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});


export default connect(mapStateToProps)(Settings);



