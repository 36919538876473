import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { store } from "../../redux/store";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../Api";
import { Link } from "react-router-dom";
import { setCookie } from "../../components/Cookies";
import { connect } from "react-redux";


import { useDispatch } from "react-redux";

import "./login.css"; // Make sure to import your CSS file

const useStyles = makeStyles((theme) => ({
  input: {
    borderTop: "0px",
    borderRight: "0px",
    borderLeft: "0px",
    border: "1px  solid rgb(223, 219, 219)",
    borderRadius: "10px",
    outline: "none",
    padding: "5px 10px 5px 10px",
    width: "100%", 
    

    "& input::placeholder": {
      color: "#000000",
    },
  },
}));

const Loginn = ({ isLogging, role }) => {
  const classes = useStyles();

  const Navigate = useNavigate();

  const dispatch = useDispatch();

  const setUserDataa = (data) => {
    store.dispatch({
      type: "User_Data",
      payload: data,
    });
  };

  useEffect(() => {
    if (isLogging) {
      return role === "admin"
        ? Navigate("/admin-dashboard")
        : role === "manager"
        ? Navigate("/manager-dashboard")
        : role === "agent"
        ? Navigate("/leads")
        : "";
    }
  }, [isLogging, role]);

  const [loading, setLoading] = useState(false);
  //   const isNonMobile = useMediaQuery("(min-width:500px)");
  const [userData, setUserData] = useState({ emailORMobile: "", password: "" });
  const [login, setLogin] = useState("main");

  const handleLogin = async () => {
    if (userData.emailORMobile === "") {
      return toast.error("Fill email or mobile number", {
        autoClose: 2000,
      });
    }

    if (userData.password === "") {
      return toast.error("fill password", {
        autoClose: 2000,
      });
    }
    try {
      setLoading(true);
      const response = await Api.login(userData);
      console.log(response);
      if (response.data.message === "Login successfully") {
        // setUserData(response.data)
        toast.success("Login successfully");
        setCookie("leadmanagement", response.data.accessToken, 7);
        setLoading(false);
        setUserDataa({
          isLogging: true,
          role: response.data.data.role,
          userName: `${response.data.data.firstName} ${response.data.data.lastName} `,
        });
        // localStorage.setItem("login", true)
        Navigate(
          role === "admin"
            ? "/admin-dashboard"
            : role === "manager"
            ? "/manager-dashboard"
            : role === "agent"
            ? "/leads"
            : ""
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      console.error("Error during login:", error);
    }
  };

  return (
    <div
      className="container bgg-img "
    
    >
      <div className="login">
        <div className="login__content">
          {/* <img  className="login__img" src="./img/back.png" alt="Login image" /> */}
          <div className="login__img"></div>
          <div className="login__form">
            <div>
            <h1 className="">Login</h1>
              <h1 className="login__title">
              <span style={{color:"#000000"}}>Welcome</span> &nbsp; <span>Back</span> 
              </h1>

              <p style={{textAlign:"center"}} className="login__description">
                Welcome! Please login to continue.
              </p>
            </div>
           
            {/* <div  className="">
              Access to our dashboard
              </div> */}
            <div>
              <div className="login__inputs">
                <div>
                  {/* <label htmlFor="email" className="login__label">Email</label> */}
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Email or Mobile"
                    value={userData.emailORMobile}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        emailORMobile: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  {/* <label htmlFor="password" className="login__label">Password</label> */}
                  <div className="login__box">
                    <InputBase
                      className={classes.input}
                      type="password"
                      placeholder="Password"
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({ ...userData, password: e.target.value })
                      }
                    />
                    {/* <i className="ri-eye-off-line login__eye" id="input-icon"></i> */}
                  </div>
                </div>
                {/* <Link  className="login__forgot" to=" ">
                  Forgot Password?
                </Link> */}
              </div>

              {/* <div className="login__check">
                <label className="login__check-label" htmlFor="check">
                  <input className="login__check-input" type="checkbox" id="check" />
                  <i className="ri-check-line login__check-icon"></i>
                  Remember me
                </label>
              </div> */}
            </div>

            <div>
              <div
                onClick={() => {
                  handleLogin();
                }}
                className="login__buttons"
              >
                <button disabled={loading}  className="login__button">  {loading ? < CircularProgress style={{ height:"20px" , width:"20px"}}/> :"Log In"} </button>

                {/* <button className="login__button login__button-ghost">Sign Up</button> */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div style={{color:"black"}} className="login__forgot">
                  Don't have an account yet ?{" "}
                  <span>
                    <Link className="login__forgot" to="/register ">
                      Register
                    </Link>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

export default connect(mapStateToProps)(Loginn);
