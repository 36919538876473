const setCookie = (name, value, days)=> {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Calculate expiration date
    document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
  }


      const getCookie = (name)=> {
    const cookieArray = document.cookie.split(';');
    for (const cookie of cookieArray) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return  {
          headers: {
            Authorization: `Bearer ${cookieValue}`
          }
        };
        
        
        
      }
    }
    return null; 
  }

  const deleteCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  export { setCookie, getCookie ,deleteCookie };