import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Grid, TextField, Select, MenuItem } from '@mui/material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { FormControl, InputAdornment } from "@mui/material";
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from "@mui/material/DialogContentText";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const Leads = ({ isLogging, role }) => {

  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);

  const token = getCookie("leadmanagement")





  const openPopup = () => {
    navigate("/create-lead");

  }







  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
  const [lead, setLead] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [followUP, setFollowUp] = useState({ description: "", time: "", dueDate: "" ,  email: ""})
const [loading , setLoading] = useState(false)



  const handleFollowup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    fetchData()
  }, [isDelete])

  const fetchData = async () => {

    try {
      setLoading(true)
      let response;
      if (role === 'admin') {
        response = await Api.get_All_Leads(token);
      } else if (role === 'manager') {
        response = await Api.get_Manager_Leads(token);
      } else if (role === 'agent') {
        response = await Api.get_Agent_Leads(token);
      }

      if (response.data && Array.isArray(response.data.data)) {
        setLoading(false)
        setLead(mapPlotData(response.data.data));
        // setLead(response.data.data)
        console.log(response.data.data);

      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    !isLogging && navigate('/')
  }, [])



  const handleCreateFollowups = async () => {

setLoading(true)
    try {
      const response = await Api.create_Follow(followUP, token)

      console.log("response of api to create reminder", response);

      if (response.data.message === "followup create successfully") {
        setOpen(false);
        setLoading(false)
        toast.success('Successfully Created');
        fetchData()
        navigate("/follow-ups")
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }

  }

  const handleInputeChange = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    setFollowUp({ ...followUP, [e.target.name]: e.target.value })


  }

  const handleCreateClick = (id) => {
    const selectedLead = lead.find(item => item.id === id);
    setSelectedUserId(id);
    setFollowUp({ ...followUP, email: selectedLead.email }); // Set email when creating followup
    setOpen(true);
  }
  // setEmailSave(params.row.email)


  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true)
    try {


      await Api.Delete_lead(selectedUserId);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);

    } catch (error) {
      setLoading(false)
toast.error(error)
      console.error("Error deleting user:", error);
    }
  };




  const handleLeadStatusChange = async (event, id) => {
    const newStatus = event.target.value;
    console.log("newstats", newStatus);
    console.log(" event id", id);
    setLoading(true)
    try {

      const response = await Api.update_Status(id, { leadStatus: newStatus }, token);
      console.log("api response", response);

      const updatedLead = lead.map((item) =>
        item.id === id ? { ...item, lead_status: newStatus } : item
      );
      setLead(updatedLead);

      if (response.data.message == "Lead status updated successfully") {
        toast.success("Lead Status updated successfully")
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error updating lead status:", error);
    }
  };


  // const handleCreateFollowups = async () => {


  //   try {
  //     const response = await Api.create_Follow(followUP, token)

  //         console.log("response of api to create reminder", response);

  //         if (response.data.message === "followup create successfully") {
  //           setOpen(false);
  //           toast.success('Successfully Created');
  //           fetchData()
  //           navigate("/follow-ups")
  //         }
  //   } catch (error) {
  //     console.log(error)
  //   }

  //     }








  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    {
      field: "full_name", headerName: "Full Name", width: 120,
      renderCell: (params) => (
        <Link to={`/lead-details/${params.row.id}`}>
          {params.value}
        </Link>
      ),
    },
    { field: 'email', headerName: 'Email', width: 120 },
    { field: 'lead_source', headerName: 'Lead Source', width: 120 },
    { field: 'isAssign', headerName: 'Assigned By', width: 120 },
    { field: 'assignTo', headerName: 'Assigned To', width: 120 },
    {
      field: "lead_status",
      headerName: "Lead Status",
      width: 170,
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) => handleLeadStatusChange(e, params.row.id)}
          style={{
            backgroundColor: getButtonColor(params.value),
            padding: "2px",
            width: "130px",
            color: "white",
            height: "35px"
          }}
        >
          <MenuItem value="new">New</MenuItem>
          <MenuItem value="processing">In discussion</MenuItem>
          <MenuItem value="confirm">Hired</MenuItem>
          <MenuItem value="cancel">Cold/Dead</MenuItem>
        </Select>
      ),
    },


    {
      field: "listing_Url", headerName: "Listing Url", width: 120,
      renderCell: (params) => (
        <Link to={`${params.row.listing_Url}`} target="_blank">
          {params.value}
        </Link>
      ),
    },
    { field: 'listingAddress', headerName: 'Listing Address', width: 120 },
    { field: 'hostName', headerName: 'Host Name', width: 120 },
    { field: 'hostNumber', headerName: 'Host Number', width: 120 },
    { field: 'hostMail', headerName: 'Host Mail', width: 120 },
    
    { field: 'lead_created', headerName: 'Lead Created', width: 120 },
    {
      field: 'add_followup',
      headerName: 'Add Followup',
      width: 100,
      renderCell: (params) => (
        <button onClick={() => handleCreateClick(params.row.id)} style={{ color: "black" }}>
          <AddIcCallIcon />
        </button>
      ),
    },
    {
      field: 'edit_lead',
      headerName: 'Edit Lead',
      width: 100,
      renderCell: (params) => (
        <Link to={`/edit-lead/${params.row.id}`} style={{ color: "black" }}>
          <EditIcon />
        </Link>
      ),
    },
    ...(role === "admin" ? [{
      field: 'delete_lead',
      headerName: 'Delete Lead',
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleDeleteClick(params.row.id)} style={{ color: "black" }}
        >
          <DeleteForeverOutlinedIcon />
        </button>
      ),
    }] : []),
    {
      field: 'action',
      headerName: 'Action',
      width: 40,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="options" />
      ),
    },
  ];
  
  
  


  const getButtonColor = (leadStatus) => {
    switch (leadStatus) {

      case 'new':
        return 'rgb(5, 102, 129)';
      case 'processing':
        return 'rgb(58, 50, 133)';
      case 'confirm':
        return 'rgb(72, 110, 3)';
      case 'cancel':
        return 'rgb(230, 0, 35)';
      default:
        return 'gray'; // Default color if status is not recognized
    }
  };




  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id || index,
      full_name: d.firstName || "No",
      title: d.title || "No",
      // company: d.industry || "No",
      phone: d.phoneNumber,
      email: d.email,
      lead_status: d.leadStatus,
      listing_Url: d.listingUrl || "No",
      listingAddress: d.listingAddress || "No",
      hostName: d.hostName || "No",
      hostNumber: d.hostNumber || "NO",
      hostMail: d.hostMail || "NO",
      lead_created: d.createdAt.slice(0, 10),
      lead_owner: d.owner,
      lead_source: d.leadSource,
      assignTo: d.assignTo && d.assignTo.firstName || "NO",
      isAssign: d.assignBy && d.assignBy.firstName || "No",


    }));
  };

  return (
    <>
      <Topbar />

      <Box m="20px" className="margin_in_mobile_ipad" >


        <Header title="Leads" subTitle={ "New Lead"} openPopup={openPopup} />
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px"
            },

          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //  checkboxSelection
            rows={lead}
            // rows={plotData}

            columns={columns}
          />
        </Box>


        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this Lead?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>







        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <strong style={{ fontSize: "20px" }}>Add Follow Up</strong>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </DialogTitle>





          <DialogContent>
            <DialogContentText id="alert-dialog-description">



              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto ",
                  width: "100%",
                }}
              >

                <FormControl sx={{ m: 1 }}>
                  <strong>Date</strong>

                  <TextField
                    type="date"
                    name="dueDate"
                    onChange={handleInputeChange}
                    value={followUP.dueDate}
                    sx={{ height: "40px" }}
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }}>
                  <strong>Time</strong>

                  <TextField
                    type="time"
                    name="time"
                    onChange={handleInputeChange}
                    value={followUP.time}


                    sx={{ height: "40px" }}
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                  <strong>Email</strong>
                  <TextField
                    type="text"
                    name="email"
                    value={followUP.email}
                    sx={{ height: "40px" }}
                    // disabled 
                  />
                </FormControl>

                {/* <FormControl sx={{ m: 1 }}>
                      <strong>User</strong>
                      <Select
                        //   value={age}
                        //   onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "40px" }}
                        icon={<PersonAddAltIcon />}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>Russel Copeland</MenuItem>
                        <MenuItem value={20}>Brian Jhonson</MenuItem>
                      </Select>
                    </FormControl> */}



              </div>

              <br />
              <FormControl sx={{ m: 1, width: "100%" }}>
                {/* <strong>Message</strong> */}




                <TextField
                  label="Message  "

                  type="text"
                  id="second-text-field"
                  value={followUP.description}
                  name="description"
                  onChange={handleInputeChange}
                  multiline
                  style={{width:"100%"}}
                />





              </FormControl>























            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={{
              border: "2px solid #9B56FF", height: "37.3px",
              color: "white ",
              backgroundColor: "#9B56FF",



              "&:hover": {
                color: "#9B56FF"
              }



            }}    onClick={handleCreateFollowups}>Submit</Button>



            <Button sx={{
              border: "2px solid #9B56FF", height: "37.3px",
              color: "#9B56FF ",




              "&:hover": {
                color: "#9B56FF"
              }



            }}



              onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        {loading && <Loader />}


        <ToastContainer />

      </Box>
    </>
  );
};


const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});


export default connect(mapStateToProps)(Leads);
