import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Loader from "../Loader";
import Topbar from "../global/Topbar";
import Api from "../../Api";
import { getCookie } from "../../components/Cookies";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;;
import Card from '@mui/material/Card';
import Swal from "sweetalert2";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CardContent from '@mui/material/CardContent';
import {DialogTitle ,Dialog ,DialogContent,DialogActions} from "@mui/material";












const ManagerDashboard = ({isLogging}) => {

  const openPopup = () => {
    Swal.fire({
      title: "Add Note",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        // Return the note value here
        return note;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'swal-button',
        cancelButton: 'swal-button'
      },
      buttonsStyling: false,
      padding: '2rem',

      background: '#fff',
      backdrop: 'rgba(0, 0, 0, 0.4)',
      confirmButtonColor: '#4caf50',
      confirmButtonTextColor: '#fff',
      cancelButtonColor: '#f44336',
      cancelButtonTextColor: '#fff',
      html: `
        <style>
          .swal-button {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }
        </style>
      `
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the "Save" button is clicked
        try {
          // Your asynchronous code for saving the note
          const response = await Api.createTextNote({ text: result.value } , token);
          setIsDelete(!isDelete)
          console.log("Response from createTextNote API:", response.data.message);
          if (response.data.message === "successfully created note") {
         
            // Show success message and handle redirection
            setAllNotes(!allNotes)
            Swal.fire('Note Saved!', '', 'success');
           
            console.log("error");
          }
        } catch (error) {
          console.error('Error during saving note:', error);
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      }
    });
  }
  const navigate = useNavigate()
  const [isDelete, setIsDelete] = useState(false);
  const [allNotes ,setAllNotes] = useState("")

  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [loading , setLoading] = useState(false)
  const [notes, setNotes] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
const [managerLead , setManagerLead] = useState("")
const [managerAgent , setManagerAgent] = useState("")
const [source, setSource] = useState("")
const [todayLeads , setTodayLeads] = useState("")
const [assignLeads , setAssignLeads] = useState("")
const [status , setStatus] = useState("")
  const Navigate = useNavigate()
  const dispatch = useDispatch();

  const counts = [
    { id: 1, count: managerLead, description: "Total Leads" , image:"/img/icon4.png" },
    { id: 2, count: assignLeads, description: "Assigned Leads" , image:"/img/greenimage.png" },
    { id: 3, count:managerAgent, description: "Total Agents" , image:"/img/redimage.png" },
  //  { id: 4, count: 15870, description: "Total Task" , image:"/img/blueimage.png" },
    
    // Add more data objects as needed
  ];
const token = getCookie("leadmanagement")

useEffect(() => {
  if (isLogging) {
    fetchData(); // Fetch data when the component mounts
    handleManagerLead()
    handleManagerAgent()
    handleStatus()
    handleSource()
    handleTodayManagerLeads()
    handleManagerAssignLeads()
  }
}, [isLogging,allNotes,isDelete,managerLead,managerAgent  ]);


const handleManagerAssignLeads = async ()=>{
try {
  setLoading(true)
  const response = await Api.get_All_Manager_AssingTo_Leads(token)
  setLoading(false)
  console.log("assign manager leads", response.data);
  setAssignLeads(response.data.data)
} catch (error) {
  setLoading(false)
    console.error('Error fetching data:', error);
}
}

const handleTodayManagerLeads = async ()=>{
  try {
    setLoading(true)
    const response = await Api.today_Manager_Leads(token)
    setLoading(false)
    console.log("today manager leads", response.data);
    setTodayLeads(response.data)
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
  
  }



const handleStatus = async ()=>{

  try {
    setLoading(true)
    const response = await Api.get_Lead_Status_source_manager(token);
    setLoading(false)
    setStatus(response.data)
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
}


const handleManagerLead = async ()=>{

  try {
    setLoading(true)
    const response = await Api.total_Manager_Leads(token)
    setLoading(false)
    setManagerLead(response.data.count)
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
}

const handleManagerAgent = async ()=>{

try {
  setLoading(true)
const response = await Api.get_All_Agent(token)
setLoading(false)
console.log("elkfufbfeffbuwekf.wef.b",response.data.count)
setManagerAgent(response.data.count)
} catch (error) {
  setLoading(false)
  console.error('Error fetching data:', error);
}


}



const handleSource = async () => {

  try {
    setLoading(true)
    const response = await Api.get_Lead_Status_source_manager(token)
    setLoading(false)
    setSource(response.data)

  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
}


  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await Api.getNotesByUser(token);
      if (response.data && Array.isArray(response.data.data)) {
        setLoading(true)
        setNotes(response.data.data);
      } else {
        console.error('Data is not an array or is missing:', response.data.data);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      await Api.deleteNote(selectedUserId , token);
      setLoading(false)
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
      setLoading(false)
      console.error("Error deleting user:", error);
    }
  };


  return (
   <>
        <Topbar />

        <Box m="20px" className="margin_in_mobile_ipad" >


{/* <Header title=" Manager Dashboard" subtitle="Welcome to Manager dashboard" /> */}

<Box>

<div style={{display:"grid" , gridTemplateColumns:"auto auto auto auto", gap:"20px" , }} className="totalInputsOfDash">
{counts.map((data ,index) =>(
    <div key={data.id} style={{ display:"flex",   alignItems:"center", justifyContent: "space-between" ,  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" , padding:"10px" , borderRadius:"10px",  }}>
    <img style={{ height: "40px", width: "40px" }} src={data.image} alt="" />
    <div style={{textAlign:"end"}}  >
      <p><strong>{data.count}</strong></p>
      <p>{data.description}</p>
    </div>
  </div>
))}




</div>
</Box>


<br/>




<Box >


  <div style={{display:"flex" , justifyContent:"space-between"}}> 
  
  <Typography variant="h3" gutterBottom>
     Sticky Notes
  </Typography>
  
  <Button onClick={openPopup} sx={{ backgroundColor: "#9B56FF", color: "#fff", height:"40px", ':hover': { backgroundColor: "#9B56FF", color: "#fff" } }}>Add Notes</Button>
  
  </div>
  <br />
     
      <Box display="flex" flexWrap="wrap"  style={{  gap:"20px"}}>
        {notes.map((note, index) => (
          <Card key={index} sx={{ margin: "5px 0px" , padding:"10px" , backgroundColor:"#28c76f" , color:"white" }}>
<div style={{display:"flex" , justifyContent:"end"}}>
<CloseOutlinedIcon style={{ color: "red", fontSize: "10px" }} 
onClick={() => handleDeleteClick(note._id)}
/>
</div>

            <CardContent>
            
              <Typography variant="body2">
                {note.text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>


<br />
<Box



>

  <div style={{ display:"grid" ,gridTemplateColumns:"auto auto auto",  gap:"20px" }} className='invoicesalign'  >

    <div style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
<h2 style={{marginLeft:"20px"}}>Leads Source</h2>
    <div >
    <PieChart 

className="piewidth"
series={[
{
  data: [
    { id: 0, value: source.facebook, label: `Facebook ${source.facebookLeads}`,  color: "#0f4a96" },
    { id: 1, value: source.instagram , label: `Instagram ${source.instagramLeads}`, color: "#c255bb" },
    { id: 2, value: source.whatsapp, label: `WhatsApp ${source.whatsappLeads}`, color: "#416634" },
    { id: 3, value: source.linkedin, label: `Linkdin ${source.linkedinLeads}`, color: "#9973d1" },
    { id: 4, value: source.google, label: `Google ${source.googleLeads}`, color: "#725d73" },
    { id: 5, value: source.website, label: `Wesites ${source.websitesLeads    }`, color: "#0324fc" },


  ],
},
]}
width={400}
height={200}
/>
    </div>


    </div>







   




    <div style={{boxShadow:"0 2px 10px rgba(0, 0, 0, 0.1)"}}>
<h2 style={{marginLeft:"20px"}}> Leads Status</h2>
    <div >
    <PieChart 

className="piewidth"
series={[
{
  data: [
    { id: 0, value:source.newPercentage, label: `New  ${source.newLeads}`, color: "rgb(5, 102, 129)" },
    { id: 1, value: source.processingPercentage, label: `In Discussion  ${source.processingLeads}`, color: "rgb(58, 50, 133)" },
    { id: 2, value: source.confirmPercentage, label: `Hired  ${source.confirmLeads}`, color: "rgb(72, 110, 3)" },
    { id: 3, value: source.cancelPercentage, label: `Cold/Dead  ${source.cancelLeads}`, color: "rgb(230, 0, 35)" },
  ],
},
]}
width={400}
height={200}
/>
    </div>


    </div>



     


    <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ marginLeft: "20px" }}>Today Leads</h2>
              <div >
                <PieChart

                  className="piewidth"
                  series={[
                    {
                      data: [
                        { id: 0, value: todayLeads.facebook, label: `Facebook ${todayLeads.facebookLeads}`,  color: "#0f4a96" },
                        { id: 1, value: todayLeads.instagram , label: `Instagram ${todayLeads.instagramLeads}`, color: "#c255bb" },
                        { id: 2, value: todayLeads.whatsapp, label: `WhatsApp ${todayLeads.whatsappLeads}`, color: "#416634" },
                        { id: 3, value: todayLeads.linkedin, label: `Linkdin ${todayLeads.linkedinLeads}`, color: "#9973d1" },
                        { id: 4, value: todayLeads.google, label: `Google ${todayLeads.googleLeads}`, color: "#ccffcc" },
                        { id: 5 ,value: todayLeads.website, label: `Website ${todayLeads.websitesLeads}`, color: "#d9ff66" },

                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>


            </div>






    




  </div>

</Box>


<Box >


  <div style={{display:"flex" , justifyContent:"space-between"}}> 
  
  <Typography variant="h2" gutterBottom>
        Notes
      </Typography>
  
  <Button onClick={openPopup} sx={{ backgroundColor: "#9B56FF", color: "#fff", height:"40px", ':hover': { backgroundColor: "#9B56FF", color: "#fff" } }}>Add Notes</Button>
  
  </div>
     
      <Box display="flex" flexWrap="wrap" style={{justifyContent:"space-between"}}>
        {notes.map((note, index) => (
          <Card key={index} sx={{ margin: "5px 0px" , padding:"10px" , backgroundColor:"#28c76f" , color:"white" }}>
<div style={{display:"flex" , justifyContent:"end"}}>
<CloseOutlinedIcon style={{ color: "red", fontSize: "10px" ,cursor:"pointer" }} 
onClick={() => handleDeleteClick(note._id)}
/>
</div>

            <CardContent>
            
              <Typography variant="body2">
                {note.text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
    
  


<Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
       <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent>
         Are you sure you want to delete this Lead?
       </DialogContent>
       <DialogActions>
         <Button onClick={handleDeleteCancel} color="primary">
           Cancel
         </Button>
         <Button onClick={handleDeleteConfirm} color="error">
           Delete
         </Button>
       </DialogActions>
     </Dialog>
     {loading && <Loader />}
</Box>
   
   </>
  );
};

export default ManagerDashboard;
