import { NavLink, useNavigate , useParams} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useDispatch } from "react-redux";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import VerifiedIcon from "@mui/icons-material/Verified";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { Button, Grid, } from "@mui/material";
import { connect } from "react-redux";
import { store } from '../../redux/store';
import { getCookie } from "../../components/Cookies/index";
import MuiAlert from '@mui/material/Alert';
import {Snackbar } from '@mui/material';
import axios from "axios";
import { clearHostProperty } from "../../redux/actions";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  nextback: {
    justifyContent: "space-between",
    display: "flex",
  },

  mainContainer: {
    display: "flex",
    justifyContent: "space-around",


        // Media Query for Mobile
        [theme.breakpoints.down('xs')]: {
          flexDirection: "column",
        },

        
      // Media Query for iPad
      [theme.breakpoints.between('sm', 990)]: {
        flexDirection: "column", // Change it as per your requirement for iPad
      },
  },


//   images: {
//     [theme.breakpoints.between(500 , 900)] : {
// width:"100%"
//     }
//   },
  outer: {
    display: "flex",
    justifyContent: "center",
  },

  infoText: {
    marginBottom: theme.spacing(2),
  },

  heading: {
    fontSize: "18px",
    color: "black",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    gap: "50px",
    marginBottom: theme.spacing(2),
  },

  radioButtonContainer: {
    display: "flex",
  },

  subhead: {
    fontSize: "15px",
    fontWeight: "600",
  },
}));
const Step4 = ({hostProperty } ) => {
  const [loading,setLoading]=useState(false)
  const { id } = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate(0)
 const [createProperty , SetCreateProperty] = useState(false)
  const setUserData = (data) => {
    store.dispatch({
      type: "User_Data",
      payload: data,
    });
  };
  console.log(hostProperty)
  const token = getCookie('savelogin')
  const handleHostProperty = async()=>{
    
    try {
      setLoading(true)
        const res = await axios.post(`${BACKEND_URL}/admin/create-room/${id}` ,  hostProperty )
        SetCreateProperty(true)
        setLoading(false)
        setTimeout(() => {
         
          dispatch(clearHostProperty());
          Navigate("/");
        }, 500);
     

    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const classes = useStyles();

  return (
    <>
     
        <div style={{padding:"50px"}}>
          <div id="realdeal">
            <div>
              <div>
           

                <br />

                <div className={classes.container}>
                  <div className="container">
                    <div>
                      <div className={classes.heading}>Review Your Listing</div>
                      <div>
                        Here’s what we’ll show to guests. Make sure everything
                        looks good.
                      </div>

                      <br />
                      <div className={classes.outer}>
                        <div className="container">
                          <div className={classes.mainContainer}>
                            <div
                              className={classes.carddiv}
                              style={{
                                borderRadius: "20px",
                                padding: "15px",
                                // backgroundColor: "rgb(245, 242, 242)",
                              }}
                            >
                              <img className={classes.images}
                                src={hostProperty.images[0]}
                                alt="gjghfjhg"
                                style={{ height: "220px" }}
                              />
                              <br />
                              <br />



                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h6>{hostProperty.name && hostProperty.name.slice(0,20) + "..." }</h6>
                                  <h6>
                                    <CurrencyRupeeIcon sx={{position:"relative" , top:"7px"}} /> {hostProperty.price}
                                  </h6>
                                </div>
                                <div>
                                  New
                                  <br />
                                  <StarIcon
                                    sx={{
                                      color: "#fda94f",
                                      zIndex: 1,
                                    }}
                                  />
                                </div>
                              </div>


                              
                            </div>

                            <div className={classes.infoContainer}>
                              <div className={classes.heading}>
                                What’s next?
                              </div>
                              <div className={classes.heading}>
                                {" "}
                                <VerifiedIcon sx={{ color: "#fda94f" }} />{" "}
                                Verify a few details and publish
                              </div>
                              <div className={classes.infoText}>
                                Lorem ipsum dolor sit amet consectetur. Et et
                                aenean turpis etiam sem ultrices.
                              </div>

                              <div className={classes.heading}>
                                {" "}
                                <CalendarMonthIcon
                                  sx={{ color: "#fda94f" }}
                                />{" "}
                                Set up your calender
                              </div>
                              <div className={classes.infoText}>
                                Lorem ipsum dolor sit amet consectetur. Et et
                                aenean turpis etiam sem ultrices.
                              </div>
                              <div className={classes.heading}>
                                {" "}
                                <SettingsIcon sx={{ color: "#fda94f" }} />{" "}
                                Adjust your settings
                              </div>
                              <div className={classes.infoText}>
                                Lorem ipsum dolor sit amet consectetur. Et et
                                aenean turpis etiam sem ultrices.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ width: "100%" }} />

                <div className={classes.container}  style={{marginBottom:"15px"}}>
                  <div className="container">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>{/* Your existing content */}</div>
                      </Grid>
                      <Grid item xs={12} className={classes.nextback}>
                        <Link to={`/property-listing/${id}/step3`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Back
                          </Button>
                        </Link>

                      
                          <Button
                          onClick={handleHostProperty}
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#fda94f",

                              border: "2px solid #fda94f ",
                            }}
                          >
                            Next
                          </Button>
                       
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Snackbar
                open={createProperty}
                autoHideDuration={6000}
                
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                 
                  severity="success"
                >
                Property Successfully Created
                </MuiAlert>
              </Snackbar>
             
    
    </>
  );
};

const mapStateToProps = (state) => ({
  hostProperty: state.user.hostProperty
});

export default connect(mapStateToProps)(Step4);
